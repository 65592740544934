import React, { useEffect, useState } from 'react';
import './App.css';
import { auth } from './services/firebase';
import {  signInWithEmailAndPassword, getIdToken, onAuthStateChanged, getAuth  } from 'firebase/auth';
import Login from "./views/Login"
import axios from "axios"

import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";


import NavRoutes from "./Navitgation/NavRoutes"

function App() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
 

  let navigate = useNavigate();


  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user.email)
      } else {
       
      }
    });
  }, []);





  const getUserToken = async () => {
    return new Promise((resolve, reject) => {
      const unsub = onAuthStateChanged(auth, async (user) => {
        if (user) {
       
          const token = await getIdToken(user);
          resolve(token)
        } else {
          console.log("User not logged in")
          resolve(null)
        }
        unsub();
      });
    })
  }

  axios.interceptors.request.use(async (request) => {
    const token = await getUserToken();
  
    if (token) {
      request.headers = {
        authorization: token,
      };
    } else {
      // prompt user to login
    }
  
    return request;
  });

  const handleLogin = () => {

    
    signInWithEmailAndPassword(auth, email, password)
    .then(() => {
        
        navigate("./")
        })
   
    
    .catch(err => alert("Invalid Email or Password"))

    // onAuthStateChanged(auth, async (user) => {
    //   if (user) {
    //     const token = await getIdToken(user);
    //     console.log(token)
    //   }
    // });
  }
  return (
    <div className="background-main">
    <Routes>
      
      <Route path="/login" element={<Login
          setEmail={setEmail}
          setPassword={setPassword}
          handleLogin={() => handleLogin()}
      />}></Route>
      <Route path="*" element={<NavRoutes />}></Route>

 
    </Routes>

    </div>

  );
}

export default App;