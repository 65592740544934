import React from "react"

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
    // typography: {

    //     fontSize: 18,
    //   },
});




export default function Login({ setEmail, setPassword, handleLogin}) {

    const handleUser = (e) => {
        setEmail(e.target.value)
    }

    const handlePass = (e) => {
        setPassword(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        handleLogin()
    }

    const handleKeyPress = (event) => {
       
        if (event.key === 'Enter') {

          handleLogin()
        }
      };
    


    

    


    return (
        <div className="background-main">
            <ThemeProvider theme={theme}>
              <Box
                className="sign-in"
                component="form"
                sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
                >
                <Typography style={{color:"white"}} textAlign="center">Pacas CMS</Typography>
                <TextField  color="primary" sx={{ input: { color: '#D9D4D3' }  }} InputLabelProps={{style : {color : '#D9D4D3'} }} className="center" id="outlined-basic" onChange={handleUser} label="Email" variant="outlined" />
                <br></br>
                <TextField sx={{ input: { color: '#D9D4D3' }  }} InputLabelProps={{style : {color : '#D9D4D3'} }} className="center" id="outlined-basic" onChange={handlePass} onKeyDown={handleKeyPress} label="Password" variant="outlined" type="password" />
                <br></br>
                <Button className="button" variant="contained" onClick={handleSubmit} >Sign In</Button>

             </Box>
             </ThemeProvider>
        
        </div>
    )



}