
//DEPS
import React, { useEffect, useState } from 'react';
import axios from "axios"

//COMPONENTS

import CatalogDisplay from './CatalogDisplay';

let passList = [

   {sku: "7010", render: "false"},
   { sku: "6086", render: "false"},
   { sku: "1600", render: "false"},
   { sku: "2936", render: "false"},
   { sku: "8238", render: "false"},
   { sku: "8502", render: "false"},
   { sku: "5028", render: "false"},
   { sku: "3153", render: "false"},

]


export default function PackCreator() {
    const [variantData, setVariantData] = useState([])

    const mergeData = (a,b) => {
        let combinedData = a.map(elem => {
            let findImage = b.find(elm => elm.id === elem.image_id)
            let image = ""
            if (findImage) {image = findImage.src}

   

            //PRODUCT ID
            let style = elem.product_id
            let cat = elem.product_id
            if (elem.product_id === 6852663804004 || elem.product_id === 6620534669412 || elem.product_id === 6597571575908 || elem.product_id === 4837716951140 || elem.product_id === 6950074384484 ) {
              style = "Crew Socks"
              cat = "Women's"
            } 
            else if (elem.product_id === 6873293586532) {
              style = "Compression Socks"
              cat = "Women's"
            }
            else if (elem.product_id === 6824475361380) {
              style = "Golf Socks"
              cat = "Women's"
            }
            else if (elem.product_id === 6609129275492) {
              style = "Light-Weight Crew Socks"
              cat = "Women's"
            }
            else if ( elem.productId === 6626100314212 || elem.productId === 6816252428388 ) {
              style = "Low-Cut Socks"
              cat = "Women's"
            }
            else if (elem.product_id === 6541486489700) {
              style = "No-Show Socks"
              cat = "Women's"
            }
            else if (elem.product_id === 6557907746916) {
              style = "Performance Socks"
              cat = "Women's"
            }
            else if (elem.product_id === 7105370357860) {
                style = "Slouchy Socks"
                cat = "Women's"
              }

           
            else if (elem.product_id === 6888734425188 || elem.product_id === 6888748580964 || elem.product_id === 6888738160740 || elem.product_id === 6888742060132 || elem.product_id === 6888726921316 || elem.productId === 6888747335780) {
              style = "Pacas Throw Blanket"
            }
        
            else if (elem.product_id === 2236767502436 || elem.product_id === 6626100314212 || elem.product_id === 6816252428388 || elem.product_id === 6958959296612 ) {
              style = "Low-Cut Socks"
              cat = "Women's"
            }

            else if (elem.product_id === 7194189430884 ) {
              style = "Hiking Socks"
              cat = "Women's"
            }
        
            else if (elem.product_id=== 6956831965284 || elem.product_id === 4837718753380) {
              style = "Crew Socks"
              cat = "Men's"
            }
            else if (elem.product_id === 6878354341988) {
              style = "Compression Socks"
              cat = "Men's"
            }
        
            else if (elem.product_id === 6602920394852) {
              style = "Light-Weight Crew Socks"
              cat = "Men's"
            }
        
            else if (elem.productId === 6873185353828 || elem.productId === 6862851080292 || elem.productId === 6873188270180 || elem.productId === 6873186828388 || elem.productId === 6873182437476 ) {
              style = "Pacas Summer Wrap"
            }
        
            else if (elem.product_id === 6972996714596 || elem.product_id === 6972996157540 || elem.product_id === 6972996976740 || elem.product_id === 6972996419684 || elem.product_id === 6972997271652 ) {
              style = "Pacas Shawl"
            }
        
            else if (elem.product_id === 4838368051300 || elem.product_id === 4838370410596) {
              style = "Toddler Pacas Socks"
            }
            else if (elem.product_id === 7000945000548 || elem.product_id === 7000945590372  || elem.productId === 7000423956580) {
              style = " Pacas Scarf"
            }
        
            else if (elem.product_id === 7082909958244 || elem.product_id === 7082909139044 ) {
              style = "Gift Box"
            }

            else if (elem.product_id === 4823046324324  ) {
                style = "Low-Cut Socks"
                cat = "Men's"
              }
              else if (elem.product_id === 4838251888740  ) {
                style = "Kid Socks"
                cat = "Kids'"
              }
              //



            //   if (findSku) {}
            return (
                {sku: elem.sku, image: image, color: elem.option1, cat:cat, style:style, render: elem.render }
            )
              
            }).filter(elm => parseInt(elm.sku) % 2 === 0 || elm.cat === "Kids'").filter(elm => elm.image !== "")


        

            console.log(combinedData)

            setVariantData(combinedData)
        }
   

    const getSingles = () => {
        axios.post("/api/shopify/getallsingles", {

        })
        .then((res) => {
          

            let allImages = res.data.products.map(elem => {
                return (
                    elem.images
                )
            })
            let allVariants = res.data.products.map(elem => {
                return (
                    elem.variants
                )
            })

            console.log(allVariants.flat())

            let filteredSkus = allVariants.flat().filter(elm => passList.some(ellm => ellm.sku === elm.sku))

            let logicFilter = filteredSkus.map(elem => {
              let found = passList.find(ellm => ellm.sku === elem.sku )
                return (
                  {...elem, render: found.render}
                )

            })

            // console.log(logicFilter)

            // console.log(filteredSkus)

            // let filteredSkus = allVariants.flat()

            // let logicFilter = filteredSkus


            console.log(allImages.flat())

            console.log(filteredSkus)

            console.log(filteredSkus.filter(ellm => ellm !== undefined))

            mergeData(logicFilter,allImages.flat())

            


        })
    }

   



    //TESTING
    const logData = () => {
        console.log(variantData)
    }

    useEffect(() => {
        getSingles()
       
    }, [])


    return (
        <div >
            {/* <h1>Pack Creator</h1>
            <button onClick={logData}>Log Data</button> */}
            
           <CatalogDisplay data={variantData} />

        </div>
    )
}