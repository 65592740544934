
import React, { useEffect, useState } from 'react';
import axios from "axios"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

import BIViewModal from '../subViews/BIViewModal';
import BICreateModal from '../subViews/BICreateModal';

import loading from "../assets/loadingNew.gif"





export default function BIParent() {
    const [bundleData, setBundleData] = useState([]);
    const [prodData, setProdData] = useState([]);
    const [load, setLoad] = useState(0)

    const [filter, setFilter] = useState("All")

    const [dataView, setDataView] = useState([]);

    
        //Select Functions
        const handleFilter = (event) => {
            setLoad(0)
            setFilter(event.target.value);
            console.log(event.target.value)
            console.log(filter)
            window.scrollTo({
                top: 0, 
                behavior: 'smooth'
            
              })

              handleState(bundleData, event.target.value)
          };



    const getProduct = () => {

        axios.post("/api/shopify/getoneproduct", {
            ///Component ids
            //FLATS NEEDED 7258058981476 6999594565732 7000425103460
            id: "4837733957732,7194187825252,6930797101156,7105422360676,7260570255460,4837517230180,7234857042020,4837725012068,7312592961636,7312592994404,7312593027172,7321871220836,4837737365604,7234867462244,4837728518244,6930798379108,7486012719204"
            }).then((res) => {

               

        let prodVariants = res.data.products.map((elem) => {
        
            let { title } = elem;
            

            let variantsWithTitle = elem.variants.map(variant => ({
                ...variant, 
                title: title 
            }));
            
            return variantsWithTitle;
            }).flat();


        let prodImages = res.data.products.map((elem) => {
            return (
                elem.images
            )
        }).flat()

        let prodForm = prodVariants.map((elem) => {
            let foundImages = prodImages.find((elm) => elm.id === elem.image_id)

            if (foundImages) {

            return (
                {
                    sku: elem.sku,
                    src: foundImages.src,
                    option1: elem.option1,
                    option2: elem.option2,
                    option3: elem.option3,
                    title: elem.title

                }
            )
            } else {
                return (
                    {
                        sku: elem.sku,
                        src: "",
                        option1: elem.option1,
                        option2: elem.option2,
                        option3: elem.option3,
                        title: elem.title
    
                    }
                )
            }
        })

        console.log(prodForm)

        setProdData(prodForm)

    



     //           
  
    axios.post("/api/shopify/getoneproduct", {
        //BUNDLE IDs
        id: "7312330096740,7261578133604,7312332259428,7312316825700,7261579280484,7313261330532,7321355419748,7321414238308,7323641839716,7323636072548,7323647115364,7334788497508,7365950439524,7367726334052"
        }).then((res) => {
            console.log(res.data.products)

            let form = res.data.products.map((elem) => {

                let bundleTitle = elem.title
                console.log(bundleTitle)

                

                    let OA = elem.options.map((options) => {
                        let prodName = options.name
                        console.log(bundleTitle)
                        let VOA = options.values.map((values) => {

                            //This is fucking insane but its late and my brain isnt working

                            let cId = ""
                            let cId2 = ""
                            let cId3 = ""
                            let cId4 = ""
                            //
                            if (prodName === "Men's Solid Scarf (Color)") {
                                cId = 7312332259428
                                cId2 = 7321414238308
                            
                            }
                            if (prodName === "Men's Beanie (Color)") {
                                cId = 7312332259428
                                cId2 = 7334788497508
                            
                            }
                            //
                            if (prodName === "3 Pack - Women's Crew Pacas Socks (Size)") {
                                cId = 7313261330532
                                cId2 = 7323641839716
                            }
                            if (prodName === "3 Pack - Women's Crew Pacas Socks (Color)") {
                                cId = 7313261330532
                                cId2 = 7323641839716
                            }
                            if (prodName === "4 Pack - Women's Crew Pacas Socks (Size)") {
                                cId = 7323636072548
                             
                            }
                            if (prodName === "4 Pack - Women's Crew Pacas Socks (Color)") {
                                cId = 7323636072548
                              
                            }
                            if (prodName === "3 Pack - Men's Crew Pacas Socks (Size)") {cId = 7321414238308}
                            if (prodName === "3 Pack - Men's Crew Pacas Socks (Color)") {cId = 7321414238308}
                            if (prodName === "Women's Plaid Scarf (Color)") {cId = 7260390817892}
                            if (prodName === "Women's Solid Scarf (Color)") {
                                cId = 7312316825700
                                cId2 = 7313261330532
                                cId3 = 7323647115364
                                cId4 = 7334788497508
                            
                            }
                            //
                            if (prodName === "3 Pack - Women's Compression Socks (Size)") {cId = 7312330096740}
                            if (prodName === "3 Pack - Women's Compression Socks (Color)") {cId = 7312330096740}
                            if (prodName === "Solid Throw Blanket (Color)") {
                                 cId = 7312330096740
                                 cId2 = 7261578133604
                                 cId3 = 7261579280484
                                 cId4 = 7365950439524
                                
                                }
                            //
                            if (prodName === "2 Pack - Women's Slouchy Socks (Size)") {cId = 7261578133604}
                            if (prodName === "2 Pack - Women's Slouchy Socks (Color)") {cId = 7261578133604}
                            //
                            if (prodName === "Gloves (Color)") {
                                cId = 7312316825700
                                cId2 = 7323641839716
                            
                            }
                            if (prodName === "Women's Beanie (Color)") {
                                cId = 7312316825700
                                cId2 = 7334788497508
                            
                            }
                            if (prodName === "6 Pack - Women's Crew Pacas Socks (Color)") {
                                cId = 7261579280484
                                cId2 = 7321355419748
                                cId3 = 7323647115364
                            
                            }
                            if (prodName === "6 Pack - Women's Crew Pacas Socks (Size)") {
                                cId = 7261579280484
                                cId2 = 7321355419748
                                cId3 = 7323647115364
                            }

                            if (prodName === "Women's Brushed Scarf (Color)") {cId = 7321355419748}

                            if (prodName === "Pom Pom Hat (Color)") {
                                cId = 7323636072548
                                cId2 = 7367726334052
                            }

                            if (prodName === "3 Pack - Men's Compression Socks (Size)") {cId = 7365950439524}
                            if (prodName === "3 Pack - Men's Compression Socks (Color)") {cId = 7365950439524}





                            return (
                                {value: values, prodName: prodName, bundleTitle: bundleTitle, sku: "", src:"", cId: cId, cId2: cId2, cId3: cId3 }
                            )
                        })

                        return (
                            VOA
                        )
                    }).flat()


                    let flatVariants = elem.variants.flat()


                return (
                    {variants: flatVariants, OA:OA}
                )
            }).flat()

            //

            console.log(form)


            const combinedForm = form.reduce((a, b) => {
            
                a.OA = [...(a.OA || []), ...b.OA];
             
                a.variants = [...(a.variants || []), ...b.variants];
                
                return a;
              }, {});


            let OA = combinedForm.OA
            let variants = combinedForm.variants

            
     

            let variantMatch = variants.map((elem, i) => {

                console.log(i)
                // if (i === 192) {
                //     console.log(elem)
                // }

        
                // let foundArray1 = OA.find((elm) => elm.value === elem.option1 && elm.cId === elem.product_id || elm.value === elem.option1 && elm.cId2 === elem.product_id || elm.value === elem.option1 && elm.cId3 === elem.product_id || elm.value === elem.option1 && elm.cId4 === elem.product_id  )
                // let foundArray2 = OA.find((elm) => elm.value === elem.option2 && elm.cId === elem.product_id || elm.value === elem.option2 && elm.cId2 === elem.product_id || elm.value === elem.option2 && elm.cId3 === elem.product_id || elm.value === elem.option2 && elm.cId4 === elem.product_id )
                // let foundArray3 = OA.find((elm) => elm.value === elem.option3 && elm.cId === elem.product_id || elm.value === elem.option3 && elm.cId2 === elem.product_id || elm.value === elem.option3 && elm.cId3 === elem.product_id || elm.value === elem.option3 && elm.cId4 === elem.product_id )

                // if (i === 640) {
                //     console.log(foundArray1)
                //     console.log(foundArray2)
                //     console.log(foundArray3)
                // }


            

    


                //EDGE CASE COLLISON
                // if (foundArray1.cId === 7312316825700) {
                //         foundArray1.prodName = "Women's Solid Scarf (Color)"
                //         foundArray2.prodName = "Gloves (Color)"
                //         foundArray3.prodName = "Women's Beanie (Color)"

                // }

               

       
        

                // let formSku = elem.sku.split("-")
                // foundArray1.sku = formSku[0]
                // console.log(formSku)

                // if (formSku.length === 3) {
                    
                //     foundArray1.sku = formSku[0]
                //     foundArray2.sku = formSku[1]
                //     foundArray3.sku = formSku[2]
                // } else {
                //     foundArray1.sku = formSku[0]
                //     foundArray2.sku = formSku[1]
                // }

            //     let ar1 = {
            //         bundleTitle: foundArray1.bundleTitle,
            //         prodName: foundArray1.prodName,
            //         value: foundArray1.value,
            //         // sku:formSku[0]

            //     }

            //     let ar2 = {
            //         bundleTitle: foundArray2.bundleTitle,
            //         prodName: foundArray2.prodName,
            //         value: foundArray2.value,
            //         // sku:formSku[1]

            //     }

            //     let ar3 = []

            //     if (foundArray3) {

            //     ar3 = {
            //         bundleTitle: foundArray3.bundleTitle,
            //         prodName: foundArray3.prodName,
            //         value: foundArray3.value,
            //         // sku:formSku[2]

            //     }
            // }

                // if (i === 0) {
                //     console.log(foundArray1)
                // }


                // if (foundArray3) {
                // return (
                //     {
                //         variantId: elem.id,
                //         bundleTitle: foundArray1.bundleTitle,
                //         imageId: elem.image_id,
                //         title: elem.title,
                //         productId: elem.product_id,
                //         children: [ar1,ar2,ar3],
                //         bsku: elem.sku,
                //         i: i,
                //         // sku1: formSku[0],
                //         // sku2: formSku[1]
                //     }
                // )
                // } else {
                    return (
                        {
                            variantId: elem.id,
                            bundleTitle: "",
                            imageId: elem.image_id,
                            title: elem.title,
                            productId: elem.product_id,
                            children: [],
                            bsku: elem.sku

                        }
                    )
                // }
            })

            console.log(variantMatch)

            let testt = variantMatch
            console.log(testt)

            let combinedMatch = variantMatch.map((elem, i) => {

                    let ch = elem.children.map((elm) => {
                   
                        let index = elm.prodName.indexOf("(");
                        let nameForm = index > -1 ? elm.prodName.substring(0, index - 1) : elm.prodName;
                        let nameForm2 = index > -1 ? elm.prodName.substring(0, index - 2) : elm.prodName;

                        // console.log(prodForm)

                        let foundTitle = prodForm.find((ellm) => ellm.option2 === elm.value && ellm.title == nameForm || ellm.option2 === elm.value && ellm.title == nameForm2)
                        // console.log(foundTitle)

                      if (foundTitle) {
                        // console.log(foundTitle)

                        return (
                            {
                                bundleTitle: elm.bundleTitle,
                                prodName: elm.prodName,
                                sku: foundTitle.sku,
                                src: foundTitle.src,
                                value: elm.value
                            }
                        )
                      } 
                    //   else {
                    //     return (
                    //         {
                    //             bundleTitle: elm.bundleTitle,
                    //             prodName: elm.prodName,
                    //             sku: "",
                    //             src: "",
                    //             value: elm.value
                    //         }
                    //     )
                    //   }

                    }).filter((elem) => elem !== undefined)
                    let newTitle = ""

                    if (elem.productId === 7365950439524) {
                        newTitle = "Men's Jet Set Travel Pack Bundle"
                    }
                    if (elem.productId === 7313261330532) {
                        newTitle = "Women's Always Active Bundle"
                    }
                    if (elem.productId === 7312316825700) {
                        newTitle = "Women's On the Go Bundle"
                    }
                    if (elem.productId === 7312330096740) {
                        newTitle = "Women's Jet Set Travel Pack Bundle"
                    }
                    if (elem.productId === 7312332259428) {
                        newTitle = "Men's On the Go Bundle"
                    }
                    if (elem.productId === 7321414238308) {
                        newTitle = "Men's Always Active Bundle"
                    }
                    if (elem.productId === 7261578133604) {
                        newTitle = "Loves to Lounge Bundle"
                    }
                    if (elem.productId === 7334788497508) {
                        newTitle = "His and Hers Bundle"
                    }
                    if (elem.productId === 7321355419748) {
                        newTitle = "Fabulous Fashionista Bundle"
                    }
                    if (elem.productId === 7261579280484) {
                        newTitle = "Cozy at Home"
                    }
                    if (elem.productId === 7323647115364) {
                        newTitle = "6 Pack Holiday Bundle"
                    }
                    if (elem.productId === 7323636072548) {
                        newTitle = "4 Pack Holiday Bundle"
                    }
                    if (elem.productId === 7323641839716) {
                        newTitle = "3 Pack Holiday Bundle"
                    }
                    if (elem.productId === 7367726334052) {
                        newTitle = "Women's Winter Bundle"
                    }

                return (
                    {
                        children: ch,
                        bundleTitle: newTitle,
                        imageId: elem.imageId,
                        productId: elem.productId,
                        title: elem.title,
                        variantId: elem.variantId
                    }
                )
            })

            console.log(combinedMatch)

          let  data = combinedMatch

            // let finalData = []

            // if (filter !== " All") {
            //     finalData = combinedMatch.filter(elm => elm.bundleTitle === filter)
            // } else {
            //     finalData = combinedMatch
            // }





            setBundleData(data)
            setDataView(data)
            // console.log("fore")
            // handleState(data)
            setLoad(1)
        })
    })
}


const handleState = (data, eFilter) => {

    console.log(eFilter)

if (eFilter !== "All") {
    setDataView(data.filter(elm => elm.bundleTitle === eFilter))
} else {
    setDataView(data)
}

setLoad(1)
}


useEffect(() => {
    getProduct()
  
  }, []);

  if (load === 0) {
    return (
        <div style={{ marginLeft: "40vw", marginTop: "30vh" }}>
        <img src={loading} />
        </div>
    )
  } else {


    return (
        <div style={{ marginLeft: "200px", marginBottom: "80px"}}>
                      <Box style={{margin:"1vw" }}sx={{ minWidth: 150, maxWidth: 150 }}>
          <FormControl fullWidth>
                <InputLabel sx={{  color: "white",  
                                    '&.Mui-focused': {color: 'white',}, 
                                }} 
                                id="demo-simple-select-label">Filter</InputLabel>
                <Select
                sx={{  color: "white",
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                      },
                      '.MuiSvgIcon-root': {
                        color: 'white',
                      },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter}
                label="Category"
                onChange={handleFilter}
                >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"Men's Jet Set Travel Pack Bundle"}>Men's Jet Set Travel Pack Bundle</MenuItem>
                <MenuItem value={"Women's Jet Set Travel Pack Bundle"}>Women's Jet Set Travel Pack Bundle</MenuItem>
                <MenuItem value={"Men's Always Active Bundle"}>Men's Always Active Bundle</MenuItem>
                <MenuItem value={"Women's Always Active Bundle"}>Women's Always Active Bundle</MenuItem>
                <MenuItem value={"Men's On the Go Bundle"}>Men's On the Go Bundle</MenuItem>
                <MenuItem value={"Women's On the Go Bundle"}>Women's On the Go Bundle</MenuItem>
                <MenuItem value={"Loves to Lounge Bundle"}>Loves to Lounge Bundle</MenuItem>
                <MenuItem value={"His and Hers Bundle"}>His and Hers Bundle</MenuItem>
                <MenuItem value={"Fabulous Fashionista Bundle"}>Fabulous Fashionista Bundle</MenuItem>
                <MenuItem value={"Cozy at Home"}>Cozy at Home</MenuItem>
                <MenuItem value={"6 Pack Holiday Bundle"}>6 Pack Holiday Bundle</MenuItem>
                <MenuItem value={"4 Pack Holiday Bundle"}>4 Pack Holiday Bundle</MenuItem>
                <MenuItem value={"3 Pack Holiday Bundle"}>3 Pack Holiday Bundle</MenuItem>
                <MenuItem value={"Women's Winter Bundle"}>Women's Winter Bundle</MenuItem>
                </Select>
            </FormControl>
            </Box>
            <div style={{ height: "70vh", overflowY: "scroll",marginBottom: "80px", marginRight:"3vw" }}>
            
          {/* <h1>TEST</h1>
          <button onClick={getProduct}>GET</button> */}
          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell >
            Bundle
          {/* <Box sx={{ minWidth: 150, maxWidth: 150 }}>
          <FormControl fullWidth>
                <InputLabel sx={{  color: "white" }} id="demo-simple-select-label">Bundle</InputLabel>
                <Select
                sx={{  color: "white" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter}
                label="Category"
                onChange={handleFilter}
                >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"Men's Jet Set Travel Pack Bundle"}>Men's Jet Set Travel Pack Bundle</MenuItem>
                <MenuItem value={"Women's Jet Set Travel Pack Bundle"}>Women's Jet Set Travel Pack Bundle</MenuItem>
                <MenuItem value={"Kids'"}>Kids'</MenuItem>
                </Select>
            </FormControl>
            </Box> */}


          </TableCell>
            <TableCell>Title</TableCell>

            <TableCell align="left">ID</TableCell>
            
            <TableCell align="left">Image</TableCell>
            <TableCell align="left">Create</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {dataView.map((elem) => (
            <TableRow
              key={elem.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {elem.bundleTitle}
              </TableCell>
              <TableCell >{elem.title}</TableCell>
              <TableCell >{elem.variantId}</TableCell>
              {/* <TableCell align="left">{elem.id}</TableCell> */}
              {elem.imageId ? <TableCell align="left"><BIViewModal data={elem} /></TableCell> :<TableCell align="left">No Image</TableCell>}
              <TableCell align="left"><BICreateModal data={elem} cData={prodData} /></TableCell>
              {/* <TableCell align="left"><BIViewModal data={elem} /></TableCell> */}
              {/* <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    </div>
        </div>
    )



            }






}