import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import loading from "../assets/loadingNew.gif"
import axios from "axios"
import { TextField } from '@mui/material';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50vw",
    height: "30vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function BomViewModal( { handleBatchUpdate, handleChange, note} ) {

    const [open, setOpen] = useState(false);



    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

 

















if (1 === 2) {

  return (
    // <div>
    //   <Button onClick={handleOpen}>View</Button>
    //   <Modal
    //     open={createOpen}
    //     onClose={handleCreateClose}
    //     aria-labelledby="modal-modal-title"
    //     aria-describedby="modal-modal-description"
    //   >
    //     <Box sx={style}>

    //        <img style={{margin:"10vw", height:"40vh", marginLeft:"25vw"}}src={loading} />
        
    //     </Box>
    //   </Modal>
    // </div>
    <></>
  );
} else if (1 === 1) {

    return (
        <div>
        <Button variant="outlined" style={{ maxHeight: "4vh", backgroundColor: '#a61616', color: 'white' }} onClick={handleOpen}>Update Status</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <TextField 
                    fullWidth
                    id="standard-multiline-static"
                    label="Add a Note"
                    multiline
                    rows={4}
                    defaultValue="Default Value"
                    variant="standard"
                    value={note}
                    onChange={handleChange}
                />
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Button variant="outlined" style={{ maxHeight: "4vh", backgroundColor: '#a61616', color: 'white' }} onClick={handleBatchUpdate}>Update Status</Button>
    
              </Typography>
            </Box>
          </Modal>
        </div>
      );







}
}