
import React, { useEffect, useState } from 'react';
import axios from "axios"
import {  onAuthStateChanged, getAuth, signOut  } from 'firebase/auth';




export default function Alerts() {
    const [user, setUser] = useState("");
    const [userSub, setUserSub] = useState(false);
    const [subbedUsers, setSubbedUsers] = useState("");
    const [load, setLoad] = useState(false);


    const auth = getAuth();

    useEffect(() => {
     
        onAuthStateChanged(auth, (user) => {

          if (user) {
            console.log(user.email)
            getsub()


            setUser(user.email)
          } else {
            console.log("no")
          }
        });
      }, [userSub, load]);


    const sub = () => {
        console.log("SUB")
        axios.post('/api/report', {
            email: user,
            name: "test",
            value: true
        })
        .then((res) => {console.log(res)
        setUserSub(true)
        getsub()
        })
    }

    const unsub = () => {

        console.log("SUB")
        axios.post('/api/report', {
            email: user,
            name: "test",
            value: false
        })
        .then((res) => {console.log(res)
        setUserSub(false)
        getsub()
        })
    }
    
    const getsub = () => {
        console.log("SUB")
        axios.post('/api/getreport', {
     
        })
        .then((res) => {
            
        console.log(res.data)
        let subbed = []
        res.data.forEach(elem => {
            
            if (elem.value === true) {
                subbed.push(elem.email)
            }

      
            setSubbedUsers(subbed.join(", "))
   
            if (elem.email === user) {
                if (elem.value === true) {

                setUserSub(true)
            
                }
            }

            
        })

        setLoad(true)
        
        })

        console.log(userSub)
    }

 
if (load) {


    return (
        <div style={{ marginLeft: "200px" }}>
            
        <h1>Alerts</h1>

        <h2>Report</h2>
        <h3>Single Product Inventory </h3>
        <h4>Frequency: 1st of the month, 9:00am</h4>
        <h5>Subscribers: {subbedUsers}</h5>
        {userSub ? <button onClick={unsub}>Unsubscribe</button> : <button onClick={sub}>Subscribe</button>  }
        {/* <button onClick={sub}>Subscribe</button>
        <button onClick={unsub}>Unsubscribe</button> */}
        <button onClick={getsub}>Get</button>
        </div>
    )
} else {
    return (
        <div></div>
    )
}
}