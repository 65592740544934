import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { HexColorPicker } from "react-colorful";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid'
import { useDebouncyFn } from 'use-debouncy';



//Patterns
import solid from "../assets/buttons/patterns/solid.png"
import con1 from "../assets/buttons/patterns/con1.png"
import hor1 from "../assets/buttons/patterns/hor1.png"
import con2 from "../assets/buttons/patterns/con2.png"
import hor2 from "../assets/buttons/patterns/hor2.png"
import vert1 from "../assets/buttons/patterns/vert1.png"
import vert2 from "../assets/buttons/patterns/vert2.png"
import d1 from "../assets/buttons/patterns/d1.png"
import d2 from "../assets/buttons/patterns/d2.png"
import pl1 from "../assets/buttons/patterns/pl1.png"
import pl2 from "../assets/buttons/patterns/pl2.png"

//Vectors
import snow1 from "../assets/buttons/vectors/snow1.png"
import snow2 from "../assets/buttons/vectors/snow2.png"
import paca from "../assets/buttons/vectors/paca.png"
import paca2 from "../assets/buttons/vectors/paca2.png"





import axios from "axios"
import ComplexCircle from '../components/ComplexCircle';

const TabPanel = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    minWidth: 10,
    overflow: 'auto',
  }));




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "60vw",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ConmplexButton({ photoData, details, buttonPass, image, setPhotoData, saveData }) {
  const [open, setOpen] = React.useState(false);
  const [color, setColor] = useState("");
  const [color2, setColor2] = useState("");
  const [selectedSubColor, setSelectedSubColor] = useState(1);
  const [patternSelect, setPatternSelect] = useState("");
  const [vectorSelect, setVectorSelect] = useState("");
  
  const handleClose = () => setOpen(false);
  const [selectedSku, setSelectedSku] = React.useState("");
  const [selectedTab, setSelectedTab] = useState(0);

 
  
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };



  const handleLog = () => {
    console.log(photoData)
   console.log(selectedSku)
    console.log(selectedTab)
    console.log(typeof selectedSubColor)

    saveData()
    handleClose()
  }

  const handleChange = (event) => {
    setSelectedSku(event.target.value);
  };


  //Debounce test


  const changeColor = useDebouncyFn(
    (e) => buffColor(e), // function debounce
    150, // number of milliseconds to delay
  );

  const buffColor = (e) => {
    setColor(e)
    setColor2(e)
  }



  const changePattern = (value) => {

    let newPhotoData = photoData.map((elem) => {
      if (elem.sku === selectedSku) {
        let modifiedObject = {...elem, pattern: value}
        return (
          modifiedObject
        )

      } else {
        return (
          elem
        )
      }





    })



    setPhotoData(newPhotoData)



  }


  const changeVector = (value) => {

    let newPhotoData = photoData.map((elem) => {
      if (elem.sku === selectedSku) {
        let modifiedObject = {...elem, vector: value}
        return (
          modifiedObject
        )

      } else {
        return (
          elem
        )
      }





    })



    setPhotoData(newPhotoData)



  }

  //TYPE ISSUE SUBSELECTD

  useEffect(() => {
    let newPhotoData = photoData.map((elem) => {
      if (elem.sku === selectedSku && selectedTab === 2 && selectedSubColor == 1) {
        console.log("P1")
        let modifiedObject = {...elem, primary: color}
        return (
          modifiedObject
        )
      } 

      else if (elem.sku === selectedSku && selectedTab === 2 && selectedSubColor == 2) {
        console.log("P2")
        let modifiedObject = {...elem, primary2: color}
        return (
          modifiedObject
        )
      } 


      else if (elem.sku === selectedSku && selectedTab === 2 && selectedSubColor == 3) {
        console.log("P3")
        let modifiedObject = {...elem, primary3: color}
        return (
          modifiedObject
        )
      } 


      else if (elem.sku === selectedSku && selectedTab === 3 && selectedSubColor == 1) {
        console.log("S1")
        let modifiedObject = {...elem, secondary: color}
        return (
          modifiedObject
        )
      } 


      
      else {
        return (
          elem
        )
      }
  })


  setPhotoData(newPhotoData)

  }, [color])


  const radioOptions = photoData.map((elem) => {
    return (
        <FormControlLabel value={elem.sku} control={<Radio />} label={elem.sku} />
    )
  })


  const handleOpen = () => {
    setOpen(true)

    if (photoData[0]) {
        setSelectedSku(photoData[0].sku)
        setColor(photoData[0].primary)
    }





  }

  const handleCard = (e) => {
    setColor(e.target.value)
  }


  //CARDS

  const hexArray = [

   "#020303",
   "#3F3F3F",
   "#9E9E9E",
   "#F2F2F2",
   "#2BB673",
   "#EF4136",
   "#14C1C1",
   "#2B3990",
   "#D6D600",
   "#212C3C",
   "#EFE3D3",
   "#CFAB8A",
   "#BA6D22",
   "#F0B845",
   "#E1CA21",
   "#7CA851",
   "#3E4F3E",
   "#1A4940",
   "#4E9B74",
   "#C5D3CB",
   "#990420",
   "#E1547C",
   "#CC1962",
   "#860649",
   "#631D7F",
   "#C0B2C5",
   "#02094B",
   "#004772",
   "#0674B7",
   "#F91E1E",
   "#FFD4D4",
   "#440505",
   "#434C5E",
   "#20668C",
   "#8ECEC8",
   "#0E3026",
   "#BF8800",
   
  ]



  let cards = hexArray.map((elem) => {

    return (

        <Card onClick={() => changeColor(elem)} value={elem} style={{border:"1px solid black"}} variant="outlined" sx={{ maxWidth: "3.75vw", minWidth: "3.75vw", minHeight: "3.75vh", maxHeight: "3.75vh", bgcolor: elem, margin:"3px", cursor:"pointer"}}>{(elem === "") ? <h6>X</h6> : <></>}</Card>
    )
  })

  const patternArray = [
   {value:1, pattern: solid},
   {value:2, pattern: con1},
   {value:3, pattern: con2},
   {value:4, pattern: hor1},
   {value:5, pattern: hor2},
   {value:6, pattern: vert1},
   {value:7, pattern: vert2},
   {value:8, pattern: d1},
   {value:9, pattern: d2},
   {value:10, pattern: pl1},
   {value:11, pattern: pl2},

  
  ]


  useEffect(() => {
    const matchingPattern = photoData.find((elem) => elem.sku === selectedSku)?.pattern || "";
    const matchingVector = photoData.find((elem) => elem.sku === selectedSku)?.vector || "";
    setVectorSelect(matchingVector)
    setPatternSelect(matchingPattern);
  }, [selectedSku, photoData]);



  useEffect(() => {
    if (selectedTab === 2 || selectedTab === 3) {

      setSelectedSubColor(1)

    }



  }, [selectedTab, selectedSku])


  useEffect(() => {


  





    let focusedColor = ""

    let focusSku = photoData.find((elem) => elem.sku === selectedSku)
    console.log(focusSku)
    console.log(selectedSubColor)

    if (focusSku) {

    if (selectedSubColor == 1) {
      focusedColor = focusSku.primary
    }

    else if (selectedSubColor == 2) {
      focusedColor = focusSku.primary2
    }

    else if (selectedSubColor == 3) {
      focusedColor = focusSku.primary3
    }


    setColor2(focusedColor)

    console.log(focusedColor)
  }
  


  }, [selectedSubColor, selectedSku])



    let patternCards = patternArray.map((elem) => {
      
      const isSelected = patternSelect === elem.value;


    
      return (
        <Card
          onClick={() => changePattern(elem.value)}
          value={elem.value}
          style={{
            border: isSelected ? "3px solid red" : "1px solid black", 
            cursor: "pointer",
          }}
          variant="outlined"
          sx={{
            maxWidth: "8vw",
            minWidth: "8vw",
            minHeight: "12vh",
            maxHeight: "12vh",
            margin: "6px",
          }}
        >
          <img src={elem.pattern} alt={elem.pattern} />
        </Card>
      );
    });

  const vectorArray = [
    {value:100, vector: ""},
    {value:1, vector: snow1},
    {value:2, vector: snow2},
    {value:3, vector: paca},
    {value:4, vector: paca2},

 
   
   ]
 
 
   let vectorCards = vectorArray.map((elem) => {

    const isSelected = vectorSelect === elem.value;
 
     return (
 
      <Card
      onClick={() => changeVector(elem.value)}
      value={elem.value}
      style={{
        border: isSelected ? "3px solid red" : "1px solid black", 
        cursor: "pointer",
      }}
      variant="outlined"
      sx={{
        maxWidth: "8vw",
        minWidth: "8vw",
        minHeight: "12vh",
        maxHeight: "12vh",
        margin: "6px",
      }}
    >
      <img style={{width:"8vw", height:"12vh"}} src={elem.vector} alt={elem.vector} />
    </Card>
     )
   })

  const handleSubChange = (e) => {

    setSelectedSubColor(e.target.value)

  }


  return (
    <div>
<Button variant="contained"  style={{ margin: "1vw", marginLeft:"2.3vw"}} onClick={handleOpen}>Edit Button</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      <Box
  sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 0,
    p: 4,
    minWidth: "75vw",
    minHeight: "75vh",
    maxWidth: "80vw",
    maxHeight: "80vh",
  }}
>
  <Typography id="modal-modal-title" variant="h6" component="h2"></Typography>
  <label onClick={handleClose} style={{marginTop:"0px", marginLeft:"75vw", color:"red", cursor:"pointer"}}>X</label>
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
   
    <div style={{ display: "flex", height: "100%" }}>
      {/* Left side (Image and Circle) */}
      <div style={{  display: "flex", flexDirection: "column", alignItems: "center",  }}>
        <img
          src={image}
          style={{ maxWidth: "15vw", maxHeight: "15vw", border: "0px solid black" }}
          alt="Image"
        />
        <ComplexCircle photoData={photoData} details={details} buttonPass={buttonPass} />
      </div>

      {/* Right side (Radio group and Tabs) */}
      <div style={{ display: "flex",  paddingLeft: "30px" }}>
        {/* Radio Group */}
        <FormControl style={{border:"0px solid black"}} >
          <FormLabel id="demo-controlled-radio-buttons-group">SKU</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selectedSku}
            onChange={handleChange}
          >
            {radioOptions}
          </RadioGroup>
        </FormControl>

        {/* Tabs */}
        <Box sx={{ border: "1.5px solid black", borderRadius:"20px", height: "60vh", maxHeight: "60vh", width:"50vw", marginLeft:"20px" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Modal Tabs">
              <Tab label="Pattern" />
              <Tab label="Vector" />
              <Tab label="Primary" />
              <Tab label="Secondary" />
            </Tabs>
          </Box>
          <TabPanel>
            {selectedTab === 0 && (
              <div>
               <Box  style={{marginLeft:"0.5vw"}} sx={{ minWidth: 275 }}>
                <Grid container spacing={1}>
            

                    {patternCards}

                </Grid>

                </Box>
              </div>
            )}
            {selectedTab === 1 && (
               <div>
               <Box  style={{marginLeft:"0.5vw"}} sx={{ minWidth: 275 }}>
                <Grid container spacing={1}>
            

                    {vectorCards}

                </Grid>

                </Box>
              </div>
            )}

            {selectedTab === 2 && (
              <div style={{display:"flex"}}>


            <FormControl style={{ width:"10vw", marginRight:"3vw"}} >
                    {/* <FormLabel id="demo-controlled-radio-buttons-group">SKU</FormLabel> */}
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={selectedSubColor}
                        onChange={handleSubChange}
                    >
                         <FormControlLabel value={1} control={<Radio />} label="1." />
                         <FormControlLabel value={2} control={<Radio />} label="2." />
                         <FormControlLabel value={3} control={<Radio />} label="3." />
                    </RadioGroup>
            </FormControl>



                <div style={{display:"flex", flexDirection:"column"}}>


                

                <Box  style={{marginLeft:"0.5vw"}} sx={{ minWidth: 275 }}>
                <Grid container spacing={1}>

                <Card 
                    onClick={() => changeColor("")} 
                    value="" 
                    style={{border:"1px solid black"}} 
                    variant="outlined" 
                    sx={{ 
                      maxWidth: "3.75vw", 
                      minWidth: "3.75vw", 
                      minHeight: "3.75vh", 
                      maxHeight: "3.75vh", 
                      bgcolor: "white", 
                      margin: "3px", 
                      cursor: "pointer", 
                      display: "flex",  // Ensure the card behaves as a flex container
                      alignItems: "center",  // Center content vertically
                      justifyContent: "center" // Center content horizontally
                    }}
                  >
                    <div style={{ 
                      color: "red", 
                      width: "100%",  // Make the "X" fill the entire card width
                      height: "100%", // Make the "X" fill the entire card height
                      display: "flex", // Use flex to center the "X"
                      alignItems: "center", 
                      justifyContent: "center",
                      fontSize: "2vw" // Adjust font size to make "X" larger if needed
                    }}>
                      X
                    </div>
                  </Card>

                    {cards}

                </Grid>

                </Box>

                <HexColorPicker
                style={{height:"30vh", width:"37.75vw"}}
                 color={color2}
                onChange={changeColor}
                 />
               

                </div>
              </div>
            )}

          {selectedTab === 3 && (
              <div style={{display:"flex"}}>


            <FormControl style={{ width:"10vw", marginRight:"3vw"}} >
                    {/* <FormLabel id="demo-controlled-radio-buttons-group">SKU</FormLabel> */}
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={selectedSubColor}
                        onChange={handleSubChange}
                    >
                         <FormControlLabel value={1} control={<Radio />} label="1." />

                    </RadioGroup>
            </FormControl>



                <div style={{display:"flex", flexDirection:"column"}}>


                

                <Box  style={{marginLeft:"0.5vw"}} sx={{ minWidth: 275 }}>
                <Grid container spacing={1}>
                {/* <Card onClick={() => changeColor("")} variant="outlined" sx={{ maxWidth: "5vw", minWidth: "5vw", minHeight: "5vh", maxHeight: "5vh", bgcolor: "FFFFFF", margin:"3px"}}>
                    X
                </Card> */}

                    {cards}

                </Grid>

                </Box>

                <HexColorPicker
                style={{height:"30vh", width:"37.75vw"}}
                 color={color2}
                onChange={changeColor}
                 />

                </div>
              </div>
            )}
          </TabPanel>
        </Box>
      </div>
    </div>

    
  </Typography>
  <Typography    style={{marginLeft:"71vw", marginTop:"7px", marginBottom:"0px"}}>
        <Button variant="contained" onClick={handleLog}>SAVE</Button>

  </Typography>
</Box>
      </Modal>
    </div>
  );
}