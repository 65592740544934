import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from "axios"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Slider from '@mui/material/Slider';

import { HexColorPicker } from "react-colorful";

import BIPhotoCreate from "../components/BIPhotoCreate"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80vw",
  height: "90vh",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BICreateModal( {data, cData} ) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    getDefaults()
    setOpen(true)

}
  const handleClose = () => setOpen(false);
  const [color, setColor] = useState("");


  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [selectedData, setSelectedData] = useState([]);

  const [value2, setValue2] = useState("");
  const [inputValue2, setInputValue2] = useState("");

  const [selectedData2, setSelectedData2] = useState([]);

  const [value3, setValue3] = useState("");
  const [inputValue3, setInputValue3] = useState("");

  const [selectedData3, setSelectedData3] = useState([]);



  // const options = ['Option 1', 'Option 2', "3343 - Gray/Alpaca"];



const lData = () => {
  console.log(selectedData)
  console.log(selectedData2)
}

let options = cData.map((elem) => {

  let selectForm = elem.sku + " - " + elem.option2


  return (
    selectForm
  )
})


const matchData = (event, newValue) => {
  setValue(newValue);

  let foundData = cData.find((elem) => (elem.sku + " - " + elem.option2) === newValue)

  console.log(foundData)

  setSelectedData([foundData])
}

const matchData2 = (event, newValue) => {
  setValue2(newValue);

  let foundData = cData.find((elem) => (elem.sku + " - " + elem.option2) === newValue)

  console.log(foundData)

  setSelectedData2([foundData])
}


const matchData3 = (event, newValue) => {
  setValue3(newValue);

  let foundData = cData.find((elem) => (elem.sku + " - " + elem.option2) === newValue)

  console.log(foundData)

  setSelectedData3([foundData])
}
 


//   const [defaultSliderValues, setDefaultSliderValues] = useState([-80, 700, 300]);

let testIndex = [1,2,3]

let defaultSliderValues = [-80, 700, 300]

  const [sliderValues, setSliderValues] = useState(
    testIndex.reduce((acc, elem, index) => {
      // Assign a default value from defaultSliderValues array based on the index
      // If index exceeds the length of defaultSliderValues, use the last value
      const defaultValue = defaultSliderValues[index] || defaultSliderValues[defaultSliderValues.length - 1];
      acc[index] = defaultValue;
      return acc;
    }, {})
  );


 let  defaultSliderValues2 = [-80, -80, 300]

  const [sliderValues2, setSliderValues2] = useState(
    testIndex.reduce((acc, elem, index) => {
      // Assign a default value from defaultSliderValues array based on the index
      // If index exceeds the length of defaultSliderValues, use the last value
      const defaultValue = defaultSliderValues2[index] || defaultSliderValues[defaultSliderValues2.length - 1];
      acc[index] = defaultValue;
      return acc;
    }, {})
  );



    let defaultSliderValues3 = [600, 600, 600]

  const [sliderValues3, setSliderValues3] = useState(
    testIndex.reduce((acc, elem, index) => {
      // Assign a default value from defaultSliderValues array based on the index
      // If index exceeds the length of defaultSliderValues, use the last value
      const defaultValue = defaultSliderValues3[index] || defaultSliderValues[defaultSliderValues3.length - 1];
      acc[index] = defaultValue;
      return acc;
    }, {})
  );


  const handleXChange = (sku, event, newValue) => {
    setSliderValues(prevValues => ({
      ...prevValues,
      [sku]: newValue
    }));
  };

  const handleYChange = (sku, event, newValue) => {
    setSliderValues2(prevValues => ({
      ...prevValues,
      [sku]: newValue
    }));
  };

  const handleSChange = (sku, event, newValue) => {
    setSliderValues3(prevValues => ({
      ...prevValues,
      [sku]: newValue
    }));
  };






  const sliders = data.children.map((elem, i) => (
    <>
    <div  style={{width: "12vw", height: "8vh",marginLeft:"2vw"}} > 
      <h5 style={{margin:"0vw"}}>{value}</h5>

      <Autocomplete
              value={value}
              onChange={matchData}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={options}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Controllable" />}
            />

      <Slider
          size="small"
        value={sliderValues[i]}
        onChange={(event, newValue) => handleXChange(i, event, newValue)}
        min={-100}
        max={1200}
        step={10}
       
        marks={[
          { value: 0, label: '0' },
          { value: 600, label: '600' },
          { value: 1100, label: '1100' }
        ]}
      />
      {/* <h6>X OFFSET</h6> */}
  
    </div>
        <div style={{width: "12vw", height: "8vh" ,marginLeft:"2vw"}} > {/* Use a unique key for each mapped item */}
     
        <Slider
          size="small"
          value={sliderValues2[i]}
          onChange={(event, newValue) => handleYChange(i, event, newValue)}
          min={-100}
          max={1200}
          step={10}
          marks={[
            { value: 0, label: '0' },
            { value: 600, label: '600' },
            { value: 1100, label: '1100' }
          ]}
        />
        {/* <h5>Y OFFSET</h5> */}
      </div>
      
        <div  style={{width: "12vw", height: "8vh" ,marginLeft:"2vw"}}  > {/* Use a unique key for each mapped item */}
       
        <Slider
            size="small"
          value={sliderValues3[i]}
          onChange={(event, newValue) => handleSChange(i, event, newValue)}
          min={300}
          max={1200}
          step={10}
          marks={[
            { value: 300, label: '300' },
            { value: 600, label: '600' },
            { value: 1100, label: '1100' }
          ]}
        />
        {/* <h5>Size</h5> */}
      </div>
      </>
  ));

  const changeColor = (e) => {
    // console.log(e)
    setColor(e)
  }



//   let condArray = b

const log = () => {
    console.log(sliderValues)
    console.log(sliderValues2)
    console.log(sliderValues3)
    console.log(sliderValues["S2241"])

}

const clear = () => {
    setColor("")
    console.log(color)
}

const setHex = (e) => {
    setColor(e.target.value)
}




//DB POST
const sendDefaults = () => {
    axios.post("/api/bi/create", {
        productId: data.productId,
        SV1: Object.values(sliderValues),
        SV2: Object.values(sliderValues2),
        SV3: Object.values(sliderValues3),
        hex: color
    }).then((res) => {console.log(res)})
}

//DB GET
const getDefaults = () => {
    axios.post("/api/bi/getone", {
        productId: data.productId
    }).then((res) => {
        console.log(res)
        if (res.data) {
            console.log("FIRE")
            // setDefaultSliderValues(res.data.SV1)
            // setDefaultSliderValues2(res.data.SV2)
            // setDefaultSliderValues3(res.data.SV3)

            defaultSliderValues = res.data.SV1
            defaultSliderValues2 = res.data.SV2
            defaultSliderValues3 = res.data.SV3

            setColor(res.data.hex)
 
                overrideDefaults()
        }
    })
}

const logS = () => {
    console.log(defaultSliderValues)
    console.log(defaultSliderValues2)
    console.log(defaultSliderValues3)
    console.log(sliderValues)
    console.log(sliderValues2)
    console.log(sliderValues3)
    console.log(data.productId)
}

//USEEFFFECT
// useEffect(() => {
//     getDefaults()
  
//   }, [open]);

  //OR
  const overrideDefaults = () => {

    setSliderValues(
        testIndex.reduce((acc, elem, index) => {
            // Assign a default value from defaultSliderValues array based on the index
            // If index exceeds the length of defaultSliderValues, use the last value
            const defaultValue = defaultSliderValues[index] || defaultSliderValues[defaultSliderValues.length - 1];
            acc[index] = defaultValue;
            return acc;
          }, {})
    )

    setSliderValues2(
        testIndex.reduce((acc, elem, index) => {
            // Assign a default value from defaultSliderValues array based on the index
            // If index exceeds the length of defaultSliderValues, use the last value
            const defaultValue = defaultSliderValues2[index] || defaultSliderValues2[defaultSliderValues2.length - 1];
            acc[index] = defaultValue;
            return acc;
          }, {})
    )

    setSliderValues3(
        testIndex.reduce((acc, elem, index) => {
            // Assign a default value from defaultSliderValues array based on the index
            // If index exceeds the length of defaultSliderValues, use the last value
            const defaultValue = defaultSliderValues3[index] || defaultSliderValues3[defaultSliderValues3.length - 1];
            acc[index] = defaultValue;
            return acc;
          }, {})
    )


    
  }





  return (
    <div>
      <Button onClick={handleOpen}>Create</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
       
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {data.title}
          </Typography>
          <div style={{display: "flex"}}>
            <div style={{marginTop: "5vh"}}>
               
                <HexColorPicker  color={color} onChange={changeColor} />
                <button onClick={clear}>Transparent</button>
                <input onChange={setHex} placeholder="Set Hex" />
                <br></br>
                <button style={{marginTop:"15vh"}} onClick={sendDefaults}>Set Defaults</button>
                {/* <button onClick={getDefaults}>Get Defaults</button>
                <button onClick={logS}>LOG</button>
                <button onClick={overrideDefaults}>OVERRIDE</button> */}
            </div>
                <div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {/*  */}

            

            <Autocomplete
              value={value}
              onChange={matchData}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={options}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="SKU 1" />}
            />
                <Slider
              size="small"
            value={sliderValues[0]}
            onChange={(event, newValue) => handleXChange(0, event, newValue)}
            min={-100}
            max={1200}
            step={10}
          
            marks={[
              { value: 0, label: '0' },
              { value: 600, label: '600' },
              { value: 1100, label: '1100' }
            ]}
          />

          <Slider
            size="small"
            value={sliderValues2[0]}
            onChange={(event, newValue) => handleYChange(0, event, newValue)}
            min={-100}
            max={1200}
            step={10}
            marks={[
              { value: 0, label: '0' },
              { value: 600, label: '600' },
              { value: 1100, label: '1100' }
            ]}
          />

        <Slider
            size="small"
          value={sliderValues3[0]}
          onChange={(event, newValue) => handleSChange(0, event, newValue)}
          min={300}
          max={1200}
          step={10}
          marks={[
            { value: 300, label: '300' },
            { value: 600, label: '600' },
            { value: 1100, label: '1100' }
          ]}
        />





          <Autocomplete
              value={value2}
              onChange={matchData2}
              inputValue={inputValue2}
              onInputChange={(event, newInputValue) => {
                setInputValue2(newInputValue);
              }}
              id="controllable-states-demo"
              options={options}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="SKU 2" />}
            />
                <Slider
              size="small"
            value={sliderValues[1]}
            onChange={(event, newValue) => handleXChange(1, event, newValue)}
            min={-100}
            max={1200}
            step={10}
          
            marks={[
              { value: 0, label: '0' },
              { value: 600, label: '600' },
              { value: 1100, label: '1100' }
            ]}
          />

          <Slider
            size="small"
            value={sliderValues2[1]}
            onChange={(event, newValue) => handleYChange(1, event, newValue)}
            min={-100}
            max={1200}
            step={10}
            marks={[
              { value: 0, label: '0' },
              { value: 600, label: '600' },
              { value: 1100, label: '1100' }
            ]}
          />

        <Slider
            size="small"
          value={sliderValues3[1]}
          onChange={(event, newValue) => handleSChange(1, event, newValue)}
          min={300}
          max={1200}
          step={10}
          marks={[
            { value: 300, label: '300' },
            { value: 600, label: '600' },
            { value: 1100, label: '1100' }
          ]}
        />




          <Autocomplete
              value={value3}
              onChange={matchData3}
              inputValue={inputValue3}
              onInputChange={(event, newInputValue) => {
                setInputValue3(newInputValue);
              }}
              id="controllable-states-demo"
              options={options}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="SKU 2" />}
            />
                <Slider
              size="small"
            value={sliderValues[2]}
            onChange={(event, newValue) => handleXChange(2, event, newValue)}
            min={-100}
            max={1200}
            step={10}
          
            marks={[
              { value: 0, label: '0' },
              { value: 600, label: '600' },
              { value: 1100, label: '1100' }
            ]}
          />

          <Slider
            size="small"
            value={sliderValues2[2]}
            onChange={(event, newValue) => handleYChange(2, event, newValue)}
            min={-100}
            max={1200}
            step={10}
            marks={[
              { value: 0, label: '0' },
              { value: 600, label: '600' },
              { value: 1100, label: '1100' }
            ]}
          />

        <Slider
            size="small"
          value={sliderValues3[2]}
          onChange={(event, newValue) => handleSChange(2, event, newValue)}
          min={300}
          max={1200}
          step={10}
          marks={[
            { value: 300, label: '300' },
            { value: 600, label: '600' },
            { value: 1100, label: '1100' }
          ]}
        />
     






            {/* <button onClick={lData}>LOG DATA</button> */}
            {/* <Autocomplete
              value={value2}
              onChange={matchData2}
              inputValue={inputValue2}
              onInputChange={(event, newInputValue) => {
                setInputValue2(newInputValue);
              }}
              id="controllable-states-demo"
              options={options}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Controllable" />}
            /> */}

            {/* <img src={dataSrc[0].src}></img>
            <img src={dataSrc[1].src}></img>
            <img src={dataSrc[2].src}></img> */}
            {/* {sliders}
       */}
          
          </Typography>
          </div>
          <div>
          {/* <button onClick={log}>LOG</button> */}
          <BIPhotoCreate color={color} data={data} SV1 = {sliderValues} SV2={sliderValues2} SV3 = {sliderValues3} selectedData={selectedData} selectedData2={selectedData2} selectedData3={selectedData3} />
          </div>
          </div>
        </Box>
        
      </Modal>
    </div>
  );
}