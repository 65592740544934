import React, { useEffect, useState } from 'react';
import axios from "axios"

//mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';



//components
import FilterModal from "../AnalyticsModals/filterModal"
import AddComponent from "../AnalyticsModals/addComponent"
import ViewData from '../AnalyticsModals/ViewData';


export default function AnalyticsGrid() {

    const [component, setComponent] = useState([]);


    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);

    const [dataSet, setDataSet] = useState("");
    const [dataSet2, setDataSet2] = useState("");
    const [dataSet3, setDataSet3] = useState("");


    const [dataKeys, setDataKeys] = useState([]);

    let testArray = []

    const handleKeyChange = (keys) => {

        testArray = keys


        //ASYNC ISSUE THIS LOG IS REQUIRED
        console.log(testArray)


    }




    const getData = async () => {
        let data = await axios.post("/api/shopify/getorders", {max:"2023-06-07T012:00:00-06:00", min: "2023-06-07T08:00:00-06:00" })
        console.log(data.data[1].orders)
        setData(data.data[1].orders)
        setDataKeys(Object.keys(data.data[1].orders[0]))

    }

    const filterData = () => {
     

        console.log(testArray)

          const filteredData = data.map((elem) => {
            const filteredObject = {};
            Object.keys(elem).forEach((key) => {
              if (testArray.includes(key)) {
   
                filteredObject[key] = elem[key];
          
              }
            });

            return(filteredObject)
       
          });


          console.log(filteredData)
          setData2(filteredData)
    }

    //REDESIGN
    const setDataSource = (data) => {
        if (dataSet === "") {
            setDataSet(data)
        } else if (dataSet !== "" && dataSet2 === ""){
            setDataSet2(data)
        } else {
            setDataSet3(data)
        }
        
    }

    //COOMPENT JSX


    //FITLER
    let filterJSX = 
    <div
    style={{
        border: "1px solid black",
        minHeight: "15vh",
        minWidth: "10vw",
        maxHeight: "15vh",
        maxWidth: "10vw",
        boxShadow: data2.length > 0 ? "5px 5px 5px green" : "5px 5px 5px red"
    }}>
        <div >
            {dataSet2}
        </div>
        
        {data.length && <FilterModal handleKeyChange={handleKeyChange} dataKeys={dataKeys} />}
      <button onClick={filterData}>RUN</button>
        
    
    </div>
    // END FILTER

    //VIEW
    let viewJSX =
    <div
        style={{
            border: "1px solid black",
            minHeight: "15vh",
            minWidth: "10vw",
            maxHeight: "15vh",
            maxWidth: "10vw",
            boxShadow: data2.length > 0 ? "5px 5px 5px green" : "5px 5px 5px red"
        }}>
            <div >
                {dataSet3}
            </div>
     
            
            {data.length && <ViewData data={data2.length >0 ? data2 : data} />}

            

        </div>
    //END VIEW


    let dataSkeleton = 
    <div
    style={{
        backgroundColor:"white",
        border: "1px solid black",
        minHeight: "15vh",
        minWidth: "10vw",
        maxHeight: "15vh",
        maxWidth: "10vw",
        boxShadow:"5px 5px 5px red"
    }}>
        <div >
            <AddComponent setDataSource={setDataSource} />
        </div>
        

    </div>


    let dataComponent = 
    <div
    style={{
        backgroundColor:"white",
        border: "1px solid black",
        minHeight: "15vh",
        minWidth: "10vw",
        maxHeight: "15vh",
        maxWidth: "10vw",
        boxShadow: data.length > 0 ? "5px 5px 5px green" : "5px 5px 5px red"
    }}>
        <div >
            {dataSet}
        </div>
        <button onClick={getData}>RUN</button>
        

    </div>

let dataComponent2 = <div></div>


let dataComponent3 = <div></div>

if (dataSet2 === "VE") {
    dataComponent2 = viewJSX
}

if (dataSet2 === "TESTAPI") {
    dataComponent2 = filterJSX
}

if (dataSet3 === "VE") {
    dataComponent3 = viewJSX
}

if (dataSet3 === "TESTAPI") {
    dataComponent3 = filterJSX
}








    return (
        <div style={{ marginLeft: "200px" }}>



        <div  className="grid" style={{backgroundColor: "#f9f9f9", minWidth:"80vw",maxWidth:"80vw", minHeight:"70vh"}}>
            <div style={{display: "flex"}}>
            <div style={{margin: "2.5vw"}}>{dataSet ? dataComponent:  dataSkeleton}</div>

            {dataSet && <div style={{margin: "2.5vw"}}>{dataSet2 ? dataComponent2:  dataSkeleton}</div>}
            {dataSet2 && <div style={{margin: "2.5vw"}}>{dataSet3 ? dataComponent3:  dataSkeleton}</div>}
            </div>
           


        </div>
       
        
        </div>
    )
}







