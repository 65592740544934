
import React from "react"
import axios from "axios"
import SignOut from "../components/SignOut"






const formSubmit = () => {

    axios.post("/api/activity/create", {
        action: "CREATE",
        details: "Details",
        time: "today",
        user: "Jacob"
    })
    .then((res) => { 
        console.log(res.data)
    })
}

const formSubmit2 = () => {
 
}





export default function Activity() {
    return (
        <div style={{ marginLeft: "200px" }}>
            
            <button onClick={formSubmit}>Submit</button>
            <button onClick={formSubmit2}>Submit2</button>
            <SignOut />
        </div>
    )
}