import React, { useEffect, useState, useRef } from 'react';
import axios from "axios"

//ASSEST
import loading from "../assets/loading.gif"

//COMPONENTS
import PackSingleDisplay from '../components/PackSingleDisplay';
import ModalData from '../components/ModalData';

//MUI
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Catalog from './Catalog';



export default function CataLogDisplay( {data} ) {

    const [cat, setCat] = useState("Women's")
    const [style, setStyle] = useState("Crew Socks")

    //Select Functions
    const handleCat = (event) => {
        setCat(event.target.value);

      };
    const handleStyle = (event) => {
    setStyle(event.target.value);

    };



    let dataSelect1 = []
    let dataSelect2 = []

  

    if (cat!== "All") {
         dataSelect1 = data.filter(elm => elm.cat === cat)
    } else {
          dataSelect1 = data
    }

    if (style !== "All") {
         dataSelect2 = dataSelect1.filter(elm => elm.style === style)
    } else {
         dataSelect2 = dataSelect1
    }



    let dataFinal = dataSelect2




   






    if (data.length > 0) {
        return(
            <div>
                <div style={{ border: "1px solid white", padding: "10px", marginBottom: "5px", borderRadius: "10px", marginLeft:"200px" } } >
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                >

            <Box sx={{ minWidth: 150, maxWidth: 150 }}>
                    <FormControl fullWidth>
                        <InputLabel sx={{  color: "white" }} id="demo-simple-select-label">Category</InputLabel>
                        <Select
                        sx={{  color: "white" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={cat}
                        label="Category"
                        onChange={handleCat}
                        >
                        <MenuItem value={"All"}>All</MenuItem>
                        <MenuItem value={"Women's"}>Women's</MenuItem>
                        <MenuItem value={"Men's"}>Men's</MenuItem>
                        <MenuItem value={"Kids'"}>Kids'</MenuItem>
                        </Select>
                    </FormControl>
             </Box>
             <Box sx={{ minWidth: 150, maxWidth: 150 }}>
                    <FormControl  fullWidth>
                        <InputLabel sx={{  color: "white" }} id="demo-simple-select-label">Style</InputLabel>
                        <Select
                        sx={{  color: "white" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={style}
                        label="Style"
                        onChange={handleStyle}
                        >
                        <MenuItem value={"All"}>All</MenuItem>
                        <MenuItem value={"Kid Socks"}>Kid Socks</MenuItem>
                        <MenuItem value={"Crew Socks"}>Crew Socks</MenuItem>
                        <MenuItem value={"Light-Weight Crew Socks"}>Light-Weight Crew Socks</MenuItem>
                        <MenuItem value={"Compression Socks"}>Compression Socks</MenuItem>
                        <MenuItem value={"Low-Cut Socks"}>Low-Cut Socks</MenuItem>
                        <MenuItem value={"No-Show Socks"}>No-Show Socks</MenuItem>
                        <MenuItem value={"Golf Socks"}>Golf Socks</MenuItem>
                        <MenuItem value={"Performance Socks"}>Performance Socks</MenuItem>
                        <MenuItem value={"Slouchy Socks"}>Slouchy Socks</MenuItem>
                        <MenuItem value={"Hiking Socks"}>Hiking Socks</MenuItem>
                        </Select>
                    </FormControl>
             </Box>
            <h1 style={{fontFamily: "Font1"}} >.</h1>
            <h1 style={{fontFamily: "Font2"}} >&</h1>
            <h1 style={{fontFamily: "Font3"}} >&</h1>
            <h1 style={{fontFamily: "Font4"}} >&</h1>


             </Stack>
             </div>
            <div style={{ height: "85vh", overflowY: "scroll",marginBottom: "40px" } } className="pack-display">
            <Catalog data={dataFinal} cat={cat} style={style} />
            </div>

            </div>
        )
    } else {
        return (
            <img src={loading} />
        )
    }

}
