import { useState, useEffect } from "react";
import Papa from "papaparse"
import axios from "axios"


export default function Override() {

    const [overData, setOverData] = useState([]);
    const [data, setData] = useState([]);
    const [inputValues, setInputValues] = useState({});

    const handleFileUpload = (e) => {
        let currentTime = new Date().toLocaleString()
        console.log(currentTime)
        const files = e.target.files;
        console.log(files);
        if (files) {
          console.log(files[0]);
          Papa.parse(files[0], {
            header: true,
            complete: function(results) {
              console.log("Finished:", results.data);
              let formArray = results.data.map(elem => {
           
      
                return (
                  {
                    sku: elem.sku,
                    quantity: elem.quantity,
                    reduction: parseInt(elem.reduction),
                    status: false
                  }
                )
      
          
              })
              console.log(formArray)
              setOverData(formArray)
            }}
          )
        }
      
      }



      const dbPost = (data) => {
        axios.post("/api/override/create", 
            data
        ).then((res) => {
            console.log(res.data)
            if (res.status === 200) {
              getDoc()
            }
        })
      }


      const getAll = () => {
        axios.post("/api/shopify/getallvariants", {}).then((res) => {
 
            let allVariants = res.data.products.map(elem => {
                return (
                    elem.variants
                )
            }).flat()
            let failArray = []
            let matchArray = overData.map(elem => {
                let foundVariants = allVariants.find((elm) => elm.sku == elem.sku)
                if (foundVariants) {
                    return(
                        {
                            sku: elem.sku,
                            quantity: elem.quantity,
                            reduction: elem.reduction,
                            status: elem.status,
                            inventoryItemId: foundVariants.inventory_item_id,
                            updatedTime: ""
                        }
                    )

                } else {
                    failArray.push(elem.sku)
                    alert("Some Variants not Found")
                    console.log(failArray)
                }
             


            }).filter(ellm => ellm !== undefined)

            dbPost(matchArray)



            console.log(matchArray)

        })
      }


      const getDoc = () => {
        axios.post("/api/override/get", {}).then((res) => {
            console.log(res.data)
            let dataForm = res.data.map(elem => {
                return (
                    {
                        sku: elem.sku,
                        inventoryItemId: elem.inventoryItemId,
                        quantity: elem.quantity,
                        reduction: elem.reduction,
                        updatedTime: elem.updatedTime
                    }
                )
            })
            setData(dataForm)
        })
      }

      const destroyOne = (id) => {
        axios.post("/api/override/destroy", {
            inventoryItemId: id
        }).then((res) => {
            console.log(res)
            if (res.status === 200) {
              getDoc()
            }
        })
        console.log(id)
      }

      const updateOne = (id, quantity) => {
           axios.post("/api/override/update", {
            inventoryItemId: id,
               reduction: quantity
        }).then((res) => {
            console.log(res)
            if (res.status === 200) {
              getDoc()
            }
        })
        console.log(quantity)
        console.log(id)
      }

      const handleInputChange = (inventoryItemId, value) => {
        setInputValues({
          ...inputValues,
          [inventoryItemId]: value
        });
      };

      useEffect(() => {

       getDoc()
      }, []);


      const manual = () => {
        axios.post("/api/override/manual", {}).then((res) => {
          if (res.status === 200) {
            getDoc()
          } 
        })
      }


      const Table = () => (
        <table className="data-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                {headers.map((header) => (
                  <td key={header}>{row[header]}</td>
                ))}
                <td>
                  <input
                    value={inputValues[row.inventoryItemId] || ''}
                    onChange={(e) => handleInputChange(row.inventoryItemId, e.target.value)}
                  />
                  <button onClick={() => updateOne(row.inventoryItemId, inputValues[row.inventoryItemId] || '')}>
                    UPDATE
                  </button>
                  <button onClick={() => destroyOne(row.inventoryItemId)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );


      if (!data || !data.length) {
        return (
          <div className="upload-container">
            <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
            <button onClick={getAll}>UPLOAD</button>
            <button style={{marginLeft: "40vw"}} onClick={manual}>RUN</button>
          </div>
        );
      }
    
      const headers = Object.keys(data[0]);
      return (
        <div className="override-container">
          <div className="upload-container">
            <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
            <button onClick={getAll}>UPLOAD</button>
            <button style={{marginLeft: "40vw"}} onClick={manual}>RUN</button>
          </div>
          <Table />
        </div>
      );
    }


 

     
//      if (!data || !data.length) {
//         return (
//             <div style={{marginLeft:"200px"}}>
         
//             <input
//              type="file"
//              accept=".csv,.xlsx,.xls"
//              onChange={handleFileUpload}
//              />
             
//              <button onClick={getAll}>UPLOAD</button>
//              {/* <button onClick={getDoc}>GET DOC</button> */}
//              </div>
//         )
//      } else {

     
     
//       const headers = Object.keys(data[0]);


//     return (
//         <div style={{ marginLeft: "200px" }}>

//         <input
//           type="file"
//           accept=".csv,.xlsx,.xls"
//           onChange={handleFileUpload}
//         />
//         <button onClick={getAll}>UPLOAD</button>
//         <button onClick={test}>TEST</button>


//         <table border="1">
//           <thead>
//             <tr>
//               {headers.map((header) => (
//                 <th key={header}>{header}</th>
//               ))}
//               <th>Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data.map((row, index) => (
//               <tr key={index}>
//                 {headers.map((header) => (
//                   <td key={header}>{row[header]}</td>
//                 ))}
//                 <td>
//                 <input 
//                 value={inputValues[row.inventoryItemId] || ''} // Use the state variable
//                 onChange={(e) => handleInputChange(row.inventoryItemId, e.target.value)}
//               />
//               <button onClick={() => updateOne(row.inventoryItemId, inputValues[row.inventoryItemId] || '')}>
//                 UPDATE
//               </button>
//                   <button onClick={() => destroyOne(row.inventoryItemId)}>
//                     Delete
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     )
//                         }
// }