


import React, { useEffect, useState } from 'react';
import axios from "axios"






export default function BIAPI(props) {

const uploadImage = () => {
    axios.post("/api/shopify/upload", {
        productId: props.data.productId,
        b64: props.base64Image.substring(22),
        fileName: "image"
    }).then((res) => {
        //
        let imageId = res.data.image.id
        axios.post("/api/shopify/associate", {
            
            variant_id: props.data.variantId,
            image_id: imageId
        }).then((res) => {
            console.log(res)
            alert("success")
        })
    })
}




  return (
    <div style={{marginLeft: "3vw"}} >
        <button>Download</button>

      <button style={{marginLeft:"15vw"}} onClick={uploadImage}>Create</button>

    </div>
  );
}