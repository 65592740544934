
import React from "react"



export default function Fulfillment() {
    return (
        <div style={{ marginLeft: "200px" }}>
            <h1>Fulfillment</h1>
        </div>
    )
}