
import React, { useState, useRef } from 'react';
import axios from "axios"


import jsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';


const canvas = document.createElement('canvas');

canvas.width = 600;
canvas.height = 400;

const ctx = canvas.getContext('2d')

ctx.fillStyle = "white";
ctx.fillRect(0, 0, canvas.width, canvas.height);













export default function Activity() {

    const [image, setImage] = useState("<img></img>");
    const [sku, setSku] = useState("");
    const [upc, setUpc] = useState("");
    const [po, setPo] = useState("HC1013MNW");
    const [ro, setRo] = useState("4360393");
    const [quantity, setQuantity] = useState("40");



    // let sku = "1-3333X1-4444X1-6666X1-9999X1-2222X1-22222"
    let weight = "8.85 KG"
    // let upc = "810118930423"
    // let po = "PACA-92823"
    // let quantity = "30"
    let dim = "22 x 15 x 10.5 (in)"

    const handleSkuChange = (e) => {
        setSku(e.target.value)
    }

    const handleUpcChange = (e) => {
        setUpc(e.target.value)
    }



    const createBarcode = (upc) => {
        const canvas = document.createElement('canvas');
        canvas.width = 250;
        canvas.height = 110;
        console.log("TEST")
      
        JsBarcode(canvas, upc, {format: "UPC"});
        return canvas.toDataURL("image/png");
      }

    const exportPDF = () => {
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'in',
            format: [9, 6],
          });
          pdf.addImage(canvas.toDataURL(), 'JPEG', 0, 0, 9, 6);
          pdf.save("BOX_" + sku + ".pdf")

    }

    const generate = (e) => {
        if (e.target.value === "clear") {
            ctx.reset()
            setImage("")
        } else {

        }

        let barImg = new Image()
        barImg.onload = () => {

            ctx.drawImage(barImg, 290, 180, 250, 110)

            ctx.font = "40px Helevetica";
            ctx.fillStyle = "black";

            //COMPANY
            ctx.fillText("PACAS", 220, 40)

            ctx.font = "20px Helevetica";

            //SKU
            ctx.fillText("SKU: ",40,100)
            ctx.fillText(sku,100,100)

            //PO
            ctx.fillText("PARTIAL ",40, 140)
            // ctx.fillText(po,100, 140)

            //RO
            ctx.fillText("QUANTITY: ",40, 160)
            // ctx.fillText(ro,100, 160)

            //QTY

            ctx.fillText("QTY: ",40, 190)
            ctx.fillText(quantity,100, 190)

            //Net Weight

            ctx.fillText("N.W. ",40, 240)
            ctx.fillText(weight,100, 240)

            //DIM

            ctx.font = "15px Helevetica";

            ctx.fillText("Dim: ",40, 300)
            ctx.fillText(dim,100, 300)

            ctx.font = "20px Helevetica";

            //UPC
            ctx.fillText("UPC#: ",300, 140)
            ctx.fillText(upc,400, 140)


            ctx.lineWidth = 2.5;

            //Borders
            ctx.beginPath(); // Start a new path
            ctx.moveTo(35, 50); // Move the pen to (30, 50)
            ctx.lineTo(550, 50); // Draw a line to (150, 100)
            ctx.stroke(); // Render the path
            //
            //Borders
            ctx.beginPath(); // Start a new path
            ctx.moveTo(35, 120); // Move the pen to (30, 50)
            ctx.lineTo(550, 120); // Draw a line to (150, 100)
            ctx.stroke(); // Render the path
            //
            //Borders
            ctx.beginPath(); // Start a new path
            ctx.moveTo(35, 170); // Move the pen to (30, 50)
            ctx.lineTo(550, 170); // Draw a line to (150, 100)
            ctx.stroke(); // Render the path
            //
            //Borders
            ctx.beginPath(); // Start a new path
            ctx.moveTo(35, 340); // Move the pen to (30, 50)
            ctx.lineTo(550, 340); // Draw a line to (150, 100)
            ctx.stroke(); // Render the path
            //
            //Borders
            ctx.beginPath(); // Start a new path
            ctx.moveTo(35, 210); // Move the pen to (30, 50)
            ctx.lineTo(280, 210); // Draw a line to (150, 100)
            ctx.stroke(); // Render the path
            //
            //Borders
            ctx.beginPath(); // Start a new path
            ctx.moveTo(35, 260); // Move the pen to (30, 50)
            ctx.lineTo(280, 260); // Draw a line to (150, 100)
            ctx.stroke(); // Render the path
            //

            //Borders VERT 1
            ctx.beginPath(); // Start a new path
            ctx.moveTo(35, 50); // Move the pen to (30, 50)
            ctx.lineTo(35, 340); // Draw a line to (150, 100)
            ctx.stroke(); // Render the path
            //
            //Borders VERT 2
            ctx.beginPath(); // Start a new path
            ctx.moveTo(90, 50); // Move the pen to (30, 50)
            ctx.lineTo(90, 120); // Draw a line to (150, 100)
            ctx.stroke(); // Render the path
                 //Borders VERT 2
                 ctx.beginPath(); // Start a new path
                 ctx.moveTo(90, 170); // Move the pen to (30, 50)
                 ctx.lineTo(90, 340); // Draw a line to (150, 100)
                 ctx.stroke(); // Render the path
            //
            //Borders VERT 3
            ctx.beginPath(); // Start a new path
            ctx.moveTo(280, 120); // Move the pen to (30, 50)
            ctx.lineTo(280, 340); // Draw a line to (150, 100)
            ctx.stroke(); // Render the path
            //
            //Borders VERT 4
            ctx.beginPath(); // Start a new path
            ctx.moveTo(550, 50); // Move the pen to (30, 50)
            ctx.lineTo(550, 340); // Draw a line to (150, 100)
            ctx.stroke(); // Render the path
            //
        
        

            setImage(canvas.toDataURL('image/png'))
        

        }
        barImg.src = createBarcode(upc)



    }

    const handlePO = (e) => {
        setPo(e.target.value)
    }

    const handleQuant = (e) => {
        setQuantity(e.target.value)
    }

    const clear = () => {
        
        
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        
    }



    return (
        <div style={{ marginLeft: "200px" }}>
         <input placeholder='SKU' onChange={handleSkuChange}></input>
         <input placeholder="UPC" onChange={handleUpcChange}></input>

            <button onClick={generate}>GEN</button>
            <button onClick={exportPDF}>EXPORT</button>
            {/* <button value="clear" onClick={generate}>CLEAR</button> */}
            <br></br>
           <img src= {canvas.toDataURL()} />
           <br></br>
           <input onChange={handlePO} value={po}></input>
           <input onChange={handleQuant} value={quantity}></input>
       
        </div>
    )
}