
import React from "react"
import axios from "axios"
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// const getLevels = () => {
//     // axios.post("/api/shopify/getlevels", {

//     // })
//     // .then((res) => {
//     //     console.log(res.data)
//     // })

//     axios.post("/api/shopify/getallsingles", {

//     })
//     .then((res) => {
//         let idArray = []
//         let totalSegments = []
//         let variantArray = res.data.products.map(elem => {
//             return (
//                 elem.variants
//             )
//         }).flat().filter(ellm => ellm.inventory_quantity > 0).map(elm => {
//             idArray.push(elm.inventory_item_id)
//             return (
//                 {sku: elm.sku, levelId: elm.inventory_item_id}
//             )
//         })

//         let segments = Math.ceil(idArray.length / 250)

//         for (let i = 0; i < segments; i++) {

//             const startIndex = i * 250;
//             const endIndex = Math.min(startIndex + 250, idArray.length);
//             const segment = idArray.slice(startIndex, endIndex);

        

//             axios.post("/api/shopify/getlevels", {
//                 levelIds: segment
//             })
//             .then((res) => {
//                 console.log(res.data)
//                 totalSegments.push(res.data.inventory_levels)

                

//                 // let findArray  = res.data.inventory_levels.map(elem => {
//                 //    let foundId = variantArray.find(elm => elm.levelId === elem.inventory_item_id)
            
                  
//                 //         return (
//                 //             {
//                 //                 sku: foundId.sku, 
//                 //                 stock: elem.available
//                 //             }
//                 //         )
                    
//                 // })
//                 console.log(totalSegments.flat())

                
//             })
//         }

            
//         // console.log(totalSegments)
//         console.log(variantArray)
        
//     })
// }

const getLevels = async () => {
    try {
      const res = await axios.post("/api/shopify/getallsingles", {});
  
      let idArray = [];
      let totalSegments = [];
      console.log(res.data.products)
      let variantArray = res.data.products
        .map(elem => elem.variants)
        .flat()
        .filter(ellm => ellm.inventory_quantity > 0)
        .map(elm => {
          idArray.push(elm.inventory_item_id);
          return { sku: elm.sku, levelId: elm.inventory_item_id, title: elm.title, product_id: elm.product_id };
        });

      

      let variantArrayForm = variantArray.map(elem => {
        let style = ""
        let cat = ""

        switch (elem.product_id) {
          case 6852663804004:
          case 6620534669412:
          case 6597571575908:
          case 4837716951140:
          case 6950074384484:
            style = "Crew Socks";
            cat = "Women's";
            break;
          case 6873293586532:
            style = "Compression Socks";
            cat = "Women's";
            break;
          case 6824475361380:
            style = "Golf Socks";
            cat = "Women's";
            break;
          case 6609129275492:
            style = "Light-Weight Crew Socks";
            cat = "Women's";
            break;
          case 6626100314212:
          case 6816252428388:
            style = "Low-Cut Socks";
            cat = "Women's";
            break;
          case 6541486489700:
            style = "No-Show Socks";
            cat = "Women's";
            break;
          case 6557907746916:
            style = "Performance Socks";
            cat = "Women's";
            break;
          case 7105370357860:
            style = "Slouchy Socks";
            cat = "Women's";
            break;
          case 7194189430884:
            style = "Hiking Crew Socks";
            cat = "Women's";
            break;
          case 7194189987940:
            style = "Hiking Quarter Socks";
            cat = "Women's";
            break;
          case 6888734425188:
          case 6888748580964:
          case 6888738160740:
          case 6888742060132:
          case 6888726921316:
          case 6888747335780:
            style = "Pacas Throw Blanket";
            break;
          case 2236767502436:
          case 6626100314212:
          case 6816252428388:
          case 6958959296612:
            style = "Low-Cut Socks";
            cat = "Women's";
            break;
          case 6956831965284:
          case 4837718753380:
            style = "Crew Socks";
            cat = "Men's";
            break;
          case 6878354341988:
            style = "Compression Socks";
            cat = "Men's";
            break;
          case 6602920394852:
            style = "Light-Weight Crew Socks";
            cat = "Men's";
            break;
          case 6824474771556:
            style = "Golf Socks";
            cat = "Men's";
            break;
          case 7194190217316:
            style = "Hiking Crew Socks";
            cat = "Men's";
            break;
          case 7194190512228:
            style = "Hiking Quarter Socks";
            cat = "Men's";
            break;
          case 4838251888740:
            style = "Crew Socks";
            cat = "Kids'";
            break;
          case 6873185353828:
          case 6862851080292:
          case 6873188270180:
          case 6873186828388:
          case 6873182437476:
            style = "Pacas Summer Wrap";
            break;
          case 6972996714596:
          case 6972996157540:
          case 6972996976740:
          case 6972996419684:
          case 6972997271652:
            style = "Pacas Shawl";
            break;
          case 4838368051300:
          case 4838370410596:
            style = "Toddler Pacas Socks";
            break;
          case 7000945000548:
          case 7000945590372:
          case 7000423956580:
            style = "Pacas Scarf";
            break;
          case 7082909958244:
          case 7082909139044:
            style = "Gift Box";
            break;
          case 4823046324324:
            style = "Low-Cut Socks";
            cat = "Men's";
            break;
          default:
            break;
        }

        return ({
          sku: elem.sku, levelId: elem.levelId, title: elem.title, product_id: elem.product_id, style: style, cat: cat
        })

      })

        console.log(res.data.products)
        console.log(variantArrayForm)
  
      let segments = Math.ceil(idArray.length / 250);
  
      for (let i = 0; i < segments; i++) {
        const startIndex = i * 250;
        const endIndex = Math.min(startIndex + 250, idArray.length);
        const segment = idArray.slice(startIndex, endIndex);
  
        const res = await axios.post("/api/shopify/getlevels", {
          levelIds: segment
        });
  
        totalSegments.push(res.data.inventory_levels);
      }


        let findArray  = totalSegments.flat().map(elem => {
            let foundId = variantArrayForm.find(elm => elm.levelId === elem.inventory_item_id)
    
            
                return (
                    {
                        title: foundId.title,
                        sku: foundId.sku, 
                        stock: elem.available,
                        product_id: foundId.product_id,
                        style: foundId.style,
                        cat: foundId.cat
                    }
                )
            
        })
  
    //   console.log(totalSegments.flat());
    let d = new Date()
        let dateForm = d.getDate().toString()
        let dateForm2 = (d.getMonth() + 1).toString()

    let fn = "levels" + "_" + dateForm2 + "_" + dateForm + ".xlsx"

      console.log(dateForm);
      console.log(dateForm2);
      // generateExcelFile(findArray, fn)
    } catch (error) {
      console.error(error);
    }
  };


  const generateExcelFile = async (input, fn) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet('All');
  


    const data = input

  
    // Add column headers
    const headers = Object.keys(data[0]);
    worksheet1.addRow(headers);

    // Add data rows
    data.forEach(item => {
      const values = Object.values(item);
      worksheet1.addRow(values);
    });

    const worksheet2 = workbook.addWorksheet('Low-Cut Socks');
  
    let lowcut = data.filter(elem => elem.style === "Low-Cut Socks")

    // Add column headers
    const headers2 = Object.keys(lowcut[0]);
    worksheet2.addRow(headers2);

    // Add data rows
    lowcut.forEach(item => {
      const values = Object.values(item);
      worksheet2.addRow(values);
    });

    const worksheet3 = workbook.addWorksheet('Crew Socks');
  
    let crew = data.filter(elem => elem.style === "Crew Socks")

    // Add column headers
    const headers3 = Object.keys(crew[0]);
    worksheet3.addRow(headers3);

    // Add data rows
    crew.forEach(item => {
      const values = Object.values(item);
      worksheet3.addRow(values);
    });


    const worksheet4 = workbook.addWorksheet('Light-Weight Crew Socks');
  
    let lwcrew = data.filter(elem => elem.style === "Light-Weight Crew Socks")

    // Add column headers
    const headers4 = Object.keys(lwcrew[0]);
    worksheet4.addRow(headers4);

    // Add data rows
    lwcrew.forEach(item => {
      const values = Object.values(item);
      worksheet4.addRow(values);
    });


    const worksheet5 = workbook.addWorksheet('Golf Socks');
  
    let golf = data.filter(elem => elem.style === "Golf Socks")

    // Add column headers
    const headers5 = Object.keys(golf[0]);
    worksheet5.addRow(headers5);

    // Add data rows
      golf.forEach(item => {
      const values = Object.values(item);
      worksheet5.addRow(values);
    });

    const worksheet6 = workbook.addWorksheet('Compression Socks');
  
    let compression = data.filter(elem => elem.style === "Compression Socks")

    // Add column headers
    const headers6 = Object.keys(compression[0]);
    worksheet6.addRow(headers6);

    // Add data rows
      compression.forEach(item => {
      const values = Object.values(item);
      worksheet6.addRow(values);
    });

    const worksheet7 = workbook.addWorksheet('No-Show Socks');
  
    let ns = data.filter(elem => elem.style === "No-Show Socks")

    // Add column headers
    const headers7 = Object.keys(ns[0]);
    worksheet7.addRow(headers7);

    // Add data rows
      ns.forEach(item => {
      const values = Object.values(item);
      worksheet7.addRow(values);
    });


    const worksheet8 = workbook.addWorksheet('Performance Socks');
  
    let p = data.filter(elem => elem.style === "Performance Socks")

    // Add column headers
    const headers8 = Object.keys(p[0]);
    worksheet8.addRow(headers8);

    // Add data rows
      p.forEach(item => {
      const values = Object.values(item);
      worksheet8.addRow(values);
    });

    const worksheet9 = workbook.addWorksheet('Hiking Crew Socks');
  
    let hc = data.filter(elem => elem.style === "Hiking Crew Socks")

    // Add column headers
    const headers9 = Object.keys(hc[0]);
    worksheet9.addRow(headers9);

    // Add data rows
      hc.forEach(item => {
      const values = Object.values(item);
      worksheet9.addRow(values);
    });

    const worksheet10 = workbook.addWorksheet('Hiking Quarter Socks');
  
    let hq = data.filter(elem => elem.style === "Hiking Quarter Socks")

    // Add column headers
    const headers10 = Object.keys(hq[0]);
    worksheet10.addRow(headers10);

    // Add data rows
      hq.forEach(item => {
      const values = Object.values(item);
      worksheet10.addRow(values);
    });


    const worksheet11 = workbook.addWorksheet('Slouchy Socks');
  
    let sl = data.filter(elem => elem.style === "Slouchy Socks")

    // Add column headers
    const headers11 = Object.keys(sl[0]);
    worksheet11.addRow(headers10);

    // Add data rows
      sl.forEach(item => {
      const values = Object.values(item);
      worksheet11.addRow(values);
    });



    




    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, fn);
    });
  };


export default function Inventory() {
    return (
        <div style={{ marginLeft: "200px" }}>
            <h1>Inventory</h1>
            <button onClick={getLevels}>Get Levels</button>
        </div>
    )
}