import React, { useState, useRef } from 'react';

import Labels from "./LabelsParent"
import IndLabels from './IndLabels';
import BulkLabels from './BulkLabels';
import BulkLabelsInd from './BulkLabelsInd';

import Button from '@mui/material/Button';


export default function LabelsParent() {
    const [load, setLoad] = useState("");

    const handleOne = () => {
        setLoad("1")
    }

    const handleTwo = () => {
        setLoad("2")
    }
    const handleThree = () => {
        setLoad("3")
    }

    const handleFour = () => {
        setLoad("4")
    }

    if (load === "") {


    let templateData = ["sku","upc","quantity"]

    const handleTemplate = () => {
        const csv = templateData.join(",");


        const blob = new Blob([csv], {type: "text/csv"})

        const url = URL.createObjectURL(blob)

        const a = document.createElement('a');

        a.href = url
        a.download = 'labelTemplate.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    }


    return (
        <div style={{marginLeft:"200px"}}>
            {/* <button onClick={handleOne}>BOX</button>
            <button onClick={handleTwo}>IND</button> */}
                        <div style={{display:"flex", marginLeft: "5vw"}}>
              <div style={{margin:"10vw"}}>
            <Button style={{height:"10vh", width:"10vw"}} component="label" variant="contained" onClick={handleThree}>Box Labels</Button>
            </div>
            <div style={{margin:"10vw"}}>
            <Button style={{height:"10vh", width:"10vw"}} component="label" variant="contained" onClick={handleFour}>Individual Labels</Button>
            </div>
            </div>

            <h3 style={{textDecoration:"underline", color:'#1c2fa6', cursor:"pointer", marginLeft:"30vw"}} onClick={handleTemplate}>Download Template</h3>

        </div>
        
    )


    }

    if (load === "1") {
        return (
            <Labels />
        )
    }

    if (load === "2") {
        return (
            <IndLabels />
        )
    }

    if (load === "3") {
        return (
            <BulkLabels handleOne={handleOne} />
        )
    }

    if (load === "4") {
        return (
            <BulkLabelsInd handleOne={handleOne} />
        )
    }


}