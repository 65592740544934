
import React, { useEffect, useState } from 'react';
import axios from "axios"

//Charts


  import { Bar } from '@visx/shape';
  import { AxisBottom, AxisLeft } from '@visx/axis';
  import { Group } from '@visx/group';
  import { scaleBand, scaleLinear } from '@visx/scale';








export default function YarnTabTwo(props) {
    const [yarnData, setYarnData] = useState([]);
    const [chartData, setChartData] = useState([]);





const width = 1000;
const height = 500;
const margin = { top: 40, bottom: 150, left: 40, right: 40 };
const xMax = width - margin.left - margin.right;
const yMax = height - margin.top - margin.bottom;


const xScale = scaleBand({
    range: [0, xMax],
    round: true,
    domain: chartData.map(d => d.colorCode),
    padding: 0.4,
  });
  
  const yScale = scaleLinear({
    range: [yMax, 0],
    round: true,
    domain: [0, Math.max(...chartData.map(d => d.totalWeightKg))],
  });


    const getAll = () => {
        axios.post("/api/yarn/getall", {

        })
        .then((res) => {
            console.log(res.data)
            setYarnData(res.data)
        })
    }

    const reducedData = Object.values(
        yarnData.reduce((acc, cur) => {
          if (acc[cur.colorCode]) {
            let buffer = Number(acc[cur.colorCode].totalWeightKg)
            buffer += Number(cur.netWeightKg);
            acc[cur.colorCode].totalWeightKg = buffer
          } else {
            acc[cur.colorCode] = { colorCode: cur.colorCode, totalWeightKg: cur.netWeightKg };
          }
          return acc;
        }, {})
      );

      //REFRACTOR
    const formattedData = reducedData.map(elem => {
        return (
            {
                colorCode: elem.colorCode,
                totalWeightKg: Number(elem.totalWeightKg.toFixed(2))
            }
        )
    })

    const log = () => {
        console.log(reducedData)
        console.log(formattedData)
       
    }

    useEffect(() => {
        setChartData(formattedData)
    }, [yarnData])


    return (
        <div >
           
            <button onClick={getAll}>GET</button>
            <button onClick={log}>LOG</button>
            <br></br>

                  
    <svg width={width} height={height}>
      <Group top={margin.top} left={margin.left}>
        {chartData.map((d, i) => (
          <Bar
            key={i}
            x={xScale(d.colorCode)}
            y={yScale(d.totalWeightKg)}
            height={yMax - yScale(d.totalWeightKg)}
            width={xScale.bandwidth()}
            fill="#fffff4"
          />
        ))}
                <AxisBottom
                top={yMax}
                scale={xScale}
                stroke="#fffff4"
                tickStroke="#fffff4"
                tickLabelProps={{
                    fill: "#fffff4",
                    fontSize: 11,
                    textAnchor: 'end',
                    angle: "-90"
                  }}
            
                />
        <AxisLeft
         scale={yScale}
         stroke="#fffff4"
         tickStroke="#fffff4"
         tickLabelProps={{
            fill: "#fffff4",
      
          }}

          />

      </Group>
    </svg>
   
        </div>
    )
}