import React, { useEffect, useState } from 'react';
import axios from "axios"

//mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

  };

export default function AnalyticsGrid( {handleKeyChange, dataKeys} ) {


    const [open, setOpen] = useState(false);

    const [state, setState] = useState({});

    
    

    const handleChange = (e) => {
        setState({
          ...state,
          [e.target.name]: e.target.checked,
        });
      };
    



    const handleOpen = () => {
        setState(array2)
        setOpen(true)
    }
    const handleClose = () => setOpen(false);

    const array1 = dataKeys

    const array2 = {"test": false, "h": false, "t": false}


    const output = () => {
        let test = Object.keys(state).map(elem => {
            if (state[elem] === true) {
                return (elem)
            }

        }).filter(elem => elem !== undefined)
        console.log(test)

        handleKeyChange(test)
    }


    


    let inputs = array1.map(elem => {
        return(
            <FormControlLabel
            control={
            <Checkbox checked={state.elem} onChange={handleChange} name={elem} />
            }
            label={elem}
        />

        )
    })






    return (
        <div>
        <Button onClick={handleOpen}>Select Filters</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{maxHeight: "80vh", overflow: "scroll"}}>
          <Box sx={{ display: 'flex' }}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormLabel component="legend">Assign responsibility</FormLabel>
                <FormGroup>
                    {inputs}
    
                </FormGroup>
                <button onClick={output}>LOG</button>
                <FormHelperText>Be careful</FormHelperText>
            </FormControl>
        
            </Box>
          </Box>
        </Modal>
      </div>
    )
}