import React from "react"
import CircularProgress from '@mui/material/CircularProgress';


const setTheme = () => {

    const root = document.documentElement;
    root.style.setProperty('--primary-color', '#363636');
    root.style.setProperty('--secondary-color', '#363636');
    root.style.setProperty('--content-color', '#363636');
    root.style.setProperty('--text-color', '#363636');

  }


export default function Loading() {
    setTheme()
    return (
        <div>
           {/* <CircularProgress color="inherit" size={24} /> */}
        </div>
    )
}