  // Import the functions you need from the SDKs you need
  import { initializeApp } from "firebase/app";
  import {getAuth} from "firebase/auth";
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries
  
  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyAlGzM5w5FmqbX9-Chunwjx3krv8_CFH34",
    authDomain: "pacascms.firebaseapp.com",
    projectId: "pacascms",
    storageBucket: "pacascms.appspot.com",
    messagingSenderId: "976497836330",
    appId: "1:976497836330:web:aa1abb3035ee3ec9455e53"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  export {auth}


