import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import axios from "axios"

//Components
import PhotoCreate from '../components/PhotoCreate';
import ModalTabs from "./ModalTabOne";
import ModalTabTwo from "./ModalTabTwo";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80vw",
    height: "95vh",
    bgcolor: 'background.paper',
    border: '2px solid black',
    boxShadow: 24,
    p: 4,
    color: "black"
  };


  const MyModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const TabPanel = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    minWidth: 1000,

    overflow: 'auto',
  }));




//
  
  export default function PackModal(props) {
    const [open, setOpen] = useState(false);
    const [photoData, setPhotoData] = useState([]);
    const [value, setValue] = useState(0);
    const [buttonData, setButtonData] = useState([]);
    const [batchData, setBatchData] = useState([]);


    //Buttons set Test
    // let buttonData = [
    //   {sku: "1006", primary: "#D27A68", secondary: "#764339"},
    //   {sku: "1008", primary: "#395976", secondary: "#71AEE6"},
    //   {sku: "1010", primary: "#71E683", secondary: "#1B5F25"},
    // ]

    for (let i = 0; i < props.selectedData.length; i++) {
      const sku = props.selectedData[i].sku;
      const button = buttonData.find(button => button.sku === sku);
      
      if (button) {
        props.selectedData[i].primary = button.primary;
        props.selectedData[i].primary2 = button.primary2 ?? "";
        props.selectedData[i].primary3 = button.primary3 ?? "";
        props.selectedData[i].secondary = button.secondary;
        props.selectedData[i].pattern = button.pattern ?? 1;
        props.selectedData[i].vector = button.vector ?? 100;

      } else {
        props.selectedData[i].primary = "#FFFFFF"
        props.selectedData[i].primary2 = "";
        props.selectedData[i].primary3 = "";
        props.selectedData[i].secondary = "#FFFFFF"
        props.selectedData[i].pattern =  1;
        props.selectedData[i].vector =  100;
      }
    }

    // const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
  
    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };



    useEffect(() => {
      setPhotoData(props.selectedData);
    }, [props.selectedData]);

    const handleOpen = () => {

      axios.post("/api/button/getall", {

      }).then((res) => {
        console.log(res)
        setButtonData(res.data)
        if (props.selectedData.length > 0 && props.style !== "All" && props.cat !== "All" && props.size !== "All"  || props.selectedData.length > 0 && props.cat === "Kids'"  ) {
          console.log(props.selectedData)
          setOpen(true)
          } else {
              alert("Please select category, style, and size to view")
          }
          })

          axios.post("./api/variant/getall", {
            
          }).then((res) => {
            console.log(res.data)

            let filteredData = res.data.filter((elem) => elem.confirmed === false)

            let batches = filteredData.map(elem => elem.batch);
            batches = [...new Set(batches)];

            setBatchData(batches)
          })
        
  
    }
    const handleClose = () => {
      // props.selectedData = props.selectedData
      setOpen(false)
 
    }

    function onDragStart(event, index) {
      event.dataTransfer.setData("index", index);
    }
  
    function onDragOver(event) {
      event.preventDefault();
    }
  
    function onDrop(event, newIndex) {
      const oldIndex = event.dataTransfer.getData("index");
      const newItems = [...photoData];
      const [removed] = newItems.splice(oldIndex, 1);
      newItems.splice(newIndex, 0, removed);
      setPhotoData(newItems)
    }
  
    return (
      <div>
        <Button
        style={{ border: "1px solid blue", padding: "10px", marginBottom: "5px", borderRadius: "8px", color:"black", backgroundColor:"white" } }
         onClick={handleOpen}>View 
        </Button>
        <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      disableEnforceFocus
      disableAutoFocus
      disableRestoreFocus
      BackdropProps={{ sx: { backdropFilter: 'blur(2px)' } }}
      >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, minWidth: "50vw", minHeight: "50vh", maxWidth: "90vw", maxHeight: "95vh" }}>
        {/* <Typography id="modal-title" variant="h4" component="h2" mb={2}>
          Modal Title
        </Typography> */}
        <div style={{display:"flex", maxHeight: "10px", margin:"0px", padding:"0px"}}>
      
        <Tabs style={{maxHeight: "10px", margin:"0px", padding:"0px"}} value={selectedTab} onChange={handleTabChange} aria-label="Modal Tabs">
          <Tab label="View" />
          <Tab label="Details" />
        </Tabs>
        <div  onClick={handleClose} style={{marginLeft: "70vw", cursor: "pointer", color: "red"}}>
         X
         </div>
        </div>
       
        <TabPanel>
          {selectedTab === 0 && (
            <div>
           <ModalTabs selectedData={props.selectedData} details={props.details} size={props.size} batchData={batchData} cat={props.cat} />
            </div>
          )}
          {selectedTab === 1 && (
            <div>
            <ModalTabTwo details={props.details} />
            </div>
          )}
        </TabPanel>
      </Box>
    </Modal>

      </div>
    );
  }