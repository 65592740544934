import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, FormGroup, Modal } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60vw",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ViewData({ data }) {
  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const filterData = () => {
      const newData = data.map(obj => {
        let filteredObj = {};
        Object.entries(obj).forEach(([key, value]) => {
          if (typeof value !== 'object') {
            filteredObj[key] = value;
          }
        });
        return filteredObj;
      });
      setFilteredData(newData);
    }
    filterData();
  }, [data]);

  return (
    <div>
      <Button onClick={handleOpen}>View Data</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ maxHeight: "80vh", overflow: "scroll" }}>
          <Box sx={{ display: 'flex' }}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormGroup>
                {filteredData.length > 0 && (
                  <table>
                    <tr key={"header"}>
                      {Object.keys(filteredData[0]).map((key) => (
                        <th>{key}</th>
                      ))}
                    </tr>
                    {filteredData.map((item) => (
                      <tr key={item.id}>
                        {Object.values(item).map((val) => (
                          <td>{val}</td>
                        ))}
                      </tr>
                    ))}
                  </table>
                )}
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
