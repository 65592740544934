import React, { useEffect, useState } from 'react';
import JSZip from 'jszip';
import jsPDF from 'jspdf';

export default function YarnTabOne({ inboundData, multiChecked }) {
  const [base64Images, setBase64Images] = useState([]);

  const handleImageLoad = async ({
    boxNumber: box,
    colorCode: color,
    unitType,
    unitQuantity,
    grossWeightKg,
    netWeightKg,
    yarnUnit,
    hex,
  }) => {
    const canvas = document.createElement('canvas');
    canvas.width = 600;
    canvas.height = 400;
    const ctx = canvas.getContext('2d');

    const data = hex;
    const img = new Image();
    img.crossOrigin = 'anonymous';

   

    await new Promise((resolve) => {
        img.onload = () => {
          if (multiChecked === true) {
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'black';

      // ctx.beginPath();
      // ctx.rect(10, 10, 165, 40);
      // ctx.fill();
      // ctx.stroke();

      ctx.fillStyle = 'white';

      ctx.font = 'bold 35px geneva';

      // ctx.fillText(box, 10, 40);
      ctx.fillStyle = 'black';
      var p = canvas.width / 2 - ctx.measureText('PACAS').width / 2;
      ctx.fillText('PACAS', p, 40);
      ctx.font = '30px geneva';
      var x = canvas.width / 2 - ctx.measureText(color).width / 2;
      ctx.fillText(color, x, 200);
      ctx.font = '20px geneva';
      ctx.fillText(`UNIT: ${unitType} (2/40)`, 10, 290);
      // ctx.fillText(`QUANTITY: ${unitQuantity}`, 10, 320);
      // ctx.fillText(`GROSS: ${grossWeightKg} KG`, 10, 350);
      ctx.fillText(`NET: 30 KG`, 10, 380);

      ctx.drawImage(img, 440, 240, 150, 150);
          } else {
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = 'black';
      
            ctx.beginPath();
            ctx.rect(10, 10, 165, 40);
            ctx.fill();
            ctx.stroke();
      
            ctx.fillStyle = 'white';
      
            ctx.font = 'bold 35px geneva';
      
            ctx.fillText(box, 10, 40);
            ctx.fillStyle = 'black';
            var p = canvas.width / 2 - ctx.measureText('PACAS').width / 2;
            ctx.fillText('PACAS', p, 40);
            ctx.font = '30px geneva';
            var x = canvas.width / 2 - ctx.measureText(color).width / 2;
            ctx.fillText(color, x, 200);
            ctx.font = '20px geneva';
            ctx.fillText(`UNIT: ${unitType} (${yarnUnit})`, 10, 290);
            ctx.fillText(`QUANTITY: ${unitQuantity}`, 10, 320);
            ctx.fillText(`GROSS: ${grossWeightKg} KG`, 10, 350);
            ctx.fillText(`NET: ${netWeightKg} KG`, 10, 380);
      
            ctx.drawImage(img, 440, 240, 150, 150);

          }
        

    //   ctx.fillStyle = hex;
    //   ctx.fillRect(540, 0, canvas.width, canvas.height);
      resolve();
        }
        img.src = data;
    });

    const dataURL = canvas.toDataURL('image/png');

    return dataURL;
  };

  const handleMultiple = async () => {
    const filteredArray = inboundData.reduce((uniqueArray, currentObj) => {
      const isDuplicate = uniqueArray.some(obj => obj.colorCode === currentObj.colorCode);
      if (!isDuplicate) {
        uniqueArray.push(currentObj);
      }
      return uniqueArray;
    }, []);

    let filterFinal = [] 
    if (multiChecked === true) {
      filterFinal = filteredArray
    } else {
      filterFinal = inboundData
    }
    const promises = filterFinal.map(handleImageLoad);
    const base64Array = await Promise.all(promises);
    setBase64Images(base64Array);
  };

  const handleDownload = async () => {
    const zip = new JSZip();
  
    for (let i = 0; i < base64Images.length; i++) {
      const image = new Image();
      image.src = base64Images[i];
      await new Promise(resolve => {
        image.onload = resolve;
      });
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0);
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
  
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: [3, 2],
      });
      pdf.addImage(imgData, 'JPEG', 0, 0, 3, 2);
      const pdfBlob = pdf.output('blob');
  
      zip.file(`${inboundData[i].boxNumber}.pdf`, pdfBlob);
    }
  
    zip.generateAsync({ type: 'blob' }).then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'images.zip';
      link.click();
    });
  };

  
  
    useEffect(() => {
      handleMultiple();
    }, [inboundData]);
  
    return (
      <div style={{ height: "100vh", overflowY: "scroll",marginBottom: "40px" } } className="pack-display">
          <button onClick={handleDownload}>Download</button>
          <br></br>
          {base64Images.map((base64Image, index) => (
          <img key={index} src={base64Image} style={{maxWidth: "600px", border: "2px solid black", marginLeft: "5vw", backgroundColor: "white", }} alt={`Image ${index+1}`} />
      ))}
  
      </div>
  )
  }