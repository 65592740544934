

// import React, { useEffect, useState } from 'react';

// export default function Catalog({ data }) {
//   const [base64Images, setBase64Images] = useState([]);


//   const handleMultiple = async () => {
//     console.log(data)
//     const promises = data.map(elem => handleImageLoad(elem.sku, elem.image, elem.color));
//     const base64Array = await Promise.all(promises);
//     setBase64Images(base64Array);
  
//   };

//   const handleDownload = () => {
//     downloadZip(base64Images);
//   }

//   const handleImageLoad = async (a,b,c) => {
//     const canvas = document.createElement('canvas');


//     canvas.width = 500;
//     canvas.height = 600;
//     const ctx = canvas.getContext('2d');

//     let x  = 50;
//     let y = -50;
//     let dx = 400;
//     let dy = 400;

//     const data = b;
//     const img = new Image();
//     img.crossOrigin = 'anonymous';

//     await new Promise(resolve => {
//       img.onload = () => {
//         ctx.save()
//         ctx.rotate(10*Math.PI/180)
//         ctx.drawImage(img, x, y, dx, dy);
//         ctx.restore()
//         ctx.font = "36px Font1";
//         let check = c.indexOf('/')
//         if (check !== -1) {
//         let top = c.slice(0, c.indexOf('/'))
//         let bottom = c.slice(c.indexOf('/') + 1)
//         ctx.fillText(top.toUpperCase().trim(), 50, 410);
//         ctx.fillText(bottom.toUpperCase().trim(), 50, 446);
//         } else {
//             ctx.fillText(c.toUpperCase().trim(), 50, 410);
//         }
//         ctx.font = "18px Font2";
//         let text1 = a + " - S/M & " + (parseInt(a) + 1).toString() + " M/L"
//         ctx.fillText(text1, 50, 500);
//         ctx.fillText("Wholesale Price: $7.50 USD", 50, 540);
//         ctx.fillText("Suggested MSRP: $15.00 USD", 50, 565);
//         resolve();
//       };
//       img.src = data;
//     });

//     const dataURL = canvas.toDataURL('image/png');
//     return dataURL;
//     //
   
//   };

//   const downloadZip = (base64Array) => {
//     const JSZip = require('jszip');
//     const zip = new JSZip();

//     // Add each base64 image to the zip archive
//     for (let i = 0; i < base64Array.length; i++) {
//       const base64Image = base64Array[i];
//       const imageBlob = fetch(base64Image).then(res => res.blob());
//       zip.file(`image-${i+1}.png`, imageBlob);
//     }

//     // Generate the zip archive and trigger download
//     zip.generateAsync({ type: 'blob' })
//       .then(blob => {
//         const link = document.createElement('a');
//         link.href = URL.createObjectURL(blob);
//         link.download = 'images.zip';
//         link.click();
//       });
//   };

//   useEffect(() => {
//     handleMultiple()
//   }, [data])

//   return (
//     <div style={{marginLeft: "200px"}}>
//     <button onClick={handleDownload}>Download</button>
//     <br></br>
//       {base64Images.map((base64Image, index) => (
//         <img key={index} src={base64Image} style={{maxWidth: "600px", border: "2px solid black", marginLeft: "5vw", backgroundColor: "white"}} alt={`Image ${index+1}`} />
//       ))}
     
//     </div>
//   );
// }

import React, { useEffect, useState } from 'react';

export default function Catalog({ data, cat, style }) {
  const [base64Images, setBase64Images] = useState([]);

  const handleMultiple = async () => {
    const promises = data.map(elem => handleImageLoad(elem.sku, elem.image, elem.color, elem.render));
    const base64Array = await Promise.all(promises);
    setBase64Images(base64Array);
  };

  const handleDownload = () => {
    downloadZip(base64Images);
  };

  const handleImageLoad = async (sku, image, color, render) => {
    const canvas = document.createElement('canvas');
    canvas.width = 990;
    canvas.height = 1620;
    const ctx = canvas.getContext('2d');

    let x  = 20;
    let y = 210;
    let dx = 925;
    let dy = 925;

   

    let rotation = 0
    if (style === "Low-Cut Socks") {
      rotation = 0 
      x = 10
      y = 320
      dx = 980;
      dy = 980;
  }

  if (style === "Kid Socks") {
    rotation = 0 
    x = 40
    y = 320
    dx = 800;
    dy = 800;
}
  if (style === "Slouchy Socks") {
    rotation = 0 
    x = -20
    y = 110
    dx = 980;
    dy = 980;
}
  if (style === "Performance Socks") {
    rotation = 0 
    x = 10
    y = 320
    dx = 980;
    dy = 980;
}
        if (style === "No-Show Socks") {
            rotation = 0 
            x = 100
            dx = 800;
            dy = 800;
            y = 350
        }
        if (style === "Compression Socks") {
          rotation = 0 
          y = 70
          dx = 1050;
          dy = 1050;
          x = -30
        
      }
        if (render === "true") {
          rotation = 0 
          x = 28
      }
      if (style === "Hiking Socks") {
        rotation = 0 
        x = -50
        dx = 1030;
         dy = 1030;
         y = 110
    }

    const data = image;
    const img = new Image();
    img.crossOrigin = 'anonymous';

    await new Promise(resolve => {
      img.onload = () => {
        ctx.save()
        ctx.rotate(rotation*Math.PI/180)
        ctx.drawImage(img, x, y, dx, dy);
        ctx.restore()
        ctx.letterSpacing = "2.7px"
        ctx.fillStyle = "#2d2a26" 
        ctx.font = "78px Font4";
        let check = color.indexOf('/')
        if (check !== -1) {
          let top = color.slice(0, color.indexOf('/'))
          let bottom = color.slice(color.indexOf('/') + 1)
          ctx.fillText(top.toUpperCase().trim(), 154, 1210);
          ctx.fillText(bottom.toUpperCase().trim(), 154, 1295);
        } else {
          ctx.fillText(color.toUpperCase().trim(), 154, 1210);
        }
        ctx.letterSpacing = "0px"
        ctx.font = "52.5px Font3";
        if (style !== "Kid Socks") {
        let text1 = "S/M - " + sku
        let text2 = "M/L - " + (parseInt(sku) + 1).toString() 
        ctx.fillText(text1, 155, 1422);
        ctx.fillText(text2, 155, 1500);
        } else {
          let text1 =  sku
          ctx.fillText(text1, 155, 1422);
        }
        // ctx.fillText("Wholesale Price:", 120, 1440);
        // ctx.fillText("$7.50 USD", 600, 1440);
        // ctx.fillText("Suggested MSRP:", 120, 1500);
        // ctx.fillText("$15.00 USD", 600, 1500);
        resolve();
      };
      img.src = data;
    });

    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  };

  const downloadZip = (base64Array) => {
    const JSZip = require('jszip');
    const zip = new JSZip();

    // Add each base64 image to the zip archive
    for (let i = 0; i < base64Array.length; i++) {
      const base64Image = base64Array[i];
      const imageBlob = fetch(base64Image).then(res => res.blob());
      const sku = data[i].sku;
      zip.file(`${sku}.png`, imageBlob);
    }

    // Generate the zip archive and trigger download
    zip.generateAsync({ type: 'blob' })
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'images.zip';
        link.click();
      });
  };

  useEffect(() => {
    handleMultiple()
  }, [data])
    return (
    <div style={{marginLeft: "200px"}}>
    <button onClick={handleDownload}>Download</button>
    <br></br>
      {base64Images.map((base64Image, index) => (
        <img key={index} src={base64Image} style={{maxWidth: "600px", border: "2px solid black", marginLeft: "5vw", backgroundColor: "white", }} alt={`Image ${index+1}`} />
      ))}
     
    </div>
  );
}

