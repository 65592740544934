
import React from "react"



export default function ModalTabTwo(props) {

    const test = () => {
        console.log(props)
    }
    return (
        <div style={{ marginLeft: "200px" }}>
            <h1>Details</h1>
            <div style={{display: "flex"}}>
                <div style={{width: "50%"}}>
                    <h2>Variant One</h2>
                    <h4>SKU: {props.details.sku1}</h4>
                    <h4>COLOR: {props.details.color}</h4>
                    <h4>WEIGHT: {props.details.weight1}</h4>
                    <h4>PRICE: {props.details.price}</h4>
                </div>
                <div style={{width: "50%"}}>
                    <h2>Variant Two</h2>
                    <h4>SKU: {props.details.sku2}</h4>
                    <h4>COLOR: {props.details.color}</h4>
                    <h4>WEIGHT: {props.details.weight2}</h4>
                    <h4>PRICE: {props.details.price}</h4>

                </div>
            </div>
        </div>
    )
}