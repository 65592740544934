import React, { useEffect, useState } from 'react';

import { useAuthStatus } from '../hooks/authStatus';

import Loading from './Loading';
import UnAuthorised from './Unauthorised';

const ProtectedRoute = ({ component: Component }) => {
  // a custom hook to keep track of user's auth status
  const { loggedIn, checkingStatus } = useAuthStatus();



  return (
    <>
      {
      
        checkingStatus
          ? <Loading />
          : loggedIn
            ? <Component />
            : <UnAuthorised />
      }
    </>
  );
};

export default ProtectedRoute;