import React, { useEffect, useState, useRef } from 'react';
import axios from "axios"

//ASSEST
import loading from "../assets/loadingNew.gif"

//COMPONENTS
import PackSingleDisplay from '../components/PackSingleDisplay';
import ModalData from '../components/ModalData';

//MUI
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';

//OTHER
import CsvDownloader from "react-csv-downloader"
import Papa from "papaparse"
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';



export default function PackDisplay( {variantData, changeView, skuChecked, view} ) {

    const [cat, setCat] = useState("Women's")
    const [style, setStyle] = useState("Crew Socks")
    const [size, setSize] = useState("Size 1")
    const [selectedData, setSelectedData] = useState([])
    const [css, setCss] = useState("#ededf2")
    const [stockChecked, setStockChecked] = useState(false);
    const [customFilter, setCustomFilter] = useState(false);
    const [base64Array, setBase64Array] = useState([]);

    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
      };


    //REF
    // const ref = useRef(null);
    //Switch functions
    const handleStockSwitch = (event) => {
        setStockChecked(event.target.checked);
      };

    //Selection CallBacks

    const selectHandle = (a,b,c,d) => {
        a.forEach(elem => {
            setSelectedData(selectedData => [...selectedData, elem])
        })
        

    }

   const deselectHandle = (a) => {
    let removed = selectedData.filter((elm) => {return elm.parentSku !== a})
        setSelectedData(removed)
        
    }

    //Clear: TODO: There has to be a better way to do this but it does work, Note: This is working by forcing a re-render as "" and white appear the same
    const clearData = () => {
        if (css === "#ededf2") {
            setCss("#ededf3")
            } else {
            setCss("#ededf2")
            }
   
        setSelectedData([])
        console.log(css)
    }



    //Select Functions
    const handleCat = (event) => {
        setCat(event.target.value);
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        
          })
      };
    const handleStyle = (event) => {
    setStyle(event.target.value);
    window.scrollTo({
        top: 0, 
        behavior: 'smooth'
    
      })
    };
    const handleSize = (event) => {
        setSize(event.target.value);
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        
          })
      };
    //

    let dataSelect1 = []
    let dataSelect2 = []
    let dataSelect3 = []
  

    if (cat!== "All") {
         dataSelect1 = variantData.filter(elm => elm.cat === cat)
    } else {
          dataSelect1 = variantData
    }

    if (style === "Combo") {
      dataSelect2 = dataSelect1.filter(elm => elm.style === "Light-Weight Crew Socks" || elm.style === "No-Show Socks" || elm.style === "Crew Socks" )
  }



     else if (style !== "All") {
         dataSelect2 = dataSelect1.filter(elm => elm.style === style)
    } else {
         dataSelect2 = dataSelect1
    }

    if (size === "Size 1") {
         dataSelect3 = dataSelect2.filter((elm) => (elm.sku[1] === "-") ? elm.sku.substring(2,6) % 2 === 0 : elm.sku.substring(0,4) % 2 === 0)
    }
    else if (size === "Size 2") {
         dataSelect3 = dataSelect2.filter((elm) => (elm.sku[1] === "-") ? elm.sku.substring(2,6) % 2 === 1 : elm.sku.substring(0,4) % 2 === 1)
    }
    else {
        dataSelect3 = dataSelect2
    }

    let dataFinal = []

    if (stockChecked === true) {

     dataFinal = dataSelect3.sort((a,b) => b.stock - a.stock).filter(elm => elm.stock > 0)
    } else {
     dataFinal = dataSelect3.sort((a,b) => a.sku - b.sku)
    }

    if (customFilter.length > 0) {

    dataFinal = dataFinal.filter(elem => customFilter.map(ellm => ellm.sku).includes(elem.sku))
    
    }
   





    let singleDisplay = dataFinal.map(elem => {
        return (
            <Grid item md={2.4} key={elem.id}>
                <PackSingleDisplay image={elem.image} sku={elem.sku} color={elem.color} size={elem.size} productId={elem.productId} abv={elem.abv} apv={elem.apv} stock={elem.stock} style={elem.style} css={css} selectHandle={selectHandle} deselectHandle={deselectHandle} children={elem.children} />
            </Grid>
        )
    })

    //NEW
    const handleFileUpload = (e) => {
        const files = e.target.files;
        console.log(files);
        if (files) {
          console.log(files[0]);
          Papa.parse(files[0], {
            header: true,
            complete: function(results) {
              console.log("Finished:", results.data);
              let formArray = results.data.map(elem => {
                return (
                    {sku: elem.sku}
                )
              })
              console.log(formArray)
              setCustomFilter(formArray)
            }}
          )
        }
    
    }

    //EXCEL


    const generateExcelFile = async (images) => {
        console.log("Fire")
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');

      const data = dataFinal.map(({ children, ...rest }) => rest);

  
      // Add column headers
      const headers = Object.keys(data[0]);
      worksheet.addRow(headers);
  
      // Add data rows
      data.forEach(item => {
        const values = Object.values(item);
        worksheet.addRow(values);
      });

      const rowCount = dataFinal.length + 1
  for (let i = 1; i <= rowCount; i++) {
    const row = worksheet.getRow(i);
    row.height = 100;
  }

  // Set column width
  const columnCount = 50
  console.log(columnCount)
  for (let i = 1; i <= columnCount; i++) {
    const column = worksheet.getColumn(i);
    column.width = 25;
  }
    

      for (let i = 0; i < images.length; i++) {
     
        
  
        const imageId = workbook.addImage({
          base64: images[i],
          extension: 'png',
        });

        const imageOptions = {
            tl: { col: 0, row: i + 1 }, 
            ext: { width: 180, height: 180 } 
          };
    
        worksheet.addImage(imageId, imageOptions);
    }
  
  
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'images.xlsx');
      });
    };

    //CANVAS

    const handleMultiple = async () => {
        const promises = dataFinal.map(elem => handleImageLoad(elem.image));
        const base64Images = await Promise.all(promises);
        console.log("PRE")

    
        generateExcelFile(base64Images)
      };

      const handleImageLoad = async (image) => {
        console.log("test")
        const canvas = document.createElement('canvas');
        canvas.width = 100;
        canvas.height = 100;
        const ctx = canvas.getContext('2d');
  
    
        const data = image;
        const img = new Image();
        img.crossOrigin = 'anonymous';
    
        await new Promise(resolve => {
          img.onload = () => {
            console.log("test2")
      
            ctx.drawImage(img, 0, 0, 100, 100);

            resolve();
          };
          img.src = data;
        });
    
        const dataURL = canvas.toDataURL('image/png');
        return dataURL;
      };




    if (variantData.length > 0) {
        return(
            <div>
                <div style={{  padding: "10px", marginBottom: "5px", marginLeft:"0vw", borderBottom: "3px solid #3838a6", borderLeft: "1px solid #3838a6",borderTop: "1px solid #3838a6", borderRight: "1px solid #3838a6", marginRight: "3vw", borderRadius:"15px" } } >
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                >
                            <Button
                            style={{ border: "1px solid white", padding: "10px", marginBottom: "5px", borderRadius: "8px", color:"white" } }
                            onClick={changeView}>Back
                            </Button>
                            <Button
                            style={{ border: "1px solid white", padding: "10px", marginBottom: "5px", borderRadius: "8px", color:"white" } }
                            onClick={clearData}>Clear
                            </Button>
                    {/* <button onClick={clearData}>CLEAR</button> */}
            <Box sx={{ minWidth: 150, maxWidth: 150 }}>
                    <FormControl fullWidth>
                        <InputLabel sx={{  color: "white" }} id="demo-simple-select-label">Category</InputLabel>
                        <Select
                        sx={{  color: "white",
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '.MuiSvgIcon-root': {
                            color: 'white',
                          },
                         }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={cat}
                        label="Category"
                        onChange={handleCat}
                        >
                        <MenuItem value={"All"}>All</MenuItem>
                        <MenuItem value={"Women's"}>Women's</MenuItem>
                        <MenuItem value={"Men's"}>Men's</MenuItem>
                        <MenuItem value={"Kids'"}>Kids'</MenuItem>
                        </Select>
                    </FormControl>
             </Box>
             <Box sx={{ minWidth: 150, maxWidth: 150 }}>
                    <FormControl  fullWidth>
                        <InputLabel sx={{  color: "white" }} id="demo-simple-select-label">Style</InputLabel>
                        <Select
                        sx={{  color: "white",
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '.MuiSvgIcon-root': {
                            color: 'white',
                          },
                         }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={style}
                        label="Style"
                        onChange={handleStyle}
                        >
                        <MenuItem value={"All"}>All</MenuItem>
                        <MenuItem value={"Crew Socks"}>Crew Socks</MenuItem>
                        <MenuItem value={"Light-Weight Crew Socks"}>Light-Weight Crew Socks</MenuItem>
                        <MenuItem value={"Compression Socks"}>Compression Socks</MenuItem>
                        <MenuItem value={"Low-Cut Socks"}>Low-Cut Socks</MenuItem>
                        <MenuItem value={"No-Show Socks"}>No-Show Socks</MenuItem>
                        <MenuItem value={"Golf Socks"}>Golf Socks</MenuItem>
                        <MenuItem value={"Performance Socks"}>Performance Socks</MenuItem>
                        <MenuItem value={"Slouchy Socks"}>Slouchy Socks</MenuItem>
                        <MenuItem value={"Hiking Crew Socks"}>Hiking Crew Socks</MenuItem>
                        <MenuItem value={"Hiking Quarter Socks"}>Hiking Quarter Socks</MenuItem>
                        <MenuItem value={"Combo"}>Combo</MenuItem>
                        </Select>
                    </FormControl>
             </Box>
             <Box sx={{ minWidth: 150, maxWidth: 150 }}>
                    <FormControl   fullWidth>
                        <InputLabel sx={{  color: "white",
                           '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '.MuiSvgIcon-root': {
                            color: 'white',
                          },
                         }} id="demo-simple-select-label">Size</InputLabel>
                        <Select
                        sx={{  color: "white",
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '.MuiSvgIcon-root': {
                            color: 'white',
                          },
                         }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={size}
                        label="Size"
                        onChange={handleSize}
                        >
                        <MenuItem value={"All"}>All</MenuItem>
                        <MenuItem value={"Size 1"}>Size 1</MenuItem>
                        <MenuItem value={"Size 2"}>Size 2</MenuItem>
                
                        </Select>
                    </FormControl>
             </Box>
             <FormGroup>
            <FormControlLabel
             sx={{  color: "white" }}
            label="In Stock Only"
             control={<Switch
                checked={stockChecked}
                onChange={handleStockSwitch}
                inputProps={{ 'aria-label': 'controlled' }}
                labelPlacement="end"
                />}
            />
            </FormGroup>

             <Button  onClick={handleClick} style={{ border: "1px solid white", padding: "10px", marginBottom: "5px", borderRadius: "8px", color:"white" } }>Filter By File   </Button>
             <input  ref={hiddenFileInput} onChange={handleFileUpload} type="file" hidden/>
                
          
     

                <Button onClick={handleMultiple} style={{ border: "1px solid white", padding: "10px", marginBottom: "5px", borderRadius: "8px", color:"white" } }>EXPORT</Button>

                <ModalData selectedData={selectedData} style={style} cat={cat} size={size} skuChecked={skuChecked} view={view} />


             </Stack>
             </div>
            <div style={{ height: "70vh", overflowY: "scroll", marginLeft: "0vw", marginRight:"3vw",  } } className="pack-display">
                <Grid container spacing={2}>
                {singleDisplay}
                </Grid>
            </div>
            
            </div>
        )
    } else {
        return (
            <div style={{marginLeft: "30vw", marginTop: "22vh"}}>
            <img src={loading} />
            </div>
        )
    }

}