import React, { useEffect, useState } from 'react';

import Papa from "papaparse"

import axios from "axios"

import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import Modal from '@mui/material/Modal';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95vw",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



export default function UPCCreateModal() {
    const [open, setOpen] = useState(false);
    const [uploadData, setUploadData] = useState(false);


    const handleOpen = () => {

        setOpen(true);
       
    };

    const handleClose = () => setOpen(false);


    const handleFileUpload = (e) => {
        let currentTime = new Date().toLocaleString()
        console.log(currentTime)
        const files = e.target.files;
        console.log(files);
        if (files) {
          console.log(files[0]);
          Papa.parse(files[0], {
            header: true,
            complete: function(results) {
              console.log("Finished:", results.data);
              let formArray = results.data.map(elem => {

      
           
      
                return (
                  {
                    sku: elem.sku,

                  }
                )
                
          
              })
              console.log(formArray)

              setUploadData(formArray)
    
           
            }}
          )
        }
      
      }



const handleAssignment = async () => {

let count = uploadData.length

    let response = await axios.post("/api/upc/getunassigned", {
        count: count
    })

    console.log(response)


    let combinedData = uploadData.map((elem, i) => {
        return (
            {
                upc: response.data[i].upc,
                sku: elem.sku,
                user: "Jacob@pacas.com"

            }
        )
    })


    console.log(combinedData)

    let response2 = await axios.post("/api/upc/getassigned")

    console.log(response2.data)

    let foundSkus = response2.data.map((elem) => {
        return (
            elem.sku
        )
    })

    let inputSkus = uploadData.map((elem) => {
        return (
            elem.sku
        )
    })

    let filterArray = inputSkus.filter((elem) => foundSkus.includes(elem))


    console.log(filterArray.length)

    if (filterArray.length === 0) {

        for (const elem of combinedData) {
            let response = await axios.post("/api/upc/assign", {
                upc: elem.upc,
                sku: elem.sku,
                user: elem.user
            })

            console.log(response)
        }


    }



}





      return (
        <div style={{ marginLeft: "17vw" }}>
            <button onClick={handleOpen}>Assignment</button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

            <Box sx={style}>

            <input onChange={handleFileUpload} type="file" accept=".csv"></input>
            <button onClick={handleAssignment}>ASSIGN</button>
              
            </Box>
                </Modal>

        </div>
    )




}