
import React, { useState, useRef } from 'react';
import axios from "axios"


import jsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';


const canvas = document.createElement('canvas');

canvas.width = 300;
canvas.height = 200;

const ctx = canvas.getContext('2d')

ctx.fillStyle = "white";
ctx.fillRect(0, 0, canvas.width, canvas.height);













export default function IndLabels() {

    const [image, setImage] = useState("<img></img>");
    const [sku, setSku] = useState("");
    const [upc, setUpc] = useState("");



    // let sku = "1-3333X1-4444X1-6666X1-9999X1-2222X1-22222"
    let weight = "25 KG"
    // let upc = "810118930423"


    const handleSkuChange = (e) => {
        setSku(e.target.value)
    }

    const handleUpcChange = (e) => {
        setUpc(e.target.value)
    }



    const createBarcode = (upc) => {
        const canvas = document.createElement('canvas');
        canvas.width = 300;
        canvas.height = 150;
        console.log("TEST")
      
        JsBarcode(canvas, upc, {format: "UPC"});
        return canvas.toDataURL("image/png");
      }

    const exportPDF = () => {
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'in',
            format: [3, 2],
          });
          pdf.addImage(canvas.toDataURL(), 'JPEG', 0, 0, 1.5, 1);
          pdf.save("INDIVIDUAL_" + sku + ".pdf")

    }

    const generate = () => {

        let barImg = new Image()
        barImg.onload = () => {

            ctx.drawImage(barImg, 0, 0, 300, 200)

            ctx.font = "20px Helevetica";
            ctx.fillStyle = "black";

      

            //SKU
            // ctx.fillText("SKU: ",40,100)
            // ctx.fillText(sku,100,100)

     

       

 

            // //UPC
            // ctx.fillText("UPC#: ",300, 140)
            // ctx.fillText(upc,400, 140)


            ctx.lineWidth = 2.5;

            // //Borders
            // ctx.beginPath(); // Start a new path
            // ctx.moveTo(35, 50); // Move the pen to (30, 50)
            // ctx.lineTo(550, 50); // Draw a line to (150, 100)
            // ctx.stroke(); // Render the path
            // //
            // //Borders
            // ctx.beginPath(); // Start a new path
            // ctx.moveTo(35, 120); // Move the pen to (30, 50)
            // ctx.lineTo(550, 120); // Draw a line to (150, 100)
            // ctx.stroke(); // Render the path
            // //
            // //Borders
            // ctx.beginPath(); // Start a new path
            // ctx.moveTo(35, 150); // Move the pen to (30, 50)
            // ctx.lineTo(550, 150); // Draw a line to (150, 100)
            // ctx.stroke(); // Render the path
            // //
            // //Borders
            // ctx.beginPath(); // Start a new path
            // ctx.moveTo(35, 340); // Move the pen to (30, 50)
            // ctx.lineTo(550, 340); // Draw a line to (150, 100)
            // ctx.stroke(); // Render the path
            // //
            // //Borders
            // ctx.beginPath(); // Start a new path
            // ctx.moveTo(35, 210); // Move the pen to (30, 50)
            // ctx.lineTo(280, 210); // Draw a line to (150, 100)
            // ctx.stroke(); // Render the path
            // //
            // //Borders
            // ctx.beginPath(); // Start a new path
            // ctx.moveTo(35, 260); // Move the pen to (30, 50)
            // ctx.lineTo(280, 260); // Draw a line to (150, 100)
            // ctx.stroke(); // Render the path
            // //

            // //Borders VERT 1
            // ctx.beginPath(); // Start a new path
            // ctx.moveTo(35, 50); // Move the pen to (30, 50)
            // ctx.lineTo(35, 340); // Draw a line to (150, 100)
            // ctx.stroke(); // Render the path
            // //
            // //Borders VERT 2
            // ctx.beginPath(); // Start a new path
            // ctx.moveTo(90, 50); // Move the pen to (30, 50)
            // ctx.lineTo(90, 340); // Draw a line to (150, 100)
            // ctx.stroke(); // Render the path
            // //
            // //Borders VERT 3
            // ctx.beginPath(); // Start a new path
            // ctx.moveTo(280, 120); // Move the pen to (30, 50)
            // ctx.lineTo(280, 340); // Draw a line to (150, 100)
            // ctx.stroke(); // Render the path
            // //
            // //Borders VERT 4
            // ctx.beginPath(); // Start a new path
            // ctx.moveTo(550, 50); // Move the pen to (30, 50)
            // ctx.lineTo(550, 340); // Draw a line to (150, 100)
            // ctx.stroke(); // Render the path
            // //
        
        

            setImage(canvas.toDataURL('image/png'))
        

        }
        barImg.src = createBarcode(upc)



    }




    return (
        <div style={{ marginLeft: "200px" }}>
         <input onChange={handleSkuChange}></input>
         <input onChange={handleUpcChange}></input>

            <button onClick={generate}>GEN</button>
            <button onClick={exportPDF}>EXPORT</button>
            <br></br>
           <img src= {canvas.toDataURL()} />
       
        </div>
    )
}