import React, { useEffect, useState } from 'react';
import axios from "axios"


//MUI
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';

//COMPONENTS
import KRISingleDisplay from '../components/KRISingleDisplay';
import KRIModal from '../subViews/KRIModal';








export default function KRIDisplay( { data } ) {

    const [selectedData, setSelectedData] = useState([])

    const [cat, setCat] = useState("All")
    const [style, setStyle] = useState("All")
    const [size, setSize] = useState("All")


    const [stockChecked, setStockChecked] = useState(false);



        //
        let dataSelect1 = []
        let dataSelect2 = []
        let dataSelect3 = []
      
    
        if (cat!== "All") {
             dataSelect1 = data.filter(elm => elm.cat === cat)
        } else {
              dataSelect1 = data
        }
    
        if (style !== "All") {
             dataSelect2 = dataSelect1.filter(elm => elm.style === style)
        } else {
             dataSelect2 = dataSelect1
        }

        if (size !== "All") {
            dataSelect3 = dataSelect2.filter(elm => elm.size === size)
       } else {
            dataSelect3 = dataSelect2
       }



    
  
    
        let dataFinal = []
    
        if (stockChecked === true) {
    
         dataFinal = dataSelect3.sort((a,b) => b.quantity - a.quantity).filter(elm => elm.quantity > 0)
        } else {
         dataFinal = dataSelect3.sort((a,b) => a.sku - b.sku)
        }


    const addData = (data) => {
        setSelectedData([...selectedData, data])
    }

    const removeData = (dataToRemove) => {
        setSelectedData(selectedData.filter(data => data.sku !== dataToRemove.sku));
    }

        //Switch functions
        const handleStockSwitch = (event) => {
            setStockChecked(event.target.checked);
          };
    


        //Select Functions
        const handleCat = (event) => {
            setCat(event.target.value);
            window.scrollTo({
                top: 0, 
                behavior: 'smooth'
            
              })
          };
        const handleStyle = (event) => {
        setStyle(event.target.value);
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        
          })
        };
        const handleSize = (event) => {
            setSize(event.target.value);
            window.scrollTo({
                top: 0, 
                behavior: 'smooth'
            
              })
          };
        //


   

   
    let singleDisplay = dataFinal.map(elem => {
        return (
            <Grid item md={2.4} key={elem.id}>
                <KRISingleDisplay key={elem} elem={elem} addData={addData} removeData={removeData}    />
            </Grid>
        )
    })


    const logData = () => {
        console.log(dataFinal)
    }


    

    


    return (
       <>

            <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
            >
            <Box sx={{ minWidth: 150, maxWidth: 150 }}>
            <FormControl fullWidth>
                <InputLabel sx={{  color: "white" }} id="demo-simple-select-label">Category</InputLabel>
                <Select
                sx={{  color: "white" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cat}
                label="Category"
                onChange={handleCat}
                >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"Women's"}>Women's</MenuItem>
                <MenuItem value={"Men's"}>Men's</MenuItem>
                <MenuItem value={"Kids'"}>Kids'</MenuItem>
                </Select>
            </FormControl>
         </Box>

         <Box sx={{ minWidth: 150, maxWidth: 150 }}>
            <FormControl fullWidth>
                <InputLabel sx={{  color: "white" }} id="demo-simple-select-label">Style</InputLabel>
                <Select
                sx={{  color: "white" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={style}
                label="Category"
                onChange={handleStyle}
                >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"Crew Socks"}>Crew Socks</MenuItem>
                <MenuItem value={"Compression Socks"}>Compression Socks</MenuItem>
                <MenuItem value={"Golf Socks"}>Golf Socks</MenuItem>
                <MenuItem value={"Light-Weight Crew Socks"}>Light-Weight Crew Socks</MenuItem>
                <MenuItem value={"Low-Cut Socks"}>Low-Cut Socks</MenuItem>
                <MenuItem value={"No-Show Socks"}>No-Show Socks</MenuItem>
                <MenuItem value={"Performance Socks"}>Performance Socks</MenuItem>
                <MenuItem value={"Slouchy Socks"}>Slouchy Socks</MenuItem>
                <MenuItem value={"Hiking Crew Socks"}>Hiking Crew Socks</MenuItem>
                <MenuItem value={"Hiking Quarter Socks"}>Hiking Quarter Socks</MenuItem>
                <MenuItem value={"Performance Socks"}>Performance Socks</MenuItem>
                <MenuItem value={"Kids'"}>Kids'</MenuItem>
                <MenuItem value={"Sweater"}>Sweater</MenuItem>
                <MenuItem value={"Accessory"}>Accessory</MenuItem>
                </Select>
            </FormControl>
         </Box>

         <Box sx={{ minWidth: 150, maxWidth: 150 }}>
            <FormControl fullWidth>
                <InputLabel sx={{  color: "white" }} id="demo-simple-select-label">Size</InputLabel>
                <Select
                sx={{  color: "white" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={size}
                label="Category"
                onChange={handleSize}
                >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"Size 1"}>Size 1</MenuItem>
                <MenuItem value={"Size 2"}>Size 2</MenuItem>
                <MenuItem value={"One Size"}>One Size</MenuItem>
                </Select>
            </FormControl>
         </Box>

         <FormGroup>
            <FormControlLabel
             sx={{  color: "white" }}
            label="Sort by Quantity"
             control={<Switch
                checked={stockChecked}
                onChange={handleStockSwitch}
                inputProps={{ 'aria-label': 'controlled' }}
                labelPlacement="end"
      
                />}
            />
            </FormGroup>

         
     
       {/* <KRIModal data={selectedData} /> */}
       </Stack>

        <div style={{ height: "90vh", overflowY: "scroll", marginLeft: "0vw", marginRight:"3vw" } } className="pack-display">
            {/* <button onClick={logData}>Log</button> */}
 
                 <Grid container spacing={2}>
                {singleDisplay}
                </Grid>
      
        </div>

        </>
       
    )



}