
import React, { useEffect, useState } from 'react';
import axios from "axios"
import { Box, Button, TextField, List, ListItem, Typography, Paper } from '@mui/material';




export default function Analytics() {
    const [input, setInput] = useState("");
    const [history, setHistory] = useState([]);



    const handleSubmit = () => {
        const newHistory = [...history, input];
        setHistory(newHistory);
        setInput("");
        axios.post("/api/gpt", {
            query: input
        })
        .then((res) => {
            if (res.data.choices) {
                setHistory(prevHistory => [...prevHistory, res.data.choices[0].message.content]);
            }
        })
    }


    return (
        <div style={{ marginLeft: "200px" }}>
         


            <Box 
      sx={{
        height: '50vh', 
        width: '40vw', 
        backgroundColor: "white",
        border: '1px solid black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 2
      }}
    >
      <List sx={{overflow: 'auto', flexGrow: 1}}>
        {history.map((message, index) => (
          <ListItem key={index}>
            <Typography>{message}</Typography>
          </ListItem>
        ))}
      </List>
      <Box 
        component="form" 
        onSubmit={(e) => { 
          e.preventDefault();
          handleSubmit(); 
        }} 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between' 
        }}
      >
        <TextField 
          variant="outlined" 
          value={input} 
          onChange={(e) => setInput(e.target.value)} 
          fullWidth 
        />
        <Button 
          variant="contained" 
          onClick={handleSubmit} 
          sx={{marginLeft: 2}}
        >
          Send
        </Button>
      </Box>

    </Box>
         
        
        </div>
    )
}