import React, { useRef, useEffect, useState } from 'react';
import desktemp from "../assets/mobtemp.png";

function PreviewMobile({ photo, button, dataSend }) {
  const canvasRef = useRef(null);
  const [b64String, setb64String] = useState("");

  const genImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = 389;
    canvas.height = 754;

    // Load desktemp first
    const imgDeskTemp = new Image();
    imgDeskTemp.crossOrigin = 'anonymous';
    imgDeskTemp.src = desktemp;

    imgDeskTemp.onload = () => {
      // Draw desktemp
      ctx.drawImage(imgDeskTemp, 0, 0, canvas.width, canvas.height);

      // Load and draw photo
      const imgPhoto = new Image();
      imgPhoto.crossOrigin = 'anonymous';
      imgPhoto.src = photo; // Assuming photo is a valid image URL or base64

      imgPhoto.onload = () => {
        // Example: Draw photo at some coordinates (x: 200, y: 100)
        ctx.drawImage(imgPhoto, 10, 170, 375, 357); // Adjust size and position as needed

        // Load and draw button
        const imgButton = new Image();
        imgButton.crossOrigin = 'anonymous';
        imgButton.src = button; // Assuming button is a valid image URL or base64

        imgButton.onload = () => {
          // Example: Draw button at some coordinates (x: 1000, y: 600)
          ctx.drawImage(imgButton, 18, 576, 35, 35); // Adjust size and position as needed

          // Generate final image
          const dataURL = canvas.toDataURL('image/png');
          setb64String(dataURL);
          dataSend(dataURL);
        };
      };
    };
  };

  useEffect(() => {
    if (photo && button) {
      genImage();
    }
  }, [photo, button]);

  return (
    <div style={{ marginTop: "0vh" }}>
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      {b64String && <img style={{ height: "120px", width: "60px" }} src={b64String} alt="Preview" />}
    </div>
  );
}

export default PreviewMobile;
