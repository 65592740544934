import React, { useState, useRef, useEffect } from 'react';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import axios from "axios"
import loading from "../assets/loadingNew.gif"

import * as XLSX from 'xlsx';

//MUI
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import BomStatusModal from '../subViews/BomStatusModal';
import BomUpdateModal from '../subViews/BomUpdateModal';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

import { createTheme } from '@mui/material/styles';


import {  onAuthStateChanged, getAuth, signOut  } from 'firebase/auth';


const theme = createTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#ba000d',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });



export default function BomTest() {

  const auth = getAuth();

    const [data, setData] = useState([]);
    const [batchName, setBatchName] = useState(["None"]);
    const [statusName, setStatusName] = useState(["Open", "Closed"]);
    const [typeName, setTypeName] = useState(["Virtual", "Physical"]);
    const [batchFilter, setBatchFilter] = useState("All")
    const [typeFilter, setTypeFilter] = useState("All")
    const [statusFilter, setStatusFilter] = useState("All")
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [note, setNote] = useState("");
    const [user, setUser] = useState("")
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChange = (e) => {
      setNote(e.target.value)
  }
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

//   const  bomTemplateArray = [
//        {"AccountingId": "NPE"},
//        {"RetailerCode": "WEB"},
//        {"CasePack": 1}
//     ]

let dataSelect1 = []
let dataSelectView1 = []

let dataSelect2 = []
let dataSelectView2 = []

let dataSelect3 = data
let dataSelectView3 = dataSelect3.map((elem) => {return({"Parent SKU": elem.parent, "Batch": elem.batch})})







if (typeFilter!== "All") {
  dataSelect1 = data.filter(elm => elm.type === typeFilter)

  dataSelectView1 = dataSelect1.map((elem) => {return({"Parent SKU": elem.parent, "Batch": elem.batch})})
} else {
   dataSelect1 = data
   dataSelectView1 = dataSelect1.map((elem) => {return({"Parent SKU": elem.parent, "Batch": elem.batch})})
}

if (statusFilter!== "All" && dataSelect1.length > 0) {
    dataSelect2 = dataSelect1.filter(elm => elm.confirmed === statusFilter)

    dataSelectView2 = dataSelect2.map((elem) => {return({"Parent SKU": elem.parent, "Batch": elem.batch})})
  } else {
     dataSelect2 = dataSelect1
     dataSelectView2 = dataSelect2.map((elem) => {return({"Parent SKU": elem.parent, "Batch": elem.batch})})
  }

  if (batchFilter!== "All" && dataSelect2.length > 0) {
    dataSelect3 = dataSelect2.filter(elm => elm.batch === batchFilter)

    dataSelectView3 = dataSelect3.map((elem) => {return({"Parent SKU": elem.parent, "Batch": elem.batch})})
    
  } else {
     dataSelect3 = dataSelect2
     dataSelectView3 = dataSelect3.map((elem) => {return({"Parent SKU": elem.parent, "Batch": elem.batch})})
   
  }

  useEffect(() => {
    handleMenuItems()
  }, [typeFilter, statusFilter])




  const handleMenuItems = () => {

    let batches = dataSelect3.map(elem => elem.batch);
    batches = [...new Set(batches)];

      setBatchName(batches)

  }

        //Select Functions
        const handleBatchFilter = (event) => {
          
            setBatchFilter(event.target.value);
           

          };


          const batchItems = batchName.map((elem) => {
            return (
                <MenuItem value={elem}>{elem}</MenuItem>
            )
          })
          //
          const handleStatusFilter = (event) => {
          
            setStatusFilter(event.target.value);
        
      
          };


    

          const handleTypeFilter = (event) => {
          
            setTypeFilter(event.target.value);
          
      
          };


   




    let test = [
        {
            children: ["1-1368X1-3376X1-3388", "1368", "3376", "3388"],
            parent: "1-1368X1-3376X1-3388"

        },
        {
            children: ["1-1369X1-3377X1-3389", "1369", "3377", "3389"],
            parent: "1-1369X1-3377X1-3389"

        },

    ]

    let formData = dataSelect3.map((elem) => {

        let childrenWithParent = [{ parentSku: elem.parent, skuQuant: 1, parentQuant: 1 }, ...elem.children];

        let filterDup = childrenWithParent.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.parentSku === value.parentSku
            ))
            );

            console.log(filterDup)
    
        let formChildren = filterDup.map((elm, i) => {
          
            let CTC = i === 0 ? "N" : "E";
    
            return {
                "AccountingId": "NPE",
                "RetailerCode": "WEB",
                "UPC": elem.parent,
                "SKU": elm.parentSku,
                "ConverToCase": CTC,
                "CasePack": parseInt(elm.parentQuant)
            };
        });
    
        return formChildren;
    }).flat();



    let formData2 = dataSelect3.map((elem) => {


          

    
            return ({
                "SKU": elem.parent,
                "UPC": elem.parent,
                "Brand": "Pacas",
                "Name": elem.parent + " Pacas " + elem.style,
                "Category": elem.style,
                "UOM": "EACH",
                "retail_price": 37,
                "case_quantity": 1,
                "no_of_inner_packs": 1,
                "case_weight": 1.00,
                "case_length": 1.00,
                "case_width": 1.00,
                "case_height": 1.00,
                "case_cube": 1.00,
                "Track_SerialNo": 0,
                "Is_Hazmat": 0,
                "CountryOfOrigin": "US",
                "HarmonizationCode": "6115.94.0000",
                "StyleNumber": "",
                "ColorCode": "",
                "SizeCode": "",
                "NMFC": "49885",
                "CommodityDesc": "Apparel",
                "Class": "175",
                "AvailabilityCode": "",
                "AvailabilityStartDate": "",
                "AvailabilityEndDate": "",
                "TrackLotNumber": 0,
                "TrackExpiryDate": 0,
                "ShelfLifeInDays": 9999,
                "ExpiryLeadTime": 15,
                "Fragile": 0,
                "Track_QR_Code": 0




    })
})


let formData3 = dataSelect3.map((elem) => {


          

    
  return ({
      "SKU": elem.parent,
      "UPC": "",
      "Brand": "Pacas INC.",
      "Name": elem.color,
      "Category": elem.style,
      "UOM": "EACH",
      "retail_price": parseInt(elem.price),
      "case_quantity": 120 / elem.children.length,
      "no_of_inner_packs": 1,
      "case_weight": 20,
      "case_length": 24,
      "case_width": 24,
      "case_height": 24,
      "case_cube": 8,
      "Track_SerialNo": 0,
      "Is_Hazmat": 0,
      "CountryOfOrigin": "UNITED STATES",
      "HarmonizationCode": "6115.94.0000",
      "StyleNumber": elem.target_id,
      "ColorCode": "",
      "SizeCode": elem.sizeCode,
      "NMFC": "49885",
      "CommodityDesc": "Apparel",
      "Class": "175",
      "AvailabilityCode": "",
      "AvailabilityStartDate": "",
      "AvailabilityEndDate": "",
      "TrackLotNumber": 0,
      "TrackExpiryDate": 0,
      "ShelfLifeInDays": 9999,
      "ExpiryLeadTime": 15,
      "Fragile": 0,
      "Track_QR_Code": 0




})
})



    


    const log = () => {
        console.log(formData)
    }

    const getLog = () => {
        axios.post("./api/variant/getall", {
          batch: "TEST2"
        }).then((res) => {
          console.log(res.data)
        //   let mutData = res.data.map((elem) => {
        //     return (

        //     )
        
          setData(res.data)

          let batches = res.data.map(elem => elem.batch);
          batches = [...new Set(batches)];

            setBatchName(batches)



        })
      }
    



   useEffect(() => {
        getLog()
        onAuthStateChanged(auth, (user) => {
          if (user) {
            console.log(user.email)
            setUser(user.email)
          } else {
            console.log("no")
          }
        });
   }, [])


   const logData = () => {
    console.log(dataSelect1)
   }

   //Table

   //New Table

   const columns = [
    {id:"parent", label:"Parent Sku", minWidth: 170},
    {id:"batch", label:"Batch", minWidth: 170},
   ]



   //



   let headers = []

   if (dataSelectView3.length > 0) {
    headers = Object.keys(dataSelect3[0])
    }


   const ViewTable = () => (
    <>

    <table className="data-table">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
     
        </tr>
      </thead>
      <tbody>
        {dataSelectView3.map((row, index) => (
          <tr key={index}>
            {headers.map((header) => (
              <td key={header}>{row[header]}</td>
            ))}
      
          </tr>
        ))}
      </tbody>
    </table>
    
    </>
  );
  //








    const generateExcelFile = async (inputData) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');
    
        const data = inputData
    
      
        // Add column headers
        const headers = Object.keys(data[0]);
        worksheet.addRow(headers);
    
        // Add data rows
        data.forEach(item => {
          const values = Object.values(item);
          worksheet.addRow(values);
        });
    
    
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          console.log(blob)
          saveAs(blob, 'BOM_Template.xlsx');
        });
      };

      //
      function exportToExcel(data, fileName) {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();
      
        // Convert JSON data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);
      
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      
        // Write and export the workbook
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
      }


    const handleBOMExport = () => {
      if (typeFilter === "virtual") {
        let currentDate = new Date();
        let month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS, so add 1
        let day = String(currentDate.getDate()).padStart(2, '0');
        let formattedDate = month + '-' + day;
        exportToExcel(formData, "SKU Translation " + formattedDate)
        exportToExcel(formData2, "Non Picking Non Shipping " + formattedDate)
      } else if (typeFilter === "physical") {
        let currentDate = new Date();
        let month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS, so add 1
        let day = String(currentDate.getDate()).padStart(2, '0');
        let formattedDate = month + '-' + day;
        exportToExcel(formData3, "INCOMPLETE_SKUS_TO_ADD " + formattedDate)

      } else {
        alert("Please select a type to export")
      }
    }


    const handleBatchUpdate = () => {
      const date = new Date();
      const currentTime = (date.getMonth() + 1).toString() + '/' + 
                          date.getDate().toString() + '/' + 
                          date.getFullYear().toString().substring(2);
        if (batchFilter !== "All" && batchFilter.length > 0) {
        axios.post("/api/variant/updatebatch", {
            batch: batchFilter,
            note: note,
            time: currentTime,
            user: user
        }) .then((res) => {
            console.log(res.data)
            getLog()
            setStatusFilter("All")
            setTypeFilter("All")
            setBatchFilter("All")
            setNote("")

        
        })
        } else {
            alert("Please select a Batch and try again")
            return
        }
    }

    const handleDelete = async () => {
      try {
        let response = await axios.post("/api/variant/delete", {
          batch: batchFilter
        })

        console.log(response)
      }
      catch (error) {
        alert(error)
      }
    }


    if (data.length > 0) {
 


    return (
        <div style={{ marginLeft: "200px", marginRight:"50px" }}>
            {/* <h1>Test</h1> */}

            {/* <button onClick={logData}>LOG</button> */}
            <div style={{display:"flex", alignItems:"center", borderBottom:"1px solid black"}}>

            <Box style={{margin:"1vw" }}sx={{ minWidth: 150, maxWidth: 150 }}>
             <FormControl fullWidth>
                <InputLabel sx={{  color: "white" }} id="demo-simple-select-label">Type</InputLabel>
                <Select
                sx={{  color: "white" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={typeFilter}
                label="Category"
                onChange={handleTypeFilter}
                >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"virtual"}>Virtual</MenuItem>
                <MenuItem value={"physical"}>Physical</MenuItem>

                </Select>
            </FormControl>
            </Box>
            <Box style={{margin:"1vw" }}sx={{ minWidth: 150, maxWidth: 150 }}>
             <FormControl fullWidth>
                <InputLabel sx={{  color: "white" }} id="demo-simple-select-label">Status</InputLabel>
                <Select
                sx={{  color: "white" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={statusFilter}
                label="Category"
                onChange={handleStatusFilter}
                >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={false}>Open</MenuItem>
                <MenuItem value={true}>Closed</MenuItem>
     

                </Select>
            </FormControl>
            </Box>
            <Box style={{margin:"1vw" }}sx={{ minWidth: 150, maxWidth: 150 }}>
             <FormControl fullWidth>
                <InputLabel sx={{  color: "white" }} id="demo-simple-select-label">Batch</InputLabel>
                <Select
                sx={{  color: "white" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={batchFilter}
                label="Category"
                onChange={handleBatchFilter}
                >
                <MenuItem value={"All"}>All</MenuItem>
                {batchItems}

                </Select>
            </FormControl>
            </Box>
            <Button style={{minHeight:"5.5vh", marginLeft:"40vw", backgroundColor: '#3d4bdb', color: 'white' }}onClick={handleBOMExport}>EXPORT BOM</Button>
      

            
            </div>


            <div style={{ display: "flex", alignItems: "center", marginLeft:"35%" }}>
                {batchFilter !== "All" && dataSelect3.length > 0 ?
                    dataSelect3[0].confirmed ?
                        <>
                            <h3 style={{ marginRight: "5vw", color: "white" }}>{batchFilter}</h3>
                            <h3 style={{ color: "white",marginRight: "5vw" }}>Status: Closed</h3>
                            <BomStatusModal data={dataSelect3} />
                            {/* <button onClick={handleDelete}>DELETE BATCH</button> */}
                        </>
                        :
                        <>
                            <h3 style={{ marginRight: "5vw", color: "white" }}>{batchFilter}</h3>
                            <h3 style={{ color: "white",marginRight: "5vw" }}>Status: Open</h3>
                          <BomUpdateModal handleBatchUpdate={handleBatchUpdate} note={note} handleChange={handleChange} />
                          {/* <button onClick={handleDelete}>DELETE BATCH</button> */}
                        </>
                    : ""}
            </div>

                     {/* <BomStatusModal /> */}
{/* 
            <div style={{height:"90vh",overflowY: "scroll",marginBottom: "80px"}}> */}
            {/* <ViewTable /> */}



            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: "60vh" }}>
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            >
                            {column.label}
                            </TableCell>
                        ))} 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSelect3
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                    <TableCell key={column.id} align={column.align}>
                                    {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                );
                                })}
                            </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={dataSelect3.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Paper>



            {/* </div> */}


        </div>
    )
  } else {

    return (
      <div style={{marginLeft: "43vw", marginTop: "22vh"}}>
      <img src={loading} />
      </div>
    )
  }
}