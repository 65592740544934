import React, { useEffect, useState, forwardRef } from 'react';



//MUI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';



 const KRISingleDisplay = ((props, ref) => {

  const [skuQuant, setSkuQuant] = useState("")
  const [view, setView] = useState(0)




  const handleSet = () => {
    props.addData({sku:props.elem.sku, krsku:props.elem.krsku, quantity: parseInt(skuQuant)})
    setView(1)
  }

  const handleSet2 = () => {
    props.removeData({sku:props.elem.sku, krsku:props.elem.krsku, quantity:skuQuant})
    setView(0)
    setSkuQuant("")
  }

 

 const handleQuant = (e) => {
    let quant = e.target.value
    setSkuQuant(quant)
  
  }
        return(
            <Card raised={true} sx={{ maxWidth: "25vw", minHeight: "75vh", maxHeight: "75vh", bgcolor: "#ededf2", borderRadius: "10px" }} >
            <Typography gutterBottom variant="h6" component="div">
                {props.elem.sku}
            </Typography>
            <Typography gutterBottom variant="h8" component="div">
                {props.elem.krsku}
            </Typography>
            {
                props.elem.image ? (
                    <CardMedia
                    sx={{ height: "20vw" }}
                    image={props.elem.image}
                    title={props.sku}
                    // onClick={handleClick}
                    style={{
                      backgroundColor: view === 0 ?"white": "aqua"
                    }}
                  />
                ) : (
                    <Skeleton variant="rectangular"  height={300} />
                )
                }
    
            <CardContent>
            {view === 0 ? <Typography gutterBottom variant="h7" component="div">
               Westmont Qty: {props.elem.quantity}
            </Typography> : 
            <Typography gutterBottom variant="h7" component="div">
           Westmont Qty: {props.elem.quantity} - {skuQuant} = {Number(props.elem.quantity) - Number(skuQuant)}
         </Typography> }
            <Typography gutterBottom variant="h8" component="div">
               Capacity Loc Qty: {props.elem.capacityLevels}
            </Typography>
            <Typography gutterBottom variant="h8" component="div">
               Chestnut Loc Qty: {props.elem.chestnutLevels}
            </Typography>
            <Typography gutterBottom variant="h7" component="div">
              Total Shopify Qty: {props.elem.chestnutLevels + props.elem.capacityLevels}
            </Typography>
   

              {/* {view === 0 ? <input onChange={handleQuant} type="number" placeholder='Quantity' min="0" /> : <input onChange={handleQuant} type="number" disabled="true" placeholder='Quantity' min="0" /> }
             {view === 0 ? <Button style={{margin:"1vw"}}  component="label" variant="contained"  onClick={handleSet}>Add</Button> : <Button  style={{margin:"1vw"}} component="label" variant="contained"  onClick={handleSet2}>Remove</Button> }  */}
            </CardContent>
   
          </Card>
        )

 })

export default KRISingleDisplay