
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import loading from "../assets/loadingNew.gif"
import axios from "axios"

import {  onAuthStateChanged, getAuth, signOut  } from 'firebase/auth';

import AdjustmentDialogue from '../components/AdjustmentDialogue';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80vw",
    height: "90vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function AdjustmentsCreateModal( {createOpen, createLoad, handleCreateClose, data, failData} ) {

    const auth = getAuth();


    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState("Making Adjustments...");
    const [progOpen, setProgOpen] = useState(false);
    const [user, setUser] = useState("")



    useEffect(() => {
     
        onAuthStateChanged(auth, (user) => {
          if (user) {
            console.log(user.email)
            setUser(user.email)
          } else {
            console.log("no")
          }
        });
      }, []);


    const handleProgOpen = () => {
        setProgOpen(true);
        setProgress(5)
        
      };
  
      const handleProgClose = () => {
        setProgOpen(false);
        setStatus("Making Adjustments...")
      };


      const increase = () => {
        if (progress === 100) {
          handleProgClose()
          handleCreateClose()
       
        } 
     
      }


    const uploadDB = (sku, adjustment, newInventory, po) => {

   
      const date = new Date();
      const currentTime = (date.getMonth() + 1).toString() + '/' + 
                          date.getDate().toString() + '/' + 
                          date.getFullYear().toString().substring(2);

    axios.post("/api/adjustment/create", 

    {
        sku: sku,
        adjustment: adjustment,
        new_inventory: newInventory,
        updated_at: currentTime,
        updated_by: user,
        po: po

    }
            ).then((res) => {
                console.log(res)
            })
    }

const adjustInventory = () => {

    handleProgOpen()

    data.forEach((elem, i) => {

    setTimeout(() => {
        
    axios.post("/api/shopify/adjustinventory",
    {
        inventoryItemId: elem.inventoryItemId,
        adjustment: Number(elem.adjustment)
    } 

       
    ).then((res) => {
        let prog = Math.ceil(100 / data.length) * (i + 1)
        if (prog < 100) {
            setProgress(prog)
        } else {
            setProgress(100)
            setStatus("Finshed")
        }
        console.log(res)
        let newInv = res.data.inventory_level.available.toString()

        uploadDB(elem.sku, elem.adjustment,newInv, elem.po )
    })
  }, 200 * i);

})
}



    const UploadTable = () => (
        <>

        <table className="data-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
         
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                {headers.map((header) => (
                  <td key={header}>{row[header]}</td>
                ))}
          
              </tr>
            ))}
          </tbody>
        </table>
        
        </>
      );


      const FailTable = () => (
        <>
        <h3>Some Sku's Not Found</h3>
        <table className="data-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
             
            </tr>
          </thead>
          <tbody>
            {failData.map((row, index) => (
              <tr key={index}>
                {headers.map((header) => (
                  <td key={header}>{row[header]}</td>
                ))}
           
              </tr>
            ))}
          </tbody>
        </table>
        </>
      );



let headers = []

if (failData.length > 0) {
    headers = Object.keys(failData[0])
}

if (failData.length === 0 && data.length > 0) {
    headers = Object.keys(data[0])
}





if (createLoad === 0) {

  return (
    <div>
      {/* <Button onClick={handleOpen}>View</Button> */}
      <Modal
        open={createOpen}
        onClose={handleCreateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

           <img style={{margin:"10vw", height:"40vh", marginLeft:"25vw"}}src={loading} />
        
        </Box>
      </Modal>
    </div>
  );
} else if (createLoad === 1 && failData.length > 0) {

    return (
        <div>
          {/* <Button onClick={handleOpen}>View</Button> */}
          <Modal
            open={createOpen}
            onClose={handleCreateClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <FailTable />
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    
              </Typography>
            </Box>
          </Modal>
        </div>
      );



} else if (createLoad === 1 && failData.length === 0 && data.length > 0) {

    return (
        <div>
          {/* <Button onClick={handleOpen}>View</Button> */}
          <Modal
            open={createOpen}
            onClose={handleCreateClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
            <h3>Upload Adjustments</h3>
                <div style={{height:"60vh",overflowY: "scroll",marginBottom: "80px"}}>

               <UploadTable />
               </div>
           
               <Button component="label" variant="contained" style={{marginLeft:"35vw", height:"3vw"}}    onClick={adjustInventory}>Upload</Button>
               <AdjustmentDialogue progress={progress} status={status} progOpen={progOpen} increase={increase} handleProgClose={handleProgClose} />
            </Box>

          </Modal>
        </div>
      );



}



}