


import React, { useEffect, useState } from 'react';
import BIAPI from './BIAPI';

import loading from "../assets/loadingNew.gif"





export default function PhotoCreate(props) {
  const [base64Image, setBase64Image] = useState('');
  const [load, setLoad] = useState(0);

  let imageData = props.selectedData.concat(props.selectedData2, props.selectedData3)



  // const handleImageLoad = async () => {
  //   const canvas = document.createElement('canvas');
  //   canvas.width = 1200;
  //   canvas.height = 1200;
  //   const ctx = canvas.getContext('2d');

  //   const imgWidth = 800;
  //   const imgHeight = 800;

  //   if(props.color !== "") {
  //       ctx.fillStyle = props.color;
  //       ctx.fillRect(0, 0, canvas.width, canvas.height);
  //       console.log(props.color)
  //   }

  //   for (let i = props.data.children.length - 1; i >= 0; i--) {
  //       console.log(i)
  //     const data = props.data.children[i];
  //     const img = new Image();
  //     img.crossOrigin = 'anonymous';

  //     await new Promise(resolve => {
  //       img.onload = () => {
  //       //   const xOff = ((i) * props.valueXOff).toString()
  //       //   const x = (parseInt(xOff) + props.valueX).toString()
  //         // console.log(x)
  //         ctx.drawImage(img, props.SV1[props.data.children[i].sku], props.SV2[props.data.children[i].sku], props.SV3[props.data.children[i].sku], props.SV3[props.data.children[i].sku]);

  //         resolve();
  //       };
  //       img.src = props.data.children[i].src;
  //     });
  //   }

  //   const dataURL = canvas.toDataURL('image/png');
  //   setBase64Image(dataURL);
  //   setLoad(1)
  // };


  //

  const handleImageLoad = async () => {
    const canvas = document.createElement('canvas');
    canvas.width = 1200;
    canvas.height = 1200;
    const ctx = canvas.getContext('2d');

    const imgWidth = 800;
    const imgHeight = 800;

    if(props.color !== "") {
        ctx.fillStyle = props.color;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        console.log(props.color)
    }

    for (let i = imageData.length - 1; i >= 0; i--) {
        console.log(i)
      const data = imageData[i];
      const img = new Image();
      img.crossOrigin = 'anonymous';

      await new Promise(resolve => {
        img.onload = () => {
        //   const xOff = ((i) * props.valueXOff).toString()
        //   const x = (parseInt(xOff) + props.valueX).toString()
          // console.log(x)
          ctx.drawImage(img, props.SV1[i], props.SV2[i], props.SV3[i], props.SV3[i]);

          resolve();
        };
        img.src = imageData[i].src;
      });
    }

    const dataURL = canvas.toDataURL('image/png');
    setBase64Image(dataURL);
    setLoad(1)
  };

  useEffect(() => {
    handleImageLoad();
    logVal()
  }, [props.SV1, props.SV2, props.SV3, props.color, props.selectedData, props.selectedData2, props.selectedData3]);

  const logVal = () => {
    console.log(props.SV1)
    console.log(props.data)
  }

  if (load === 0) {
    return (
        <img src={loading}></img>
    )
  } else {

  return (
    <div style={{marginLeft: "3vw"}} >
      <img src={base64Image} style={{maxWidth: "35vw", border: "2px solid black"}} alt="Image" />
      {/* <CreateAPI base64Image={base64Image} details={props.details} validate={props.validate} sort={props.sort} buttonData={props.buttonData} /> */}
      <BIAPI data={props.data} base64Image={base64Image} />

    </div>
  );
  }
}