import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import loading from "../assets/loadingNew.gif"
import axios from "axios"
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80vw",
    height: "90vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function AdjustmentsViewModal( {viewOpen, handleViewClose, viewData, viewLoad} ) {


  const [checked, setChecked] = React.useState(false);

  const [checked2, setChecked2] = React.useState(false);

  const [viewDataForm, setViewDataForm] = React.useState([...viewData]);

  const [startDate, setStartDate] = React.useState("");

  const [endDate, setEndDate] = React.useState("");

  const [load, setLoad] = React.useState(0);

  // const [startDateString, setStartDateString] = React.useState("");

  // const [endDateString, setEndDateString] = React.useState("");

  const reset = () => {
    setViewDataForm([...viewData])
    setChecked("false")
    setChecked2("false")
    setStartDate("")
    setEndDate("")
  }

  const multiClose = () => {
    reset()
    handleViewClose()

  }


  const handleStartDate = (e) => {
    setStartDate(e.target.value)
   

  }

  const handleEndDate = (e) => {
    setEndDate(e.target.value)
  }


  const parseDateStr = (dateStr) => {
    const [month, day, year] = dateStr.split('/').map(Number);
    return new Date(year, month - 1, day);
  }

  const handleFilter = () => {
    const startDateF = parseDateStr(startDate);
    const endDateF = parseDateStr(endDate);

    if (startDateF == "Invalid Date" || endDateF == "Invalid Date") {
      alert("Invalid Date")
      return
    }

    console.log(startDateF)
    console.log(endDateF)

    let dateFilter = [...viewDataForm].filter((item) => {
        const itemDate = parseDateStr(item.updated_at);
        return itemDate >= startDateF && itemDate <= endDateF;
    });

    setViewDataForm(dateFilter)
  }




  const handleChange = (event) => {
    setChecked(event.target.checked);

    if (event.target.checked === true) {
      const sortedData = [...viewData].sort((a, b) => {
       // Parse 'updated_at' to a Date object and compare
       const datePartsA = a.updated_at.split('/');
       const datePartsB = b.updated_at.split('/');
       const dateA = new Date(datePartsA[2], datePartsA[0] - 1, datePartsA[1]);
       const dateB = new Date(datePartsB[2], datePartsB[0] - 1, datePartsB[1]);
       return dateB - dateA;

     
   });

   setViewDataForm(sortedData)
   } else {
    setViewDataForm([...viewData]);

   }
 

   console.log(viewDataForm)

  };

  const handleChange2 = (event) => {
    setChecked2(event.target.checked);
    console.log(event.target.checked)

    if (event.target.checked === true) {
      const groupedData = [...viewData].sort((a, b) => {
        if (a.sku < b.sku) {
            return -1;
        }
        if (a.sku > b.sku) {
            return 1;
        }
        return 0;
    });

    setViewDataForm(groupedData)
    }
    else  {
      setViewDataForm([...viewData])
   
    }
  };

//   React.useEffect(() => {
//     setViewDataForm([...viewData]);
// }, [viewData]);



    const generateExcelFile = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');
    
        const data = viewDataForm
    
      
        // Add column headers
        const headers = Object.keys(data[0]);
        worksheet.addRow(headers);
    
        // Add data rows
        data.forEach(item => {
          const values = Object.values(item);
          worksheet.addRow(values);
        });
    
    
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'adjustments.xlsx');
        });
      };


let headers = []

if (viewDataForm.length > 0) {
    headers = Object.keys(viewDataForm[0])
}

    const ViewTable = () => (
        <>

        <table className="data-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
         
            </tr>
          </thead>
          <tbody>
            {viewDataForm.map((row, index) => (
              <tr key={index}>
                {headers.map((header) => (
                  <td key={header}>{row[header]}</td>
                ))}
          
              </tr>
            ))}
          </tbody>
        </table>
        
        </>
      );

      React.useEffect(() => {
        setViewDataForm([...viewData]);
        

    }, [viewData]);






if (viewDataForm.length > 0) {

  if (load !== 1) {
    setLoad(1)
  }

  return (
    <div>

      <Modal
        open={viewOpen}
        onClose={multiClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <h3>Adjustments</h3>
            <FormControlLabel
              value={checked}
              onChange={handleChange}
              control={<Switch color="primary" />}
              label="Sort By Recent"
              labelPlacement="top"
            />
              <FormControlLabel
              value={checked2}
              onChange={handleChange2}
              control={<Switch color="primary" />}
              label="Group By SKU"
              labelPlacement="top"
            />
            <input value={startDate} onChange={handleStartDate} placeholder='Start Date'></input>
            <input value={endDate} onChange={handleEndDate} placeholder='End Date'></input>
            <button onClick={handleFilter}>Filter</button>
            <button onClick={reset}>Reset</button>
        
            <div style={{height:"60vh",overflowY: "scroll",marginBottom: "80px"}}>
            <ViewTable />
            </div>
            <Button component="label" variant="contained" style={{marginLeft:"35vw"}} onClick={generateExcelFile} >Export</Button>

 
        </Box>
      </Modal>
    </div>
  );
} else if (viewDataForm.length === 0 && viewData.length === 0 ) {

    return (
        <div>

          <Modal
            open={viewOpen}
            onClose={multiClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
               <img style={{margin:"10vw", height:"40vh", marginLeft:"25vw"}} src={loading} />
              
               {/* <button>GET</button> */}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    
              </Typography>
            </Box>
          </Modal>
        </div>
      );



} else if (viewDataForm.length === 0 && viewData.length > 0 && load === 1 ) {

  return (
    <div>

      <Modal
        open={viewOpen}
        onClose={multiClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <h3>Adjustments</h3>
            <FormControlLabel
              value={checked}
              onChange={handleChange}
              control={<Switch color="primary" />}
              label="Sort By Recent"
              labelPlacement="top"
            />
              <FormControlLabel
              value={checked2}
              onChange={handleChange2}
              control={<Switch color="primary" />}
              label="Group By SKU"
              labelPlacement="top"
            />
            <input value={startDate} onChange={handleStartDate} placeholder='Start Date'></input>
            <input value={endDate} onChange={handleEndDate} placeholder='End Date'></input>
            <button onClick={handleFilter}>Filter</button>
            <button onClick={reset}>Reset</button>
        
            <div>
              <h3>Typical Kaitlyn, Click Reset and check your filter date formats, Or there are just no adjustments matching your filter</h3>
            </div>

 
        </Box>
      </Modal>
    </div>
  );

}




}