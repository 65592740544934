



import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CreateAPI from './CreateAPI';

export default function PhotoCreate(props) {
  const [base64Image, setBase64Image] = useState('');
  const [base64Image2, setBase64Image2] = useState('');

  const sharpnessMatrix = (sharpness) => {
    const factor = sharpness * -1;
    const centerValue = 1 + sharpness * 4; // Increase the center pixel strength
    return [
      [0, factor, 0],
      [factor, centerValue, factor],
      [0, factor, 0],
    ];
  };
  
  const applyConvolution = (imageData, matrix) => {
    const pixels = imageData.data;
    const width = imageData.width;
    const height = imageData.height;
  
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    tempCanvas.width = width;
    tempCanvas.height = height;
  
    const outputData = tempCtx.createImageData(width, height);
  
    const matrixSize = matrix.length;
    const half = Math.floor(matrixSize / 2);
  
    // Iterate over each pixel, excluding the borders
    for (let y = half; y < height - half; y++) {
      for (let x = half; x < width - half; x++) {
        let r = 0, g = 0, b = 0;
  
        // Apply convolution matrix
        for (let j = 0; j < matrixSize; j++) {
          for (let i = 0; i < matrixSize; i++) {
            const pixelX = x + i - half;
            const pixelY = y + j - half;
            const pixelIndex = (pixelY * width + pixelX) * 4;
  
            r += pixels[pixelIndex] * matrix[j][i];
            g += pixels[pixelIndex + 1] * matrix[j][i];
            b += pixels[pixelIndex + 2] * matrix[j][i];
          }
        }
  
        // Set new pixel values
        const outputIndex = (y * width + x) * 4;
        outputData.data[outputIndex] = Math.min(255, Math.max(0, r));
        outputData.data[outputIndex + 1] = Math.min(255, Math.max(0, g));
        outputData.data[outputIndex + 2] = Math.min(255, Math.max(0, b));
        outputData.data[outputIndex + 3] = pixels[outputIndex + 3]; // Alpha remains unchanged
      }
    }
  
    return outputData;
  };
  
  const handleImageLoad = async () => {
    const canvas = document.createElement('canvas');
    canvas.width = 1200;
    canvas.height = 1200;
    const ctx = canvas.getContext('2d');
  
    // Draw images on the canvas
    for (let i = props.selectedData.length - 1; i >= 0; i--) {
      const data = props.selectedData[i];
      const img = new Image();
      img.crossOrigin = 'anonymous';
  
      await new Promise((resolve) => {
        img.onload = () => {
          const x = (parseInt(i * props.valueXOff) + props.valueX).toString();
          ctx.drawImage(img, x, props.valueY, props.valueSize, props.valueSize);
          resolve();
        };
        img.src = data.image;
      });
    }
  

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
  
    for (let j = 0; j < data.length; j += 4) {
      data[j] = Math.min(255, data[j] * props.brightness); // Red
      data[j + 1] = Math.min(255, data[j + 1] * props.brightness); // Green
      data[j + 2] = Math.min(255, data[j + 2] * props.brightness); // Blue
  
      //contrast
      data[j] = Math.min(255, Math.max(0, props.contrast * (data[j] - 128) + 128)); // Red
      data[j + 1] = Math.min(255, Math.max(0, props.contrast * (data[j + 1] - 128) + 128)); // Green
      data[j + 2] = Math.min(255, Math.max(0, props.contrast * (data[j + 2] - 128) + 128)); // Blue
    }
  
    // sharpness 
    if (props.sharpness !== undefined && props.sharpness !== 0) {
      const sharpenedData = applyConvolution(imageData, sharpnessMatrix(props.sharpness));
      ctx.putImageData(sharpenedData, 0, 0);
    } else {
      ctx.putImageData(imageData, 0, 0);
    }
  
    const dataURL = canvas.toDataURL('image/png');
    setBase64Image(dataURL);
    handleDownSize(dataURL);
  };


    const handleDownSize = async (imgUrl) => {
    const canvas = document.createElement('canvas');
    canvas.width = 800;
    canvas.height = 800;
    const ctx = canvas.getContext('2d');
    const img = new Image();

    await new Promise(resolve => {
    
      img.onload = () => {
    
        ctx.drawImage(img, 0, 0, 800, 800);
        console.log("fire")

        resolve();
      };
      img.src = imgUrl
    });
    const dataURL = canvas.toDataURL('image/png');
    console.log(dataURL.length)
    setBase64Image2(dataURL);
    props.handleImage(dataURL)
  }

  useEffect(() => {
    handleImageLoad();
  }, [props.selectedData, props.valueX, props.valueY, props.valueXOff, props.valueSize, props.brightness, props.contrast, props.sharpness]);

  return (
    <div style={{ marginLeft: "6vw" }}>
      <img src={base64Image2} style={{ maxWidth: "32vw", border: "2px solid black" }} alt="Image" />
      <CreateAPI
        handleAlert={props.handleAlert}
        base64Image={base64Image2}
        details={props.details}
        validate={props.validate}
        auto={props.auto}
        sort={props.sort}
        buttonData={props.buttonData}
        uploadVariantData={props.uploadVariantData}
      />
    </div>
  );
}
