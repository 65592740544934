import React, { useEffect, useState } from 'react';
import axios from "axios"
import KRIDisplay from './KRIDisplay';

import loading from "../assets/loadingNew.gif"


import kdata from "./kri7.json"
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';







export default function KRInventory() {

    const [data, setData] = useState([])
    const [data2, setData2] = useState([])
    const [ep, setEp] = useState([])


    const generateExcelFile = async () => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');
  
      const data3 = ep
  
    
      // Add column headers
      const headers = Object.keys(data3[0]);
      worksheet.addRow(headers);
  
      // Add data rows
      data3.forEach(item => {
        const values = Object.values(item);
        worksheet.addRow(values);
      });
  
  
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'KRI.xlsx');
      });
    };


    const getAll = () => {
        axios.post("/api/kri/getall", {}).then((res) => {
            console.log(res.data)
            setData2(res.data)
            getShop(res.data)
        })
    }

    // const getShop =  (data) => {
    //     axios.post("/api/shopify/getsingleitems", {}) .then((res) => {
            

    //         let images = res.data.products.map((elem) => {
    //             return (
    //                 elem.images
    //             )
    //         }).flat()

    //         let variants = res.data.products.map((elem) => {
    //             return (
    //                 elem.variants
    //             )
    //         }).flat()

    //         let combinedData = variants.map((elem) => {
    //             let foundImages = images.find((elm) => elm.id === elem.image_id)

    //             if (foundImages) {

    //             return(
    //                 {
    //                     sku: elem.sku,
    //                     prodId: elem.product_id,
    //                     variantId: elem.id,
    //                     inventoryId: elem.inventory_item_id,
    //                     image: foundImages.src
    //                 }
    //             )
    //             } else {
    //                 return(
    //                     {
    //                         sku: elem.sku,
    //                         prodId: elem.product_id,
    //                         variantId: elem.id,
    //                         inventoryId: elem.inventory_item_id,
    //                         image: "NA"
    //                     }
    //                 )

    //             }
    //         })


    //         console.log(combinedData)


    //         let dataAll = data.map((elem) => {
    //             let match = combinedData.find((elm) => elm.sku === elem.sku)

    //             if (match) {
    //                 return (
    //                     {
    //                         sku: elem.sku,
    //                         prodId: match.prodId,
    //                         variantId: match.variantId,
    //                         inventoryId: match.inventoryId,
    //                         image: match.image,
    //                         krQuant: elem.quantity,
    //                         krsku: elem.krsku
    //                     }
    //                 )
    //             } else return
    //         }).filter((elem) => elem !== undefined)

    //         console.log(dataAll)

    //         //
    //         let ids = data.slice(0,250).map((elem) => {return elem.inventoryId}).join(",")
    //         let ids2 = data.slice(250).map((elem) => {return elem.inventoryId}).join(",")
        

            
    //         let levelDataCapacity = []
    //         let levelDataChestnut = []
            
    //         console.log(ids)
    //         axios.post("/api/shopify/getlevels", {
    //             levelIds: ids
    //         }
    //         ) .then((res) => {
    //             console.log(res)
    //             levelDataCapacity.push(res.data.inventory_levels)
    //         })
    //         axios.post("/api/shopify/getlevels", {
    //             levelIds: ids2
    //         }
    //         ) .then((res) => {
    //             console.log(res)
    //             levelDataCapacity.push(res.data.inventory_levels)
    //         })
    //         axios.post("/api/shopify/getlevelsold", {
    //             levelIds: ids
    //         }
    //         ) .then((res) => {
    //             console.log(res)
    //             levelDataChestnut.push(res.data.inventory_levels)
    //         })
    //         axios.post("/api/shopify/getlevelsold", {
    //             levelIds2: ids2
    //         }
    //         ) .then((res) => {
    //             console.log(res)
    //             levelDataChestnut.push(res.data.inventory_levels)
    //         })


    //         let datatest = data.map((elem) => {
    //             let find  = levelDataCapacity.flat().find((elm) => elm.inventory_item_id === elem.inventoryId)
    //             if (find) {
    //             return (
    //                 {
    //                     sku: elem.sku,
    //                     prodId: elem.prodId,
    //                     variantId: match.variantId,
    //                     inventoryId: elem.inventoryId,
    //                     image: elem.image,
    //                     krQuant: elem.quantity,
    //                     krsku: elem.krsku,
    //                     capacityLevel: find.available
    //                 }
    //             )
    //             }
    //         })
    //         let datatest2 = datatest.map((elem) => {
    //             let find  = levelDataChestnut.flat().find((elm) => elm.inventory_item_id === elem.inventoryId)
    //             if (find) {
    //             return (
    //                 {
    //                     sku: elem.sku,
    //                     prodId: elem.prodId,
    //                     variantId: match.variantId,
    //                     inventoryId: elem.inventoryId,
    //                     image: elem.image,
    //                     krQuant: elem.quantity,
    //                     krsku: elem.krsku,
    //                     capacityLevel: elem.available,
    //                     chestnutLevel: find.available
    //                 }
    //             )
    //             }
    //         })
    //         setData(datatest2)

    //     })
    // }

    const getShop = async (data) => {
        try {
            const { data: shopifyData } = await axios.post("/api/shopify/getsingleitems", {});
    
            const extractField = (field) => shopifyData.products.flatMap(product => product[field]);
            const images = extractField('images');
            const variants = extractField('variants');
    
            const findImageForVariant = (variant) => images.find(image => image.id === variant.image_id) || { src: "NA" };
            const combinedData = variants.map(variant => ({
                ...variant,
                image: findImageForVariant(variant).src,
            }));
    
            console.log(combinedData);
    
            const filterAndAddFields = (elem) => {
                const match = combinedData.find(elm => elm.sku === elem.sku);
                if (!match) {console.log(elem.sku)}
                return match ? { ...elem, ...match, krQuant: elem.quantity, krsku: elem.krsku } : null;
            };
    
            const dataAll = data.map(filterAndAddFields).filter(elem => elem);
    
            console.log(dataAll);
    
            const fetchLevels = async (ids, url) => {
                console.log(ids)
                const { data } = await axios.post(url, { levelIds: ids });
                return data.inventory_levels;
            };

            console.log(data)
    
            const ids = dataAll.slice(0, 250).map(elem => elem.inventory_item_id).join(",");
            const ids2 = dataAll.slice(250).map(elem => elem.inventory_item_id).join(",");
            const levelDataCapacityCalls = Promise.all([
                fetchLevels(ids, "/api/shopify/getlevels"),
                fetchLevels(ids2, "/api/shopify/getlevels")
            ]);
    
            const levelDataChestnutCalls = Promise.all([
                fetchLevels(ids, "/api/shopify/getlevelsold"),
                fetchLevels(ids2, "/api/shopify/getlevelsold")
            ]);
    
            const [firstCapacityLevels, secondCapacityLevels] = await levelDataCapacityCalls;
            const [firstChestnutLevels, secondChestnutLevels] = await levelDataChestnutCalls;
    
            // Combine the results from each set of calls
            const levelDataCapacity = firstCapacityLevels.concat(secondCapacityLevels);
            console.log(levelDataCapacity)
            const levelDataChestnut = firstChestnutLevels.concat(secondChestnutLevels);
    
            const updateDataWithLevels = (data, levels) => data.map(elem => {
                const find = levels.find(elm => elm.inventory_item_id === elem.inventory_item_id);
                return find ? { ...elem, capacityLevels: find.available } : {...elem, capacityLevels: 0} ;
            });

            const updateDataWithLevels2 = (data, levels) => data.map(elem => {
                const find = levels.find(elm => elm.inventory_item_id === elem.inventoryId);
                return find ? { ...elem, chestnutLevels: find.available } : {...elem, chestnutLevels: 0};
            });
    
            let datatest = updateDataWithLevels(dataAll, levelDataCapacity.flat());
            let finalData = updateDataWithLevels2(datatest, levelDataChestnut.flat());

            console.log(finalData)

            let finalData2 = finalData.map((elem) => {
                let style = ""
                let cat = ""
                let size = ""

                // if (elem.product_id === 6852663804004 || elem.product_id === 6620534669412 || elem.product_id === 6597571575908 || elem.product_id === 4837716951140 || elem.product_id === 6950074384484 || elem.product_id === 7163184283748 || elem.product_id === 4837733957732 || elem.product_id === 7286790619236 ) { 
                //     style = "Crew Socks"
                //     cat = "Women's"
                //   } 
                  
                //   else if (elem.product_id === 6873293586532 || elem.product_id === 6930797101156 || elem.product_id === 7255628546148 || elem.product_id === 7217697390692) {
                //     style = "Compression Socks"
                //     cat = "Women's"
                //   }
                //   else if (elem.product_id === 6824475361380) {
                //     style = "Golf Socks"
                //     cat = "Women's"
                //   }
                //   else if (elem.product_id === 6609129275492 || elem.product_id === 7255634051172 || elem.product_id === 7217910743140) {
                //     style = "Light-Weight Crew Socks"
                //     cat = "Women's"
                //   }
                //   else if ( elem.product_id === 6626100314212 || elem.product_id === 6816252428388 || elem.product_id === 4823040131172 || elem.product_id === 7217900060772 ) {
                //     style = "Low-Cut Socks"
                //     cat = "Women's"
                //   }
                //   else if (elem.product_id === 6541486489700 || elem.product_id === 7321956876388) {
                //     style = "No-Show Socks"
                //     cat = "Women's"
                //   }
                //   else if (elem.product_id === 6557907746916) {
                //     style = "Performance Socks"
                //     cat = "Women's"
                //   }
                //   else if (elem.product_id === 6575734030436) {
                //     style = "Performance Socks"
                //     cat = "Men's"
                //   }
                //   else if (elem.product_id === 7105370357860) {
                //       style = "Slouchy Socks"
                //       cat = "Women's"
                //     }
                //   else if (elem.product_id === 7194189430884) {
                //     style = "Hiking Crew Socks"
                //     cat = "Women's"
                //   }
                //   else if (elem.product_id === 7194189987940) {
                //     style = "Hiking Quarter Socks"
                //     cat = "Women's"
                //   }
      
                 
                //   else if (elem.product_id === 6888734425188 || elem.product_id === 6888748580964 || elem.product_id === 6888738160740 || elem.product_id === 6888742060132 || elem.product_id === 6888726921316 || elem.productId === 6888747335780) {
                //     style = "Pacas Throw Blanket"
                //   }
              
                //   else if (elem.product_id === 2236767502436 || elem.product_id === 6626100314212 || elem.product_id === 6816252428388 || elem.product_id === 6958959296612 || elem.product_id === 7255625433188 ) {
                //     style = "Low-Cut Socks"
                //     cat = "Women's"
                //   }
              
                //   else if (elem.product_id=== 6956831965284 || elem.product_id === 4837718753380 || elem.product_id === 4837737365604 || elem.product_id === 7223568990308 || elem.product_id === 7255597383780) {
                //     style = "Crew Socks"
                //     cat = "Men's"
                //   }
                //   else if (elem.product_id === 6878354341988) {
                //     style = "Compression Socks"
                //     cat = "Men's"
                //   }
              
                //   else if (elem.product_id === 6602920394852) {
                //     style = "Light-Weight Crew Socks"
                //     cat = "Men's"
                //   }
      
                //   else if (elem.product_id === 6824474771556) {
                //     style = "Golf Socks"
                //     cat = "Men's"
                //   }
      
                //   else if (elem.product_id === 7194190217316) {
                //     style = "Hiking Crew Socks"
                //     cat = "Men's"
                //   }
      
                //   else if (elem.product_id === 7194190512228) {
                //     style = "Hiking Quarter Socks"
                //     cat = "Men's"
                //   }
                //   else if (elem.product_id === 4838251888740 || elem.product_id === 4838251987044 || elem.product_id === 4838368051300 || elem.product_id === 4838370410596) {
                //     style = "Crew Socks"
                //     cat = "Kids'"
                //     size = "One Size"
                //   }
              
                //   else if (elem.productId === 6873185353828 || elem.productId === 6862851080292 || elem.productId === 6873188270180 || elem.productId === 6873186828388 || elem.productId === 6873182437476 || elem.product_id === 6972996714596 || elem.product_id === 6972996157540 || elem.product_id === 6972996976740 || elem.product_id === 6972996419684 || elem.product_id === 6972997271652 || elem.product_id === 7000945000548 || elem.product_id === 7000945590372  || elem.product_id === 7000423956580 || elem.product_id === 6972993667172 || elem.product_id === 6972994781284 || elem.product_id === 6632489123940 || elem.product_id === 6989526859876 || elem.product_id === 6972995305572 || elem.product_id === 6972995829860 || elem.product_id === 7294940512356 || elem.product_id === 7000425103460 ||elem.product_id === 4837517131876 || elem.product_id === 6999571169380 || elem.product_id === 6888738160740 || elem.product_id === 6999594565732 ) {
                //     cat = "Women's"
                //     style = "Accessory"
                //     size = "One Size"
                //   }
              
                // //   else if (elem.product_id === 6972996714596 || elem.product_id === 6972996157540 || elem.product_id === 6972996976740 || elem.product_id === 6972996419684 || elem.product_id === 6972997271652 ) {
                // //     style = "Pacas Shawl"
                // //     cat = "Women's"
                // //   }
              
                //   else if (elem.product_id === 4838368051300 || elem.product_id === 4838370410596 ) {
                //     style = "Toddler Pacas Socks"
                //   }
                // //   else if (elem.product_id === 7000945000548 || elem.product_id === 7000945590372  || elem.product_id === 7000423956580 || elem.product_id === 6972993667172 || elem.product_id === 6972994781284) {
                // //     style = " Pacas Scarf"
                // //     cat = "Women's"
                // //   }
              
                //   else if (elem.product_id === 7082909958244 || elem.product_id === 7082909139044 ) {
                //     style = "Gift Box"
                //   }
          
      
                //   else if (elem.product_id === 4823046324324 || elem.product_id === 7255624745060  ) {
                //       style = "Low-Cut Socks"
                //       cat = "Men's"
                //     }

                //     else if (elem.product_id === 7294939201636  ) {
                //         style = "Accessory"
                //         cat = "Men's"
                //         size = "One Size"
                //       }

                //       else if (elem.product_id === 6964388003940 || elem.product_id === 6964388167780 ) {
                //         style = "Sweater"
                //         cat = "Men's"
                //         size = "One Size"
                //       }

                //       else if (elem.product_id === 7237848662116 || elem.product_id === 7196495446116 || elem.product_id === 7237854986340 || elem.product_id === 7264481378404 || elem.product_id === 6964387151972 || elem.product_id === 6964386496612  ) {
                //         style = "Sweater"
                //         cat = "Women's"
                //         size = "One Size"
                //       }
                if (elem.product_id === 6852663804004 || elem.product_id === 6620534669412 || elem.product_id === 6597571575908 || elem.product_id === 4837716951140 || elem.product_id === 6950074384484 || elem.product_id === 7163184283748 || elem.product_id === 4837733957732 || elem.product_id === 7286790619236 || elem.product_id === 7851959943268 ) { 
                  style = "Crew Socks"
                  cat = "Women's"
                } 
                
                else if (elem.product_id === 6873293586532 || elem.product_id === 6930797101156 || elem.product_id === 7255628546148 || elem.product_id === 7217697390692) {
                  style = "Compression Socks"
                  cat = "Women's"
                }
                else if (elem.product_id === 6824475361380) {
                  style = "Golf Socks"
                  cat = "Women's"
                }
                else if (elem.product_id === 6609129275492 || elem.product_id === 7255634051172 || elem.product_id === 7217910743140 || elem.product_id === 6609176789092 || elem.product_id === 6609178230884 ) {
                  style = "Light-Weight Crew Socks"
                  cat = "Women's"
                }
                else if ( elem.product_id === 6626100314212 || elem.product_id === 6816252428388 || elem.product_id === 4823040131172 || elem.product_id === 7217900060772 || elem.product_id === 7851605065828 ) {
                  style = "Low-Cut Socks"
                  cat = "Women's"
                }
                else if (elem.product_id === 6541486489700 || elem.product_id === 7321956876388) {
                  style = "No-Show Socks"
                  cat = "Women's"
                }
                else if (elem.product_id === 6557907746916) {
                  style = "Performance Socks"
                  cat = "Women's"
                }
                else if (elem.product_id === 6575734030436) {
                  style = "Performance Socks"
                  cat = "Men's"
                }
                else if (elem.product_id === 7105370357860) {
                    style = "Slouchy Socks"
                    cat = "Women's"
                  }
                else if (elem.product_id === 7194189430884) {
                  style = "Hiking Crew Socks"
                  cat = "Women's"
                }
                else if (elem.product_id === 7194189987940) {
                  style = "Hiking Quarter Socks"
                  cat = "Women's"
                }
    
               
                else if (elem.product_id === 6888734425188 || elem.product_id === 6888748580964 || elem.product_id === 6888738160740 || elem.product_id === 6888742060132 || elem.product_id === 6888726921316 || elem.productId === 6888747335780) {
                  style = "Pacas Throw Blanket"
                }
            
                else if (elem.product_id === 2236767502436 || elem.product_id === 6626100314212 || elem.product_id === 6816252428388 || elem.product_id === 6958959296612 || elem.product_id === 7255625433188 ) {
                  style = "Low-Cut Socks"
                  cat = "Women's"
                }
            
                else if (elem.product_id=== 6956831965284 || elem.product_id === 4837718753380 || elem.product_id === 4837737365604 || elem.product_id === 7223568990308 || elem.product_id === 7255597383780 || elem.product_id === 7851670143076) {
                  style = "Crew Socks"
                  cat = "Men's"
                }
                else if (elem.product_id === 6878354341988) {
                  style = "Compression Socks"
                  cat = "Men's"
                }
            
                else if (elem.product_id === 6602920394852 || elem.product_id === 6602924785764 || elem.product_id === 6602925637732) {
                  style = "Light-Weight Crew Socks"
                  cat = "Men's"
                }
    
                else if (elem.product_id === 6824474771556) {
                  style = "Golf Socks"
                  cat = "Men's"
                }
    
                else if (elem.product_id === 7194190217316) {
                  style = "Hiking Crew Socks"
                  cat = "Men's"
                }
    
                else if (elem.product_id === 7194190512228) {
                  style = "Hiking Quarter Socks"
                  cat = "Men's"
                }
                else if (elem.product_id === 4838251888740 || elem.product_id === 4838251987044) {
                  style = "Crew Socks"
                  cat = "Kids'"
                }
            
                else if (elem.productId === 6873185353828 || elem.productId === 6862851080292 || elem.productId === 6873188270180 || elem.productId === 6873186828388 || elem.productId === 6873182437476 ) {
                  style = "Pacas Summer Wrap"
                }
            
                else if (elem.product_id === 6972996714596 || elem.product_id === 6972996157540 || elem.product_id === 6972996976740 || elem.product_id === 6972996419684 || elem.product_id === 6972997271652 ) {
                  style = "Pacas Shawl"
                }
            
                else if (elem.product_id === 4838368051300 || elem.product_id === 4838370410596 ) {
                  style = "Toddler Pacas Socks"
                }
                else if (elem.product_id === 7000945000548 || elem.product_id === 7000945590372  || elem.productId === 7000423956580) {
                  style = " Pacas Scarf"
                }
            
                else if (elem.product_id === 7082909958244 || elem.product_id === 7082909139044 ) {
                  style = "Gift Box"
                }
        
    
                else if (elem.product_id === 4823046324324 || elem.product_id === 7255624745060 || elem.product_id === 7851616370788  ) {
                    style = "Low-Cut Socks"
                    cat = "Men's"
                  }

                    return (
                        {...elem, cat: cat, style: style, size: size}
                    )


            })

            let testCheck = finalData2.filter((elem) => elem.cat === "" || elem.style === "")

            console.log(testCheck)

            console.log(finalData2)

            let finalData3 = finalData2.map((elem) => {
                if (elem.size === "") {
                    let newSize = ""
                    if (parseInt(elem.sku) % 2 === 0) {
                        newSize = "Size 1"
                    } else {
                        newSize = "Size 2"
                    }

                    return ({
                        ...elem, size:newSize
                    })
                } else {
                    return (elem)
                }
            })

            console.log(finalData3)

            let exportData = finalData3.map((elem) => {
              return (
                {
                  sku: elem.sku
                }
              )
            })

            setEp(exportData)
    
            setData(finalData3);
        } catch (error) {
            console.error('Error in getShop:', error);
        }
    };



    useEffect(() => {
        getAll()
       
       
    }, [])




  const testFire = () => {
    // data.forEach((elem) => {
    //     axios.post("/api/kri/update", {
    //         sku: elem.sku,
    //         quantity: elem.quantity
    //     })
    // })

    axios.post("/api/kri/create", kdata).then((res) => console.log(res))
  }
    
    
    




    

    if (data.length === 0) {


    return (
        <div style={{marginLeft: "200px"}} className="background-main">
{/*  
        <h1>TEST</h1>
        <button onClick={testFire}>GET</button> */}
        <img style={{marginLeft:"35vw", marginTop:"20vh"}} src={loading} />
       
        </div>
    )
    } else {
        return (
            <div style={{marginLeft: "200px"}} className="background-main">
              {/* <button onClick={generateExcelFile}>GEN</button> */}
 
            <KRIDisplay data={data} />
           
            </div>
        )
    }



}