import { useState, useEffect, useRef } from "react";
import Papa from "papaparse"
import axios from "axios"
import AdjustmentsCreateModal from "../subViews/AdjustmentsCreateModal";
import AdjustmentsViewModal from "../subViews/AdjustmentsViewModal";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


export default function Adjustments() {

    const [failData, setFailData] = useState([]);
    const [data, setData] = useState([]);
    const [viewData, setViewData] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [createOpen, setCreateOpen] = useState(false);
    const [createLoad, setCreateLoad] = useState(0);
    

    const [viewOpen, setViewOpen] = useState(false);
    const [viewLoad, setViewLoad] = useState(0);

    //Modals
    const handleCreateOpen = () => setCreateOpen(true);

    const handleViewOpen = () => {
        
        setViewOpen(true)
        getDB()

    }

    const handleCreateClose = () => {
        setCreateOpen(false)
        handleLoadFalse(0)
    }


    const handleViewClose = () => {
        setViewOpen(false)
     
    }



    const handleLoadTrue = () => setCreateLoad(1);
    const handleLoadFalse = () => setCreateLoad(0);


    const fileInputRef = useRef(null);

    const handleFileUpload = (e) => {
        let currentTime = new Date().toLocaleString()
        console.log(currentTime)
        const files = e.target.files;
        console.log(files);
        if (files) {
          console.log(files[0]);
          Papa.parse(files[0], {
            header: true,
            complete: function(results) {
              console.log("Finished:", results.data);
              let formArray = results.data.map(elem => {

      
           
      
                return (
                  {
                    sku: elem.sku,
                    adjustment: parseInt(elem.adjustment),
                    po: elem.po
                
                  }
                )
                
      
          
              })
              console.log(formArray)
        
              getAll(formArray)
              handleCreateOpen()

              if (fileInputRef.current) {
                fileInputRef.current.value = '';
              }
           
            }}
          )
        }
      
      }

      const getDB = () => {
        axios.post("/api/adjustment/getall", {}).then((res) => {
            console.log(res.data)

            let vd = res.data.map((elem) => {
                return (
                    {
                        sku: elem.sku,
                        adjustment: elem.adjustment,
                        new_inventory: elem.new_inventory,
                        updated_at: elem.updated_at,
                        updated_by: elem.updated_by,
                        po: elem.po
                        
                    }
                )
            })
         
            setViewData(vd)
        })
      }




      const getAll = (data) => {
        axios.post("/api/shopify/getallvariants", {}).then((res) => {
 
            let allVariants = res.data.products.map(elem => {
                return (
                    elem.variants
                )
            }).flat()
            let failArray = []
            let matchArray = data.map(elem => {
                let foundVariants = allVariants.find((elm) => elm.sku == elem.sku)
                if (foundVariants) {
                    return(
                        {
                            sku: elem.sku,
                            adjustment: elem.adjustment,
                            inventoryItemId: foundVariants.inventory_item_id,
                            po: elem.po
                           
                        }
                    )

                } else {
                    failArray.push({sku: elem.sku})
                    // alert("Some Variants not Found")
                    console.log(failArray)
                }
             


            }).filter(ellm => ellm !== undefined)

            setData(matchArray)
            setFailData(failArray)
            handleLoadTrue()



            console.log(matchArray)
            console.log(failArray)

            

        })
      }


      return (
        <div className="override-container">
            <div style={{display:"flex", marginLeft: "5vw"}}>

        <div style={{margin:"10vw"}}>
        <Button style={{height:"10vh"}} component="label" variant="contained" >
        Upload Adjustments
        <VisuallyHiddenInput ref={fileInputRef} onChange={handleFileUpload} type="file" accept=".csv" />
        </Button>
        </div>
 



        <div style={{margin:"10vw"}}>
          <Button Button style={{height:"10vh"}} onClick={handleViewOpen} component="label" variant="contained" >
                   View Adjustments

         </Button>
         </div>

         </div>

         <AdjustmentsCreateModal createOpen={createOpen} handleCreateClose={handleCreateClose} handleCreateOpen={handleCreateOpen} createLoad={createLoad} data={data} failData={failData}/>
   

          <AdjustmentsViewModal viewOpen={viewOpen} handleViewClose={handleViewClose} viewData={viewData} viewLoad={viewLoad} />
        </div>
      );
    }