//DEPS
import React, { useEffect, useState } from 'react';
import axios from "axios"

import Papa from "papaparse"
import Yarn from './Yarn';

//Color Blocks
import charcoal from "../assets/CSCHARCOAL.png"
import grey from "../assets/GREYM738.png"
import hemp from "../assets/HEMP.png"
import natural from "../assets/NATURAL.png"
import navy from "../assets/NAVY.png"
import black from "../assets/BLACK.png"
import aqua from "../assets/AQUAM.png"
import redwhite from "../assets/REDWHITE.png"
import petal from "../assets/PETAL.png"
import ledenim from "../assets/ledenim.png"
import marine from "../assets/MARINEM.png"
import white from "../assets/WHITE.png"
import cq92 from "../assets/CQ92.png"
import YarnTabOne from '../subViews/YarnTabOne';
import YarnTabTwo from '../subViews/YarnTabTwo';
import AZ3532 from "../assets/AZ2532.png"
import DARKCHERRY from "../assets/DARKCHERRY.png"
import LOLLIPOP from "../assets/LOLLIPOP.png"
import M4405 from "../assets/M4405.png"
import ORIOLE from "../assets/ORIOLE.png"
import PEACOAT from "../assets/PEACOAT.png"
import RJ8540 from "../assets/RJ8540.png"
import TREETOP from "../assets/TREETOP.png"
import CARD from "../assets/CARD.png"
import VR from "../assets/VR.png"

import L002 from "../assets/2L002.png"
import AM9835 from "../assets/AM9835.png"
import AZ12482 from "../assets/AZ12482.png"
import M651 from "../assets/M651.png"
import M701 from "../assets/M701.png"
import M843 from "../assets/M843.png"
import RJ3661 from "../assets/RJ3661.png"
import RJ7382 from "../assets/RJ7382.png"
import VR14061 from "../assets/VR14061.png"

import MOSS from "../assets/MOSS.png"
import MERLOT from "../assets/MERLOT.png"
import BROWN from "../assets/BROWN.png"
import chambray from "../assets/chambray.png"
import rose from "../assets/0043.png"
import foam from "../assets/CQ68.png"


import crocus from "../assets/CRCOUS.png"
import rj11191 from "../assets/RJ11191.png"
import am4803 from "../assets/AM4803.png"

//MUI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


const TabPanel = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  minWidth: 1000,
  overflow: 'auto',
}));


export default function YarnParent() {

  const [inboundData, setInboundData] = useState([])
  const [selectedTab, setSelectedTab] = useState(0);
  const [multiChecked, setMultiChecked] = useState(false);
  

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleMultiSwitch = (event) => {
    setMultiChecked(event.target.checked);
  };



  const handleFileUpload = (e) => {
    let currentTime = new Date().toLocaleString()
    console.log(currentTime)
    const files = e.target.files;
    console.log(files);
    if (files) {
      console.log(files[0]);
      Papa.parse(files[0], {
        header: true,
        complete: function(results) {
          console.log("Finished:", results.data);
          let formArray = results.data.map(elem => {
            let hex = natural
            if (elem.colorCode === "CHARCOAL HE" || elem.colorCode === "CHARCOAL SFN43" || elem.colorCode === "M3143" || elem.colorCode === "SFN43 CHARCOAL") {
              hex = charcoal
            }
            if (elem.colorCode === "WHITE M5601" || elem.colorCode === "100" ) {
              hex = white
            }
            if (elem.colorCode === "NAVY 1019" || elem.colorCode === "DARK NAVY 6269" || elem.colorCode === "1019 NAVY" ) {
              hex = navy
            }
            if (elem.colorCode === "BLACK 500" || elem.colorCode === "BLACK 500/SFN50" || elem.colorCode === "500/SFN50 BLACK" || elem.colorCode === "500" || elem.colorCode === "SFN21") {
              hex = black
            }
            if (elem.colorCode === "HEMP 2L002" ) {
              hex = hemp
            }
            if (elem.colorCode === "GREY M738" || elem.colorCode === "M738 GREY HEATHER" || elem.colorCode === "GREY MELANGE M738" || elem.colorCode === "M738") {
              hex = grey
            }
            if (elem.colorCode === "PETAL AZ37641" || elem.colorCode === "ORCHID PETAL AZ3741" ) {
              hex = petal
            }
            if (elem.colorCode === "C975 RED WHITE" || elem.colorCode === "RED WHITE C975") {
              hex = redwhite
            }
            if (elem.colorCode === "AQUA MELANGE C986" || elem.colorCode === "AQUA MLG M3576/C986" ) {
              hex = aqua
            }
            if (elem.colorCode === "LT DENIM HTHR" || elem.colorCode === "LT DENIM HTHR CQ91" || elem.colorCode === "C831 TEAL DENIM") {
              hex = ledenim
            }
            if (elem.colorCode === "CQ92 PURPLE" ) {
              hex = cq92
            }
            if (elem.colorCode === "RJ8540" || elem.colorCode === "BLUSH 0043" ) {
              hex = RJ8540
            }
            if (elem.colorCode === "M4405" ) {
              hex = M4405
            }
            if (elem.colorCode === "AZ3532" ) {
              hex = AZ3532
            }
            if (elem.colorCode === "TREETOP 0501" ) {
              hex = TREETOP
            }
            if (elem.colorCode === "CARDINAL 0588" ) {
              hex = CARD
            }
            if (elem.colorCode === "LOLLIPOP 1017" ) {
              hex = LOLLIPOP
            }
            if (elem.colorCode === "PEACOAT 1391"  || elem.colorCode === "1391 PEACOAT" ) {
              hex = PEACOAT
            }
            if (elem.colorCode === "DARK CHERRY 4718" ) {
              hex = DARKCHERRY
            }
            if (elem.colorCode === "ORIOLE 6460" ) {
              hex = ORIOLE
            }
            if (elem.colorCode === "DARK CHERRY 4718" || elem.colorCode === "4718 DARK CHERRY" ) {
              hex = DARKCHERRY
            }
            if (elem.colorCode === "VR12174" ) {
              hex = VR
            }
            if (elem.colorCode === "MARINE T232" ) {
              hex = marine
            }
            if (elem.colorCode === "2L002" ) {
              hex = L002
            }
            if (elem.colorCode === "AM9835" ) {
              hex = AM9835
            }
            if (elem.colorCode === "AZ12482" ) {
              hex = AZ12482
            }
            if (elem.colorCode === "M651" ) {
              hex = M651
            }
            if (elem.colorCode === "M701" ) {
              hex = M701
            }
            if (elem.colorCode === "M843" ) {
              hex = M843
            }
            if (elem.colorCode === "RJ3661" ) {
              hex = RJ3661
            }
            if (elem.colorCode === "RJ7382" ) {
              hex = RJ7382
            }
            if (elem.colorCode === "VR14061" ) {
              hex = VR14061
            }
            if (elem.colorCode === "CQ82 MOSS HTHR" ) {
              hex = MOSS
            }
            if (elem.colorCode === "CQ88 MERLOT" || elem.colorCode === "3083" ) {
              hex = MERLOT
            }
            if (elem.colorCode === "SFN44 BROWN" ) {
              hex = BROWN
            }

            if (elem.colorCode === "M1870" || elem.colorCode === "CQ69 CHAMBRAY" ) {
              hex = chambray
            }
            if (elem.colorCode === "0043 VEILED ROSE" ) {
              hex = rose
            }
            if (elem.colorCode === "CQ68 SEAFOAM HTHR" ) {
              hex = foam
            }

            if (elem.colorCode === "AM4803" ) {
              hex = am4803
            }

            if (elem.colorCode === "CROCUS" ) {
              hex = crocus
            }

            if (elem.colorCode === "RJ11191" ) {
              hex = rj11191
            }
            
            return (
                {
                  boxNumber: elem.boxNumber,
                  colorCode: elem.colorCode,
                  lotNumber: elem.lotNumber,
                  unitType: elem.unitType,
                  unitQuantity:  elem.unitQuantity,
                  grossWeightKg: elem.grossWeightKg,
                  netWeightKg: elem.netWeightKg,
                  uploadedAt: currentTime,
                  yarnUnit: elem.yarnUnit,
                  hex: hex
                   
                }
            )
          })
          console.log(formArray)
          setInboundData(formArray)
        }}
      )
    }

}

  let testArray = [
    {boxNumber: "123", colorCode: "adda", lotNumber: "321", unitType: "cone", grossWeightKg: "23", netWeightKg: "34", uploadedAt: "today"},
    {boxNumber: "321", colorCode: "adsdfsdfda", lotNumber: "3321", unitType: "cone", grossWeightKg: "23", netWeightKg: "34", uploadedAt: "today"}
  ]

  const uploadInbound = () => {
    inboundData.forEach(elem => {
      axios.post("/api/yarn/inbound", 
        elem
      )
      .then ((res) => {
        console.log(res)
      })
    

    })
  }

  



  return (
    <div style={{marginLeft: "20vw"}}>
        
        <input
        type="file"
        accept=".csv,.xlsx,.xls"
        onChange={handleFileUpload}
        />
        <button onClick={uploadInbound}>UPLOAD</button>
        <FormGroup>
            <FormControlLabel
             sx={{  color: "white" }}
            label="Individual"
             control={<Switch
                checked={multiChecked}
                onChange={handleMultiSwitch}
                inputProps={{ 'aria-label': 'controlled' }}
                labelPlacement="end"
                />}
            />
            </FormGroup>
        
        {/* <Yarn inboundData={inboundData} /> */}

        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Modal Tabs">
          <Tab label="UPLOAD AND CREATE" />
          <Tab label="VIEW" />
        </Tabs>
        <TabPanel>
          {selectedTab === 0 && (
            <div>
           <YarnTabOne inboundData={inboundData} multiChecked={multiChecked} />
            </div>
          )}
          {selectedTab === 1 && (
            <div>
            <YarnTabTwo />
            </div>
          )}
        </TabPanel>
  
    </div>
  )
}