import React, { useEffect, useState } from 'react';
import axios from "axios"

//mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';


const style = {
    position: 'absolute',
    top: '15%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80vw",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

  };

export default function AddComponent( {setDataSource} ) {


    const [open, setOpen] = useState(false);

  

    
    

    



    const handleOpen = () => {

        setOpen(true)
    }
    const handleClose = () => setOpen(false);

  




    
const dataSelection = (e) => {
    setDataSource(e.target.value)

}






    return (
        <div>
        <Button onClick={handleOpen}>Add Component</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{maxHeight: "80vh", overflow: "scroll"}}>
          <Box sx={{ display: 'flex' }}>
            <FormControl  component="fieldset" variant="standard">
                <FormLabel component="legend">Select Component to Add</FormLabel>
                <FormGroup>
                    <button value={"SOAPI"} onClick={dataSelection}>Shopify Orders API</button>
                    <button value={"TESTAPI"} onClick={dataSelection}>Filter</button>
                    <button value={"VE"} onClick={dataSelection}>View and Export</button>

    
                </FormGroup>
           
                <FormHelperText></FormHelperText>
            </FormControl>
        
            </Box>
          </Box>
        </Modal>
      </div>
    )
}