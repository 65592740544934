import { getAuth, signOut } from "firebase/auth";
import React from "react"
import { useNavigate } from "react-router-dom";


export default function SignOut() {

const auth = getAuth();
let navigate = useNavigate();

const handleSignOut = () => {
    signOut(auth).then(() => {
        // Sign-out successful.
        navigate("/")
      }).catch((error) => {
        // An error happened.
      });
}


    return (
        <div>
            <button onClick={handleSignOut}>Sign Out</button>
        </div>
    )
}